import React from "react";
import { useState, useEffect } from "react";
import TopHeader from "../../../Components/TopHeader";
import Prince from "../../../Components/Prince";
import { DateRangePicker } from "react-date-range";
import { format } from "date-fns";
import spiner from "../../../assets/img/spiner.gif";
import { MdDateRange } from "react-icons/md";
import FilterComponent from "../ReportComponent/FilterComponent";
import CardComponent from "../ReportComponent/CardComponent";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Select from "@mui/joy/Select";
import Option from "@mui/joy/Option";
import SupportTicketFilterComp from "../ReportComponent/SupportTicketFilterComp";
import { error } from "jquery";

const apiUrl = process.env.REACT_APP_URL;
const Token = localStorage.getItem("Token");
function SupportTicketStatusReport(
  {
    // handleApplyFilter,
    // handleConfirm,
    // setFilterByObj,
    // filterByObj,
  }
) {
  const [dateFilterModalOPen, setDateFilterModalOpen] = useState(false);
  const [modelOpen, setModalOpen] = useState(false);
  const [from, setFrom] = useState("");
  const [to, setTo] = useState([]);
  const [openClosedCount, setOpenClosedCount] = useState("");
  const [ticketStatusCount, setTicketStatusCount] = useState([]);
  const [averageTicketAgeingByStatus, setAverageTicketAgeingByStatus] =
    useState([]);
  const [averageResolutionTime, setAverageResolutionTime] = useState([]);
  const [kamType, setKamType] = useState([]);
  const [kamEmployee, setKamEmployee] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [date, setDate] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });
  const onFilterChange = (data) => {
    setFilterData(data);
  };
  const handleDateFilterClick = () => {
    setDateFilterModalOpen(true);
  };
  const handleCloseDateFilterModal = () => {
    setDateFilterModalOpen(false);
  };

  const handleApplyFilter = () => {
    // fetchApi();
    fetchApi1();
    fetchApi2();
    fetchApi3();
    fetchApi4();
  };
  // const handleCloseModal = () => {
  //   setModalOpen(false);
  //   // setIsModalOpen(false);
  // };
  // const handleOpenModal = () => {
  //   setModalOpen(true);
  // };
  const handleDateChange1 = (ranges) => {
    const adjustedStartDate = new Date(ranges.selection.startDate);
    adjustedStartDate.setHours(23, 59, 59, 999);

    const adjustedEndDate = new Date(ranges.selection.endDate);
    adjustedEndDate.setHours(23, 59, 59, 999);

    // setFilterByObj({
    //   ...filterByObj,
    //   to: adjustedEndDate.toISOString(),
    //   from: adjustedStartDate.toISOString(),
    // });
    setFrom(adjustedStartDate.toISOString());
    setTo(adjustedEndDate.toISOString());
    setDate(ranges.selection);
    // setDate({
    //   startDate: adjustedStartDate,
    //   endDate: adjustedEndDate,
    //   key: "selection",
    // });
  };
  //console.log(date);
  console.log(filterData);
  // useEffect(() => {
  const fetchApi4 = async () => {
    try {
      const response = await fetch(
        `${apiUrl}/supportTicketReport/openVsclosedCount?from=${from}&to=${to}&kamTier=${
          filterData.filterByLeadPipeline || ""
        }&employeeId=${filterData.filterByBusinessCategory || ""}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authrization: `Bearer ${Token}`,
          },
        }
      );
      const data = await response.json();
      setOpenClosedCount(data.data);
    } catch (error) {
      console.log(error);
    }
  };
  // fetchApi();
  // }, []);

  // useEffect(() => {
  const fetchApi3 = async () => {
    try {
      const response = await fetch(
        `${apiUrl}/supportTicketReport/ticketStatusCount?from=${from}&to=${to}&kamTier=${
          filterData.filterByLeadPipeline || ""
        }&employeeId=${filterData.filterByBusinessCategory || ""}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authrization: `Bearer ${Token}`,
          },
        }
      );
      const data = await response.json();
      setTicketStatusCount(data.data);
    } catch (error) {
      console.log(error);
    }
  };
  //   fetchApi();
  // }, []);

  // useEffect(() => {
  const fetchApi2 = async () => {
    try {
      const response = await fetch(
        `${apiUrl}/supportTicketReport/averageTicketAgeingByStatus?from=${from}&to=${to}&kamTier=${
          filterData.filterByLeadPipeline || ""
        }&employeeId=${filterData.filterByBusinessCategory || ""}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authrization: `Bearer ${Token}`,
          },
        }
      );

      const data = await response.json();
      setAverageTicketAgeingByStatus(data.data);
    } catch (error) {
      console.log(error);
    }
  };
  //   fetchApi();
  // }, []);

  // useEffect(() => {
  const fetchApi1 = async () => {
    try {
      const response = await fetch(
        `${apiUrl}/supportTicketReport/avgResolutionTime?from=${from}&to=${to}&kamTier=${
          filterData.filterByLeadPipeline || ""
        }&employeeId=${filterData.filterByBusinessCategory || ""}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authrization: `Bearer ${Token}`,
          },
        }
      );

      const data = await response.json();
      setAverageResolutionTime(data.data);
    } catch (error) {
      console.log(error);
    }
  };
  //fetchApi();
  // }, []);
  useEffect(() => {
    fetchApi1();
    fetchApi2();
    fetchApi3();
    fetchApi4();
  }, []);

  useEffect(() => {
    // Make an API request to fetch gender options
    fetch(`${apiUrl}/master/getAllMasterData/20`, {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data.data)) {
          setKamType(data.data);
        } else {
          console.error("API response does not contain an array:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching gender options:", error);
      });
  }, []);

  useEffect(() => {
    // Make an API request to fetch gender options
    fetch(`${apiUrl}/employee/kamEmp?kycType=${""}`, {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data.data)) {
          // console.log(data);
          setKamEmployee(data.data);
        } else {
          // console.error("API response does not contain an array:", data);
        }
      })
      .catch((error) => {
        // console.error("Error fetching gender options:", error);
      });
  }, []);

  return (
    <>
      <div className="page">
        <TopHeader />
        <Prince />
        <div className="main-content side-content pt-0 m-0">
          <div className="main-container container-fluid">
            <div className="inner-body">
              <div className="page-header my-2">
                <div>
                  <h2 className="main-content-title tx-24 mb-0 mt-1 ">
                    Support Ticket Status Report
                  </h2>
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a>Report</a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Support Ticket Status Report
                    </li>
                  </ol>
                </div>
              </div>
              <div style={{ marginTop: "15px" }}>
                <div className="row">
                  <CardComponent
                    title={"Open Ticket"}
                    link={"Open Ticket Count"}
                    count={openClosedCount.openTicket}
                  />
                  <CardComponent
                    title={"Closed Ticket Count"}
                    link={"Total Closed Ticket"}
                    count={openClosedCount.closedTicket}
                  />

                  {/* <CardComponent
                    title={"Lead Generated Q2 2024"}
                    link={"Total Lead Generated Q2 2024"}
                    count={"3453"}
                  /> */}
                  {/* <CardComponent
                    title={"Lead Generated Q3 2024"}
                    link={"Total Lead Generated Q3 2024"}
                    count={"34434"}
                  /> */}
                  {/* <CardComponent
                    title={"Total Won Lead Yearly"}
                    link={"Won Lead Yearly"}
                    count={"533434"}
                  /> */}
                  <div className="card custom-card">
                    <div className="card-body py-0 pb-3">
                      <div className="row">
                        <div className="col-sm-2 mt-3">
                          <label>Date Filter</label>
                          <button
                            className="calender btn btn-outline-danger"
                            onClick={handleDateFilterClick}
                            style={{
                              fontSize: "11px",
                              width: "215px",
                            }}
                          >
                            <MdDateRange
                              size={16}
                              style={{ display: "inline" }}
                            />{" "}
                            {`${format(
                              date.startDate,
                              "MMM dd yyyy"
                            )} -- ${format(date.endDate, "MMM dd yyyy")}`}
                          </button>
                          <div
                            className={`modal ${
                              dateFilterModalOPen ? "show" : ""
                            } bg-black-7`}
                            style={{
                              display: dateFilterModalOPen ? "block" : "none",
                            }}
                            tabIndex="-1"
                            role="dialog"
                          >
                            <div
                              className=" modal-dialog modal-dialog modal-lg"
                              role="document"
                              style={{ marginTop: "80px" }}
                            >
                              <div
                                className="modal-content"
                                style={{ borderRadius: "8px" }}
                              >
                                <div className="modal-header">
                                  <h5 className="modal-title">
                                    Select Date Range
                                  </h5>
                                  <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    onClick={handleCloseDateFilterModal}
                                  >
                                    <span aria-hidden="true">&times;</span>
                                  </button>
                                </div>
                                <div
                                  className="modal-body"
                                  style={{ width: "100%" }}
                                >
                                  <DateRangePicker
                                    ranges={[date]}
                                    onChange={handleDateChange1}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <SupportTicketFilterComp
                          // leadSourceOptions={[{}, {}, {}]}
                          leadPipelineOptions={kamType}
                          businessCategoryOptions={kamEmployee}
                          // partnerOptions={[{}, {}, {}]}
                          onFilterChange={onFilterChange}
                        />
                        <div className="d-flex">
                          <div className="mr-2">
                            <button
                              style={{ marginTop: "30px" }}
                              className="btn btn-outline-success"
                              onClick={handleApplyFilter}
                            >
                              Apply Filter
                            </button>
                          </div>
                          <div className="mx-2">
                            <button
                              style={{ marginTop: "30px" }}
                              className="btn btn-outline-warning"
                              onClick={() => {
                                setDate({
                                  startDate: new Date(),
                                  endDate: new Date(),
                                  key: "selection",
                                });
                                window.location.reload();
                              }}
                            >
                              Clear Filter
                            </button>
                          </div>
                          {/* <div>
                            <button
                              style={{ marginTop: "30px" }}
                              className="btn btn-outline-info"
                              onClick={handleOpenModal}
                            >
                              Export
                            </button>
                            <div
                              className={`modal ${
                                modelOpen ? "show" : ""
                              } bg-black-7`}
                              style={{
                                display: modelOpen ? "block" : "none",
                              }}
                              tabIndex="-1"
                              role="dialog"
                            >
                              <div
                                className="modal-dialog modal-dialog-centered modal-xl"
                                role="document"
                              >
                                <div className="modal-content rounded-3">
                                  <div className="modal-header">
                                    <h5 className="modal-title">
                                      Download Excel
                                    </h5>
                                    <button
                                      type="button"
                                      className="close"
                                      data-dismiss="modal"
                                      aria-label="Close"
                                      onClick={handleCloseModal}
                                    >
                                      <span aria-hidden="true">&times;</span>
                                    </button>
                                  </div>

                                  <div
                                    className="modal-body "
                                    style={{ width: "100%" }}
                                  >
                                    {from !== "" ? (
                                      <p>Click here to download</p>
                                    ) : (
                                      <p>
                                        Please wait , Download button will
                                        appear soon...{" "}
                                      </p>
                                    )}
                                    {from !== "" ? (
                                      <button
                                        className="btn ripple btn-primary"
                                        type="button"
                                        onClick={handleConfirm}
                                      >
                                        Download
                                      </button>
                                    ) : (
                                      <img src={spiner} height={"50px"}></img>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div> */}
                        </div>
                      </div>{" "}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="card custom-card">
                      <div className="card-body">
                        <h5 style={{ textDecoration: "underline" }}>
                          Ticket Status and Count
                        </h5>

                        <div class="table-responsive">
                          <table class="table table-hover table-nowrap">
                            <thead class="table-light">
                              <tr>
                                <th scope="col">Status</th>
                                <th scope="col">Count</th>
                                {/* <th scope="col">Percentage</th> */}
                              </tr>
                            </thead>
                            <tbody>
                              {ticketStatusCount.map((item, index) => (
                                <tr key={index}>
                                  <td data-label="Email">
                                    <span>{item.status}</span>
                                  </td>
                                  {/* <td data-label="Phone">
                                  <a
                                    class="text-current"
                                    href="mailto:robert.fox@example.com"
                                  >
                                    60
                                  </a>
                                </td> */}
                                  <td data-label="Lead Score">
                                    <a
                                      class="text-current"
                                      href="tel:202-555-0152"
                                    >
                                      {item.count}
                                    </a>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      {/* <div
                        style={{
                          padding: "20px",
                          display: "flex",
                          justifyContent: "space-between",
                          paddingTop: "0",
                        }}
                      >
                        <div style={{ display: "flex" }}>
                          <Select
                            defaultValue="20"
                            // onChange={(value) => {
                            //   handlePageSize(value);
                            //   handleLoader();
                            // }}
                            style={{
                              marginTop: "10px",
                              height: "20px",
                              marginRight: "10px",
                            }}
                          >
                            <Option value="20">20</Option>
                            <Option value="50">50</Option>
                            <Option value="100">100</Option>
                            <Option value="200">200</Option>
                            <Option value="500">500</Option>
                          </Select>
                          <span
                            style={{
                              marginTop: "16px",
                              color: "#808080",
                            }}
                          >
                            Page Size
                          </span>
                        </div>
                        <Stack spacing={2} style={{ marginTop: "20px" }}>
                          <Pagination
                            count={5}
                            page={1}
                            variant="outlined"
                            // onChange={handlePageChange}
                            // onClick={() => handleLoader()}
                            color="primary"
                          />
                        </Stack>{" "}
                      </div> */}
                    </div>
                    <div className="card custom-card">
                      <div className="card-body">
                        <h5 style={{ textDecoration: "underline" }}>
                          Average Ticket Ageing by Status
                        </h5>
                        <div class="table-responsive">
                          <table class="table table-hover table-nowrap">
                            <thead class="table-light">
                              <tr>
                                <th scope="col">Status</th>
                                <th scope="col">Average Age (Days)</th>
                                {/* <th scope="col">Percentage of Total Leads</th> */}
                                {/* <th scope="col">Rate</th> */}
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td data-label="Email">
                                  <span>Closed</span>
                                </td>
                                <td data-label="Phone">
                                  <a
                                    class="text-current"
                                    href="mailto:robert.fox@example.com"
                                  >
                                    {averageTicketAgeingByStatus.Closed}
                                  </a>
                                </td>
                              </tr>
                              <tr>
                                <td data-label="Email">
                                  <span>Pending on Client</span>
                                </td>
                                <td data-label="Phone">
                                  <a
                                    class="text-current"
                                    href="mailto:robert.fox@example.com"
                                  >
                                    {
                                      averageTicketAgeingByStatus[
                                        "Pending on client"
                                      ]
                                    }
                                  </a>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      {/* <div
                        style={{
                          padding: "20px",
                          display: "flex",
                          justifyContent: "space-between",
                          paddingTop: "0",
                        }}
                      >
                        <div style={{ display: "flex" }}>
                          <Select
                            defaultValue="20"
                            // onChange={(value) => {
                            //   handlePageSize(value);
                            //   handleLoader();
                            // }}
                            style={{
                              marginTop: "10px",
                              height: "20px",
                              marginRight: "10px",
                            }}
                          >
                            <Option value="20">20</Option>
                            <Option value="50">50</Option>
                            <Option value="100">100</Option>
                            <Option value="200">200</Option>
                            <Option value="500">500</Option>
                          </Select>
                          <span
                            style={{
                              marginTop: "16px",
                              color: "#808080",
                            }}
                          >
                            Page Size
                          </span>
                        </div>
                        <Stack spacing={2} style={{ marginTop: "20px" }}>
                          <Pagination
                            count={5}
                            page={1}
                            variant="outlined"
                            // onChange={handlePageChange}
                            // onClick={() => handleLoader()}
                            color="primary"
                          />
                        </Stack>{" "}
                      </div> */}
                    </div>
                    <div className="card custom-card">
                      <div className="card-body">
                        <h5 style={{ textDecoration: "underline" }}>
                          Average Resolution Time
                        </h5>
                        <div class="table-responsive">
                          <table class="table table-hover table-nowrap">
                            <tbody>
                              <tr>
                                <td data-label="Email">
                                  <span>Average Resolution Time</span>
                                </td>
                                <td data-label="Phone">
                                  <a
                                    class="text-current"
                                    href="mailto:robert.fox@example.com"
                                  >
                                    {
                                      averageResolutionTime.averageResolutionTime
                                    }
                                  </a>
                                </td>
                              </tr>
                              <tr>
                                <td data-label="Email">
                                  <span>Total Cases</span>
                                </td>
                                <td data-label="Phone">
                                  <a
                                    class="text-current"
                                    href="mailto:robert.fox@example.com"
                                  >
                                    {averageResolutionTime.totalCases}
                                  </a>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      {/* <div
                        style={{
                          padding: "20px",
                          display: "flex",
                          justifyContent: "space-between",
                          paddingTop: "0",
                        }}
                      >
                        <div style={{ display: "flex" }}>
                          <Select
                            defaultValue="20"
                            // onChange={(value) => {
                            //   handlePageSize(value);
                            //   handleLoader();
                            // }}
                            style={{
                              marginTop: "10px",
                              height: "20px",
                              marginRight: "10px",
                            }}
                          >
                            <Option value="20">20</Option>
                            <Option value="50">50</Option>
                            <Option value="100">100</Option>
                            <Option value="200">200</Option>
                            <Option value="500">500</Option>
                          </Select>
                          <span
                            style={{
                              marginTop: "16px",
                              color: "#808080",
                            }}
                          >
                            Page Size
                          </span>
                        </div>
                        <Stack spacing={2} style={{ marginTop: "20px" }}>
                          <Pagination
                            count={5}
                            page={1}
                            variant="outlined"
                            // onChange={handlePageChange}
                            // onClick={() => handleLoader()}
                            color="primary"
                          />
                        </Stack>{" "}
                      </div> */}
                    </div>
                  </div>
                  {/* <div className="col-md-6">
                    <div className="card custom-card">
                      <div className="card-body">
                        <h5 style={{ textDecoration: "underline" }}>
                          SLA Adherence Breakdown
                        </h5>
                        <div class="table-responsive">
                          <table class="table table-hover table-nowrap">
                            <thead class="table-light">
                              <tr>
                                <th scope="col">Status</th>
                                <th scope="col">Total Tickets</th>
                                <th scope="col">Tickets within SLA</th>
                                <th scope="col">SLA Adherence (%)</th>
                               
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td data-label="Email">
                                  <span>Open</span>
                                </td>
                                <td data-label="Phone">
                                  <a
                                    class="text-current"
                                    href="mailto:robert.fox@example.com"
                                  >
                                    15
                                  </a>
                                </td>
                                <td data-label="Phone">
                                  <a
                                    class="text-current"
                                    href="mailto:robert.fox@example.com"
                                  >
                                    15
                                  </a>
                                </td>
                                <td data-label="Lead Score">
                                  <a
                                    class="text-current"
                                    href="tel:202-555-0152"
                                  >
                                    30%
                                  </a>
                                </td>
                              </tr>
                              <tr>
                                <td data-label="Email">
                                  <span>Closed</span>
                                </td>
                                <td data-label="Phone">
                                  <a
                                    class="text-current"
                                    href="mailto:robert.fox@example.com"
                                  >
                                    10
                                  </a>
                                </td>
                                <td data-label="Phone">
                                  <a
                                    class="text-current"
                                    href="mailto:robert.fox@example.com"
                                  >
                                    10
                                  </a>
                                </td>
                                <td data-label="Lead Score">
                                  <a
                                    class="text-current"
                                    href="tel:202-555-0152"
                                  >
                                    20%
                                  </a>
                                </td>
                              </tr>
                              <tr>
                                <td data-label="Email">
                                  <span>Escalated</span>
                                </td>
                                <td data-label="Phone">
                                  <a
                                    class="text-current"
                                    href="mailto:robert.fox@example.com"
                                  >
                                    12
                                  </a>
                                </td>
                                <td data-label="Phone">
                                  <a
                                    class="text-current"
                                    href="mailto:robert.fox@example.com"
                                  >
                                    12
                                  </a>
                                </td>
                                <td data-label="Lead Score">
                                  <a
                                    class="text-current"
                                    href="tel:202-555-0152"
                                  >
                                    24%
                                  </a>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    
                    </div>
                    <div className="card custom-card">
                      <div className="card-body">
                        <h5 style={{ textDecoration: "underline" }}>
                          Reporting Period: [Start Date] - [End Date]
                        </h5>

                        <div class="table-responsive">
                          <table class="table table-hover table-nowrap">
                            <thead class="table-light">
                              <tr>
                                <th scope="col">Matrix</th>
                                <th scope="col">Value</th>
                               
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td data-label="Email">
                                  <span>Total Tickets</span>
                                </td>
                              
                                <td data-label="Lead Score">
                                  <a
                                    class="text-current"
                                    href="tel:202-555-0152"
                                  >
                                    33
                                  </a>
                                </td>
                              
                              </tr>
                              <tr>
                                <td data-label="Email">
                                  <span>Open Tickets</span>
                                </td>
                              
                                <td data-label="Lead Score">
                                  <a
                                    class="text-current"
                                    href="tel:202-555-0152"
                                  >
                                    56
                                  </a>
                                </td>
                             
                              </tr>
                              <tr>
                                <td data-label="Email">
                                  <span>Closed Tickets</span>
                                </td>
                                <td data-label="Phone">
                                  <a
                                    class="text-current"
                                    href="mailto:robert.fox@example.com"
                                  >
                                    22
                                  </a>
                                </td>
                               
                              
                              </tr>
                              <tr>
                                <td data-label="Email">
                                  <span>Average Resolution Time</span>
                                </td>
                             
                                <td data-label="Lead Score">
                                  <a
                                    class="text-current"
                                    href="tel:202-555-0152"
                                  >
                                    26
                                  </a>
                                </td>
                               
                              </tr>
                              <tr>
                                <td data-label="Email">
                                  <span>SLA Adherence</span>
                                </td>
                                <td data-label="Phone">
                                  <a
                                    class="text-current"
                                    href="mailto:robert.fox@example.com"
                                  >
                                    23
                                  </a>
                                </td>
                               
                               
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                   
                    </div>
                  </div> */}
                </div>
              </div>

              {}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SupportTicketStatusReport;
