import React, { useState, useEffect } from "react";
import InputField from "../../Components/createTicket/InputField";
import Box from "@mui/material/Box";
import CreateButton from "../../Components/createTicket/CreateButton";
import { toast } from "react-toastify";
//import { event } from "jquery";

const apiUrl = process.env.REACT_APP_URL;

function DeliveryNotRecived({
  customerId,
  selectedValue4Id,
  caseId,
  prefilledAwb,
  tabId,
}) {
  const [alternateNumber, setAlternateNumber] = useState("");
  const [awbNumber, setAwbNumber] = useState("");

  const handleInputValue = (event) => {
    setAlternateNumber(event.target.value);
  };

  const handleAwbNumber = (event) => {
    setAwbNumber(event.target.value);
  };
  useEffect(() => {
    if (prefilledAwb) setAwbNumber(prefilledAwb);
  }, [prefilledAwb]);

  console.log(customerId);
  const handleCreateCase = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append("caseType", caseId);
    formData.append("caseSubType", selectedValue4Id);
    formData.append("isMultipleAWB", false);
    formData.append("awbNo", awbNumber);
    formData.append("alternateNumber", alternateNumber);
    formData.append("tabId", tabId);
    const url = customerId.companyId
      ? `${apiUrl}/caseM/addCase?companyId=${customerId.companyId}`
      : `${apiUrl}/caseM/addCase?customerId=${customerId}`;
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          // Authorization: `Bearer ${Token}`,
        },
        body: formData,
      });

      if (response.ok) {
        toast.success("Case Created successfully!");
        // setOpen(false);
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else {
        toast.error("Failed to create case. Please try again.");
      }
    } catch (error) {
      toast.error("Failed to create case. Please try again.");
    }
  };

  return (
    <Box className="mt-3">
      <Box className="mb-2">
        <InputField
          text="Awb No."
          handleInputValue={handleAwbNumber}
          value={awbNumber}
        />
      </Box>

      <InputField
        text="Alternate Mobile No."
        handleInputValue={handleInputValue}
        value={alternateNumber}
      />

      {selectedValue4Id === 41 && (
        <CreateButton handleCreateCase={handleCreateCase}></CreateButton>
      )}
    </Box>
  );
}

export default DeliveryNotRecived;
