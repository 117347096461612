import React from "react";
import { useState, useEffect } from "react";
import TopHeader from "../../Components/TopHeader";
import Prince from "../../Components/Prince";
import { DateRangePicker } from "react-date-range";
import { format } from "date-fns";
import spiner from "../../../src/assets/img/spiner.gif";
import { MdDateRange } from "react-icons/md";
//import FilterComponent from "../Reports/ReportComponent/FilterComponent";
import CardComponent from "../Reports/ReportComponent/CardComponent";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Select from "@mui/joy/Select";
import Option from "@mui/joy/Option";
import { toast } from "react-toastify";
import SalesPersonFilter from "./SalesPersonFilter";

function SalesPersonPerformance({}) {
  const apiUrl = process.env.REACT_APP_URL;
  const Token = localStorage.getItem("Token");

  const [dateFilterModalOPen, setDateFilterModalOpen] = useState(false);
  const [modelOpen, setModalOpen] = useState(false);
  const [from, setFrom] = useState("");
  const [to, setTo] = useState([]);
  const [leadSourceOptions, setLeadSourceOptions] = useState([]);
  const [employeeId, setEmployeeId] = useState([]);
  const [state, setStates] = useState([]);
  const [leadStatus, setLeadStatus] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [counts, setCounts] = useState([]);
  const [shipmentPotentialOptions, setShipmentPotentialOptions] = useState([]);
  const [performance, setPerformance] = useState([]);

  const [date, setDate] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });
  console.log(performance);
  console.log(counts);

  const onFilterChange = (data) => {
    setFilterData(data);
  };

  const handleApplyFilter = () => {
    fetchData();
  };

  const handleDateFilterClick = () => {
    setDateFilterModalOpen(true);
  };
  const handleCloseDateFilterModal = () => {
    setDateFilterModalOpen(false);
  };
  // const handleCloseModal = () => {
  //   setModalOpen(false);
  //   // setIsModalOpen(false);
  // };
  // const handleOpenModal = () => {
  //   setModalOpen(true);
  // };
  const handleDateChange1 = (ranges) => {
    const adjustedStartDate = new Date(ranges.selection.startDate);
    adjustedStartDate.setHours(23, 59, 59, 999);

    const adjustedEndDate = new Date(ranges.selection.endDate);
    adjustedEndDate.setHours(23, 59, 59, 999);
    setFrom(adjustedStartDate.toISOString());
    setTo(adjustedEndDate.toISOString());
    setDate(ranges.selection);
  };

  // useEffect(() => {
  const fetchData = async () => {
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Token}`,
      };
      const response = await fetch(
        `${apiUrl}/salesPersonReport/salesPerformanceReport?from=${from}&to=${to}&shipmentPotential=${
          filterData.filterByPartner || ""
        }&employeeId=${filterData.filterByLeadSource || ""}&kycStatus=${
          filterData.filterByBusinessCategory || ""
        }&state=${filterData.filterByLeadPipeline || ""}`,
        {
          method: "GET",
          headers: headers,
        }
      );
      const result = await response.json();
      setPerformance(result.data);
    } catch (err) {
      toast.error(err.message);
    }
  };
  //   fetchData();
  // }, [filterData]);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Token}`,
        };
        const response = await fetch(
          `${apiUrl}/salesPersonReport/headerCount`,
          {
            method: "GET",
            headers: headers,
          }
        );
        const result = await response.json();
        setCounts(result.data);
      } catch (err) {
        toast.error(err.message);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    // Make an API request to fetch gender options
    fetch(`${apiUrl}/master/getAllMasterData/1`, {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data.data)) {
          setLeadSourceOptions(data.data);
        }
      });

    fetch(`${apiUrl}/employee/allStates/101`, {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data.data)) {
          setStates(data.data);
        }
      })
      .catch((error) => {
        // console.error("Error fetching gender options:", error);
      });
  }, []);

  useEffect(() => {
    // Make an API request to fetch gender options
    fetch(`${apiUrl}/master/getAllMasterData/8`, {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data.data)) {
          setLeadStatus(data.data);
        }
      });
  }, []);

  useEffect(() => {
    fetch(`${apiUrl}/employee/empDropdownSub`, {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data.data)) {
          setEmployeeId(data.data);
        }
      })
      .catch((error) => {});
  }, []);

  useEffect(() => {
    fetch(`${apiUrl}/master/getAllMasterData/4`, {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data.data)) {
          setShipmentPotentialOptions(data.data);
        }
      })
      .catch((error) => {});
  }, []);
  console.log(counts.totalOrder);
  console.log(employeeId);
  return (
    <>
      <div className="page">
        <TopHeader />
        <Prince />
        <div className="main-content side-content pt-0 m-0">
          <div className="main-container container-fluid">
            <div className="inner-body">
              <div className="page-header my-2">
                <div>
                  <h2 className="main-content-title tx-24 mb-0 mt-1 ">
                    Sales Person Performance
                  </h2>
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a>Report</a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Sales Person Performance
                    </li>
                  </ol>
                </div>
              </div>
              <div style={{ marginTop: "15px" }}>
                <div className="row">
                  <CardComponent
                    title={"Total Customer"}
                    link={"Total Customer"}
                    count={counts.totalCustomer}
                  />
                  <CardComponent
                    title={"Total Order"}
                    link={"Total Order"}
                    // count={counts.totalOrder}
                    count={"45"}
                  />
                  <CardComponent
                    title={"Total Shipment"}
                    link={"Total Shipment"}
                    //count={counts.totalShipment}
                    count={"30"}
                  />
                  <CardComponent
                    title={"KYC Approved"}
                    link={"KYC Approved"}
                    count={counts.kycApproved}
                  />
                  <CardComponent
                    title={"KYC Pending"}
                    link={"KYC Pending"}
                    count={counts.kycPending}
                  />
                  <div className="card custom-card">
                    <div className="card-body py-0 pb-3">
                      <div className="row">
                        <div className="col-sm-2 mt-3">
                          <label>Date Filter</label>
                          <button
                            className="calender btn btn-outline-danger"
                            onClick={handleDateFilterClick}
                            style={{
                              fontSize: "11px",
                              width: "215px",
                            }}
                          >
                            <MdDateRange
                              size={16}
                              style={{ display: "inline" }}
                            />{" "}
                            {`${format(
                              date.startDate,
                              "MMM dd yyyy"
                            )} -- ${format(date.endDate, "MMM dd yyyy")}`}
                          </button>
                          <div
                            className={`modal ${
                              dateFilterModalOPen ? "show" : ""
                            } bg-black-7`}
                            style={{
                              display: dateFilterModalOPen ? "block" : "none",
                            }}
                            tabIndex="-1"
                            role="dialog"
                          >
                            <div
                              className=" modal-dialog modal-dialog modal-lg"
                              role="document"
                              style={{ marginTop: "80px" }}
                            >
                              <div
                                className="modal-content"
                                style={{ borderRadius: "8px" }}
                              >
                                <div className="modal-header">
                                  <h5 className="modal-title">
                                    Select Date Range
                                  </h5>
                                  <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    onClick={handleCloseDateFilterModal}
                                  >
                                    <span aria-hidden="true">&times;</span>
                                  </button>
                                </div>
                                <div
                                  className="modal-body"
                                  style={{ width: "100%" }}
                                >
                                  <DateRangePicker
                                    ranges={[date]}
                                    onChange={handleDateChange1}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <SalesPersonFilter
                          leadSourceOptions={employeeId}
                          leadPipelineOptions={state}
                          businessCategoryOptions={[
                            { id: "Approved", name: "Approved" },
                            { id: "Pending", name: "Pending" },
                          ]}
                          partnerOptions={shipmentPotentialOptions}
                          onFilterChange={onFilterChange}
                        />
                        <div className="d-flex">
                          <div className="mr-2">
                            <button
                              style={{ marginTop: "30px" }}
                              className="btn btn-outline-success"
                              onClick={handleApplyFilter}
                            >
                              Apply Filter
                            </button>
                          </div>
                          <div className="mx-2">
                            <button
                              style={{ marginTop: "30px" }}
                              className="btn btn-outline-warning"
                              onClick={() => {
                                setDate({
                                  startDate: new Date(),
                                  endDate: new Date(),
                                  key: "selection",
                                });
                                window.location.reload();
                              }}
                            >
                              Clear Filter
                            </button>
                          </div>
                          {/* <div>
                            <button
                              style={{ marginTop: "30px" }}
                              className="btn btn-outline-info"
                              onClick={handleOpenModal}
                            >
                              Export
                            </button>
                            <div
                              className={`modal ${
                                modelOpen ? "show" : ""
                              } bg-black-7`}
                              style={{
                                display: modelOpen ? "block" : "none",
                              }}
                              tabIndex="-1"
                              role="dialog"
                            >
                              <div
                                className="modal-dialog modal-dialog-centered modal-xl"
                                role="document"
                              >
                                <div className="modal-content rounded-3">
                                  <div className="modal-header">
                                    <h5 className="modal-title">
                                      Download Excel
                                    </h5>
                                    <button
                                      type="button"
                                      className="close"
                                      data-dismiss="modal"
                                      aria-label="Close"
                                      onClick={handleCloseModal}
                                    >
                                      <span aria-hidden="true">&times;</span>
                                    </button>
                                  </div>

                                  <div
                                    className="modal-body "
                                    style={{ width: "100%" }}
                                  >
                                    {from !== "" ? (
                                      <p>Click here to download</p>
                                    ) : (
                                      <p>
                                        Please wait , Download button will
                                        appear soon...{" "}
                                      </p>
                                    )}
                                    {from !== "" ? (
                                      <button
                                        className="btn ripple btn-primary"
                                        type="button"
                                        onClick={handleConfirm}
                                      >
                                        Download
                                      </button>
                                    ) : (
                                      <img src={spiner} height={"50px"}></img>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div> */}
                        </div>
                      </div>{" "}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="card custom-card">
                      <div className="card-body">
                        {/* <h5 style={{ textDecoration: "underline" }}>
                          Won Lead By Lead Source
                        </h5> */}

                        <div class="table-responsive">
                          <table class="table table-hover table-nowrap">
                            <thead class="table-light">
                              <tr>
                                <th scope="col">Customer ID</th>
                                <th scope="col">Closure & Shipment</th>
                                <th scope="col">Client Information</th>
                                <th scope="col">Performance Metrics</th>
                                <th scope="col">Shipment Date</th>
                              </tr>
                            </thead>
                            <tbody>
                              {performance.map((item, index) => (
                                <tr key={index}>
                                  <td data-label="Email" class="email-cell">
                                    <p class="info">
                                      <strong>Customer ID : </strong> {item.id}
                                    </p>
                                  </td>
                                  <td data-label="Email" class="email-cell">
                                    <p class="info">
                                      <strong>Customer Name : </strong>{" "}
                                      {item.primaryContactName}
                                    </p>
                                    <p class="info">
                                      <strong>Company Name : </strong>{" "}
                                      {item.companyName}
                                    </p>
                                    <p class="info">
                                      <strong>Company Id : </strong>{" "}
                                      {item.companyId}
                                    </p>
                                    <p class="info">
                                      <strong>Total Order Synced : </strong>{" "}
                                      {item.externaltotalOrder}
                                    </p>
                                    <p class="info">
                                      <strong>Total Shipment: </strong>{" "}
                                      {item.externaltotalShipment}
                                    </p>
                                  </td>
                                  <td data-label="Email" class="email-cell">
                                    <p class="info">
                                      <strong>
                                        Client's Wallet Balance :{" "}
                                        {item.externalWalletBalance}
                                      </strong>{" "}
                                    </p>
                                    <p class="info">
                                      <strong>KYC Status : </strong>{" "}
                                      {item.externalkycStatus}
                                    </p>
                                    <p class="info">
                                      <strong>Account Closure Date : </strong>{" "}
                                      {""}
                                    </p>
                                    <p class="info">
                                      <strong>Current KAM : </strong> {item.kam}
                                    </p>
                                    <p class="info">
                                      <strong>KAM Tier : </strong>
                                      {item.employee.kamType}
                                      {""}
                                    </p>
                                  </td>
                                  <td data-label="Email" class="email-cell">
                                    <p class="info">
                                      <strong>Delivery (%) : </strong>
                                    </p>
                                    <p class="info">
                                      <strong>
                                        First Attempt Delivery (%) :{" "}
                                      </strong>{" "}
                                      {""}
                                    </p>
                                    <p class="info">
                                      <strong>RTO (%) : </strong> {""}
                                    </p>
                                    <p class="info">
                                      <strong>NDR (%) : </strong> {""}
                                    </p>
                                    <p class="info">
                                      <strong> Intrasit Shipments : </strong>{" "}
                                      {""}
                                    </p>
                                    <p class="info">
                                      <strong> Prepaid : </strong> {""}
                                      {item.externalprePaidPercentage}
                                    </p>
                                  </td>
                                  <td data-label="Email" class="email-cell">
                                    <p class="info">
                                      <strong>First Shipment Date : </strong>{" "}
                                      {item.externalfirstShipmentDate}
                                    </p>
                                    <p class="info">
                                      <strong>Last Shipemnt Date : </strong>{" "}
                                      {item.externallastShipmentDate}
                                    </p>
                                    <p class="info">
                                      <strong>
                                        Shipments Volume Growth :{" "}
                                      </strong>{" "}
                                      {""}
                                    </p>
                                    <p class="info">
                                      <strong>Average Shipment : </strong> {""}
                                    </p>
                                  </td>
                                </tr>
                              ))}
                              {/* <tr>
                                <td data-label="Email" class="email-cell">
                                  <p class="info">
                                    <strong>Customer ID : </strong> 23434
                                  </p>
                                </td>
                                <td data-label="Email" class="email-cell">
                                  <p class="info">
                                    <strong>Customer Name : </strong> Ankit
                                    Kumar
                                  </p>
                                  <p class="info">
                                    <strong>Company Name : </strong> Soumya
                                    Medical Agency Pvt Ltd
                                  </p>
                                  <p class="info">
                                    <strong>Company Id : </strong> COMP1234
                                  </p>
                                  <p class="info">
                                    <strong>Total Order Synced : </strong> 432
                                  </p>
                                  <p class="info">
                                    <strong>Total Shipment: </strong> 152
                                  </p>
                                </td>
                                <td data-label="Email" class="email-cell">
                                  <p class="info">
                                    <strong>Client's Wallet Balance : </strong>{" "}
                                    ₹ 20,543.00
                                  </p>
                                  <p class="info">
                                    <strong>KYC Status : </strong> Pending
                                  </p>
                                  <p class="info">
                                    <strong>Account Closure Date : </strong>{" "}
                                    23/09/2023
                                  </p>
                                  <p class="info">
                                    <strong>Current KAM : </strong> Ankit Maurya
                                  </p>
                                  <p class="info">
                                    <strong>KAM Tier : </strong> Gold
                                  </p>
                                </td>
                                <td data-label="Email" class="email-cell">
                                  <p class="info">
                                    <strong>Delivery (%) : </strong> 34 %
                                  </p>
                                  <p class="info">
                                    <strong>
                                      First Attempt Delivery (%) :{" "}
                                    </strong>{" "}
                                    12 %
                                  </p>
                                  <p class="info">
                                    <strong>RTO (%) : </strong> COMP1234
                                  </p>
                                  <p class="info">
                                    <strong>NDR (%) : </strong> Pending
                                  </p>
                                  <p class="info">
                                    <strong> Intrasit Shipments : </strong> RTO
                                  </p>
                                  <p class="info">
                                    <strong> Prepaid : </strong> COD Order Split
                                  </p>
                                </td>
                                <td data-label="Email" class="email-cell">
                                  <p class="info">
                                    <strong>First Shipment Date : </strong>{" "}
                                    24/12/1923
                                  </p>
                                  <p class="info">
                                    <strong>Last Shipemnt Date : </strong>{" "}
                                    08/05/2023
                                  </p>
                                  <p class="info">
                                    <strong>Shipments Volume Growth : </strong>{" "}
                                    30%
                                  </p>
                                  <p class="info">
                                    <strong>Average Shipment : </strong> Pending
                                  </p>
                                </td>
                              </tr>
                              <tr>
                                <td data-label="Email" class="email-cell">
                                  <p class="info">
                                    <strong>Customer ID : </strong> 23434
                                  </p>
                                </td>
                                <td data-label="Email" class="email-cell">
                                  <p class="info">
                                    <strong>Customer Name : </strong> Ankit
                                    Kumar
                                  </p>
                                  <p class="info">
                                    <strong>Company Name : </strong> Soumya
                                    Medical Agency Pvt Ltd
                                  </p>
                                  <p class="info">
                                    <strong>Company Id : </strong> COMP1234
                                  </p>
                                  <p class="info">
                                    <strong>Total Order Synced : </strong> 432
                                  </p>
                                  <p class="info">
                                    <strong>Total Shipment: </strong> 152
                                  </p>
                                </td>
                                <td data-label="Email" class="email-cell">
                                  <p class="info">
                                    <strong>Client's Wallet Balance : </strong>{" "}
                                    ₹ 20,543.00
                                  </p>
                                  <p class="info">
                                    <strong>KYC Status : </strong> Pending
                                  </p>
                                  <p class="info">
                                    <strong>Account Closure Date : </strong>{" "}
                                    23/09/2023
                                  </p>
                                  <p class="info">
                                    <strong>Current KAM : </strong> Ankit Maurya
                                  </p>
                                  <p class="info">
                                    <strong>KAM Tier : </strong> Gold
                                  </p>
                                </td>
                                <td data-label="Email" class="email-cell">
                                  <p class="info">
                                    <strong>Delivery (%) : </strong> 34 %
                                  </p>
                                  <p class="info">
                                    <strong>
                                      First Attempt Delivery (%) :{" "}
                                    </strong>{" "}
                                    12 %
                                  </p>
                                  <p class="info">
                                    <strong>RTO (%) : </strong> COMP1234
                                  </p>
                                  <p class="info">
                                    <strong>NDR (%) : </strong> Pending
                                  </p>
                                  <p class="info">
                                    <strong> Intrasit Shipments : </strong> RTO
                                  </p>
                                  <p class="info">
                                    <strong> Prepaid : </strong> COD Order Split
                                  </p>
                                </td>
                                <td data-label="Email" class="email-cell">
                                  <p class="info">
                                    <strong>First Shipment Date : </strong>{" "}
                                    24/12/1923
                                  </p>
                                  <p class="info">
                                    <strong>Last Shipemnt Date : </strong>{" "}
                                    08/05/2023
                                  </p>
                                  <p class="info">
                                    <strong>Shipments Volume Growth : </strong>{" "}
                                    30%
                                  </p>
                                  <p class="info">
                                    <strong>Average Shipment : </strong> Pending
                                  </p>
                                </td>
                              </tr>
                              <tr>
                                <td data-label="Email" class="email-cell">
                                  <p class="info">
                                    <strong>Customer ID : </strong> 23434
                                  </p>
                                </td>
                                <td data-label="Email" class="email-cell">
                                  <p class="info">
                                    <strong>Customer Name : </strong> Ankit
                                    Kumar
                                  </p>
                                  <p class="info">
                                    <strong>Company Name : </strong> Soumya
                                    Medical Agency Pvt Ltd
                                  </p>
                                  <p class="info">
                                    <strong>Company Id : </strong> COMP1234
                                  </p>
                                  <p class="info">
                                    <strong>Total Order Synced : </strong> 432
                                  </p>
                                  <p class="info">
                                    <strong>Total Shipment: </strong> 152
                                  </p>
                                </td>
                                <td data-label="Email" class="email-cell">
                                  <p class="info">
                                    <strong>Client's Wallet Balance : </strong>{" "}
                                    ₹ 20,543.00
                                  </p>
                                  <p class="info">
                                    <strong>KYC Status : </strong> Pending
                                  </p>
                                  <p class="info">
                                    <strong>Account Closure Date : </strong>{" "}
                                    23/09/2023
                                  </p>
                                  <p class="info">
                                    <strong>Current KAM : </strong> Ankit Maurya
                                  </p>
                                  <p class="info">
                                    <strong>KAM Tier : </strong> Gold
                                  </p>
                                </td>
                                <td data-label="Email" class="email-cell">
                                  <p class="info">
                                    <strong>Delivery (%) : </strong> 34 %
                                  </p>
                                  <p class="info">
                                    <strong>
                                      First Attempt Delivery (%) :{" "}
                                    </strong>{" "}
                                    12 %
                                  </p>
                                  <p class="info">
                                    <strong>RTO (%) : </strong> COMP1234
                                  </p>
                                  <p class="info">
                                    <strong>NDR (%) : </strong> Pending
                                  </p>
                                  <p class="info">
                                    <strong> Intrasit Shipments : </strong> RTO
                                  </p>
                                  <p class="info">
                                    <strong> Prepaid : </strong> COD Order Split
                                  </p>
                                </td>
                                <td data-label="Email" class="email-cell">
                                  <p class="info">
                                    <strong>First Shipment Date : </strong>{" "}
                                    24/12/1923
                                  </p>
                                  <p class="info">
                                    <strong>Last Shipemnt Date : </strong>{" "}
                                    08/05/2023
                                  </p>
                                  <p class="info">
                                    <strong>Shipments Volume Growth : </strong>{" "}
                                    30%
                                  </p>
                                  <p class="info">
                                    <strong>Average Shipment : </strong> Pending
                                  </p>
                                </td>
                              </tr>
                              <tr>
                                <td data-label="Email" class="email-cell">
                                  <p class="info">
                                    <strong>Customer ID : </strong> 23434
                                  </p>
                                </td>
                                <td data-label="Email" class="email-cell">
                                  <p class="info">
                                    <strong>Customer Name : </strong> Ankit
                                    Kumar
                                  </p>
                                  <p class="info">
                                    <strong>Company Name : </strong> Soumya
                                    Medical Agency Pvt Ltd
                                  </p>
                                  <p class="info">
                                    <strong>Company Id : </strong> COMP1234
                                  </p>
                                  <p class="info">
                                    <strong>Total Order Synced : </strong> 432
                                  </p>
                                  <p class="info">
                                    <strong>Total Shipment: </strong> 152
                                  </p>
                                </td>
                                <td data-label="Email" class="email-cell">
                                  <p class="info">
                                    <strong>Client's Wallet Balance : </strong>{" "}
                                    ₹ 20,543.00
                                  </p>
                                  <p class="info">
                                    <strong>KYC Status : </strong> Pending
                                  </p>
                                  <p class="info">
                                    <strong>Account Closure Date : </strong>{" "}
                                    23/09/2023
                                  </p>
                                  <p class="info">
                                    <strong>Current KAM : </strong> Ankit Maurya
                                  </p>
                                  <p class="info">
                                    <strong>KAM Tier : </strong> Gold
                                  </p>
                                </td>
                                <td data-label="Email" class="email-cell">
                                  <p class="info">
                                    <strong>Delivery (%) : </strong> 34 %
                                  </p>
                                  <p class="info">
                                    <strong>
                                      First Attempt Delivery (%) :{" "}
                                    </strong>{" "}
                                    12 %
                                  </p>
                                  <p class="info">
                                    <strong>RTO (%) : </strong> COMP1234
                                  </p>
                                  <p class="info">
                                    <strong>NDR (%) : </strong> Pending
                                  </p>
                                  <p class="info">
                                    <strong> Intrasit Shipments : </strong> RTO
                                  </p>
                                  <p class="info">
                                    <strong> Prepaid : </strong> COD Order Split
                                  </p>
                                </td>
                                <td data-label="Email" class="email-cell">
                                  <p class="info">
                                    <strong>First Shipment Date : </strong>{" "}
                                    24/12/1923
                                  </p>
                                  <p class="info">
                                    <strong>Last Shipemnt Date : </strong>{" "}
                                    08/05/2023
                                  </p>
                                  <p class="info">
                                    <strong>Shipments Volume Growth : </strong>{" "}
                                    30%
                                  </p>
                                  <p class="info">
                                    <strong>Average Shipment : </strong> Pending
                                  </p>
                                </td>
                              </tr> */}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div
                        style={{
                          padding: "20px",
                          display: "flex",
                          justifyContent: "space-between",
                          paddingTop: "0",
                        }}
                      >
                        <div style={{ display: "flex" }}>
                          <Select
                            defaultValue="20"
                            // onChange={(value) => {
                            //   handlePageSize(value);
                            //   handleLoader();
                            // }}
                            style={{
                              marginTop: "10px",
                              height: "20px",
                              marginRight: "10px",
                            }}
                          >
                            <Option value="20">20</Option>
                            <Option value="50">50</Option>
                            <Option value="100">100</Option>
                            <Option value="200">200</Option>
                            <Option value="500">500</Option>
                          </Select>
                          <span
                            style={{
                              marginTop: "16px",
                              color: "#808080",
                            }}
                          >
                            Page Size
                          </span>
                        </div>
                        <Stack spacing={2} style={{ marginTop: "20px" }}>
                          <Pagination
                            count={5}
                            page={1}
                            variant="outlined"
                            // onChange={handlePageChange}
                            // onClick={() => handleLoader()}
                            color="primary"
                          />
                        </Stack>{" "}
                      </div>
                    </div>
                  </div>
                </div>
                {/* Row */}
              </div>

              {}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SalesPersonPerformance;
