import React from "react";
import { useState, useEffect } from "react";
import TopHeader from "../../../Components/TopHeader";
import Prince from "../../../Components/Prince";
import { DateRangePicker } from "react-date-range";
import { format } from "date-fns";
import spiner from "../../../assets/img/spiner.gif";
import { MdDateRange } from "react-icons/md";
import FilterComponent from "../ReportComponent/FilterComponent";
import CardComponent from "../ReportComponent/CardComponent";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Select from "@mui/joy/Select";
import Option from "@mui/joy/Option";

const apiUrl = process.env.REACT_APP_URL;
const Token = localStorage.getItem("Token");

function LeadStatusReport() {
  const [dateFilterModalOPen, setDateFilterModalOpen] = useState(false);
  const [modelOpen, setModalOpen] = useState(false);
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [headerCount, setHeaderCount] = useState("");
  const [leadSourceCount, setLeadSourceCount] = useState([]);
  const [leadStageCount, setLeadStageCount] = useState([]);

  const [leadSourceOptions, setLeadSourceOptions] = useState([]);
  const [employeeId, setEmployeeId] = useState([]);
  const [state, setStates] = useState([]);
  const [leadStatus, setLeadStatus] = useState([]);
  const [filterData, setFilterData] = useState([]);

  const onFilterChange = (data) => {
    setFilterData(data);
    console.log(data);
  };

  const [date, setDate] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });

  const handleDateFilterClick = () => {
    setDateFilterModalOpen(true);
  };
  const handleCloseDateFilterModal = () => {
    setDateFilterModalOpen(false);
  };
  // const handleCloseModal = () => {
  //   setModalOpen(false);
  //   // setIsModalOpen(false);
  // };
  // const handleOpenModal = () => {
  //   setModalOpen(true);
  // };

  const handleDateChange1 = (ranges) => {
    const adjustedStartDate = new Date(ranges.selection.startDate);
    adjustedStartDate.setHours(23, 59, 59, 999);

    const adjustedEndDate = new Date(ranges.selection.endDate);
    adjustedEndDate.setHours(23, 59, 59, 999);

    // setFilterByObj({
    //   ...filterByObj,
    //   to: adjustedEndDate.toISOString(),
    //   from: adjustedStartDate.toISOString(),
    // });
    setFrom(adjustedStartDate.toISOString());
    setTo(adjustedEndDate.toISOString());
    setDate(ranges.selection);
    // setDate({
    //   startDate: adjustedStartDate,
    //   endDate: adjustedEndDate,
    //   key: "selection",
    // });
  };

  useEffect(() => {
    const fetchApi = async () => {
      try {
        const response = await fetch(`${apiUrl}/stageReport/headerCount`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authrization: `Bearer ${Token}`,
          },
        });
        const data = await response.json();
        setHeaderCount(data.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchApi();
  }, []);
  // useEffect(() => {
  const fetchApi1 = async () => {
    try {
      const response = await fetch(
        `${apiUrl}/stageReport/leadSourceCount?from=${from}&to=${to}&leadSource=${
          filterData.filterByLeadSource || ""
        }&employeeId=${filterData.filterByLeadPipeline || ""}&status=${
          filterData.filterByBusinessCategory || ""
        }&state=${filterData.filterByPartner || ""}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authrization: `Bearer ${Token}`,
          },
        }
      );
      const data = await response.json();
      setLeadSourceCount(data.data.leadSourceCount);
    } catch (error) {
      console.log(error);
    }
  };
  // fetchApi1();
  // }, []);

  // useEffect(() => {
  const fetchApi2 = async () => {
    try {
      const response = await fetch(
        `${apiUrl}/stageReport/leadStageCount?from=${from}&to=${to}&leadSource=${
          filterData.filterByLeadSource || ""
        }&employeeId=${filterData.filterByLeadPipeline || ""}&status=${
          filterData.filterByBusinessCategory || ""
        }&state=${filterData.filterByPartner || ""}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authrization: `Bearer ${Token}`,
          },
        }
      );
      const data = await response.json();
      setLeadStageCount(data.data.leadStatusCount);
    } catch (error) {
      console.log(error);
    }
  };
  //fetchApi2();
  // }, []);

  useEffect(() => {
    fetchApi1();
    fetchApi2();
  }, []);

  const handleApplyFilter = () => {
    fetchApi1();
    fetchApi2();
  };

  useEffect(() => {
    // Make an API request to fetch gender options
    fetch(`${apiUrl}/master/getAllMasterData/1`, {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data.data)) {
          setLeadSourceOptions(data.data);
        }
      });

    fetch(`${apiUrl}/employee/allStates/101`, {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data.data)) {
          setStates(data.data);
        }
      })
      .catch((error) => {
        // console.error("Error fetching gender options:", error);
      });
  }, []);

  useEffect(() => {
    // Make an API request to fetch gender options
    fetch(`${apiUrl}/master/getAllMasterData/8`, {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data.data)) {
          setLeadStatus(data.data);
        }
      });
  }, []);

  useEffect(() => {
    fetch(`${apiUrl}/employee/empDropdownSub`, {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data.data)) {
          setEmployeeId(data.data);
        }
      })
      .catch((error) => {});
  }, []);
  return (
    <>
      <div className="page">
        <TopHeader />
        <Prince />
        <div className="main-content side-content pt-0 m-0">
          <div className="main-container container-fluid">
            <div className="inner-body">
              <div className="page-header my-2">
                <div>
                  <h2 className="main-content-title tx-24 mb-0 mt-1 ">
                    Lead Status Report
                  </h2>
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a>Report</a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Lead Status Report
                    </li>
                  </ol>
                </div>
              </div>
              <div style={{ marginTop: "15px" }}>
                <div className="row">
                  <CardComponent
                    title={"Lead Generated  2024"}
                    link={"Total Lead Generated 2024"}
                    count={headerCount.totalLeadThisYear}
                  />
                  <CardComponent
                    title={"Lead Generated Q1 2024"}
                    link={"Total Lead Generated Q1 2024"}
                    count={headerCount.totalLeadThisQ1Year}
                  />
                  <CardComponent
                    title={"Lead Generated Q2 2024"}
                    link={"Total Lead Generated Q2 2024"}
                    count={headerCount.totalLeadThisQ2Year}
                  />
                  <CardComponent
                    title={"Lead Generated Q3 2024"}
                    link={"Total Lead Generated Q3 2024"}
                    count={headerCount.totalLeadThisQ3Year}
                  />
                  <CardComponent
                    title={"Total Won Lead Yearly"}
                    link={"Won Lead Yearly"}
                    count={headerCount.wonLeadCount}
                  />
                  <div className="card custom-card">
                    <div className="card-body py-0 pb-3">
                      <div className="row">
                        <div className="col-sm-2 mt-3">
                          <label>Date Filter</label>
                          <button
                            className="calender btn btn-outline-danger"
                            onClick={handleDateFilterClick}
                            style={{
                              fontSize: "11px",
                              width: "215px",
                            }}
                          >
                            <MdDateRange
                              size={16}
                              style={{ display: "inline" }}
                            />{" "}
                            {`${format(
                              date.startDate,
                              "MMM dd yyyy"
                            )} -- ${format(date.endDate, "MMM dd yyyy")}`}
                          </button>
                          <div
                            className={`modal ${
                              dateFilterModalOPen ? "show" : ""
                            } bg-black-7`}
                            style={{
                              display: dateFilterModalOPen ? "block" : "none",
                            }}
                            tabIndex="-1"
                            role="dialog"
                          >
                            <div
                              className=" modal-dialog modal-dialog modal-lg"
                              role="document"
                              style={{ marginTop: "80px" }}
                            >
                              <div
                                className="modal-content"
                                style={{ borderRadius: "8px" }}
                              >
                                <div className="modal-header">
                                  <h5 className="modal-title">
                                    Select Date Range
                                  </h5>
                                  <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    onClick={handleCloseDateFilterModal}
                                  >
                                    <span aria-hidden="true">&times;</span>
                                  </button>
                                </div>
                                <div
                                  className="modal-body"
                                  style={{ width: "100%" }}
                                >
                                  <DateRangePicker
                                    ranges={[date]}
                                    onChange={handleDateChange1}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <FilterComponent
                          leadSourceOptions={leadSourceOptions}
                          //  leadPipelineOptions={employees}
                          leadStatus={leadStatus}
                          partnerOptions={state}
                          employeeId={employeeId}
                          onFilterChange={onFilterChange}
                        />
                        <div className="d-flex">
                          <div className="mr-2">
                            <button
                              style={{ marginTop: "30px" }}
                              className="btn btn-outline-success"
                              onClick={handleApplyFilter}
                            >
                              Apply Filter
                            </button>
                          </div>
                          <div className="mx-2">
                            <button
                              style={{ marginTop: "30px" }}
                              className="btn btn-outline-warning"
                              onClick={() => {
                                setDate({
                                  startDate: new Date(),
                                  endDate: new Date(),
                                  key: "selection",
                                });
                                window.location.reload();
                              }}
                            >
                              Clear Filter
                            </button>
                          </div>
                          {/* <div>
                            <button
                              style={{ marginTop: "30px" }}
                              className="btn btn-outline-info"
                              onClick={handleOpenModal}
                            >
                              Export
                            </button>
                            <div
                              className={`modal ${
                                modelOpen ? "show" : ""
                              } bg-black-7`}
                              style={{
                                display: modelOpen ? "block" : "none",
                              }}
                              tabIndex="-1"
                              role="dialog"
                            >
                              <div
                                className="modal-dialog modal-dialog-centered modal-xl"
                                role="document"
                              >
                                <div className="modal-content rounded-3">
                                  <div className="modal-header">
                                    <h5 className="modal-title">
                                      Download Excel
                                    </h5>
                                    <button
                                      type="button"
                                      className="close"
                                      data-dismiss="modal"
                                      aria-label="Close"
                                      onClick={handleCloseModal}
                                    >
                                      <span aria-hidden="true">&times;</span>
                                    </button>
                                  </div>

                                  <div
                                    className="modal-body "
                                    style={{ width: "100%" }}
                                  >
                                    {from !== "" ? (
                                      <p>Click here to download</p>
                                    ) : (
                                      <p>
                                        Please wait , Download button will
                                        appear soon...{" "}
                                      </p>
                                    )}
                                    {from !== "" ? (
                                      <button
                                        className="btn ripple btn-primary"
                                        type="button"
                                        onClick={handleConfirm}
                                      >
                                        Download
                                      </button>
                                    ) : (
                                      <img src={spiner} height={"50px"}></img>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div> */}
                        </div>
                      </div>{" "}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="card custom-card">
                      <div className="card-body">
                        <h5 style={{ textDecoration: "underline" }}>
                          Lead Source
                        </h5>

                        <div class="table-responsive">
                          <table class="table table-hover table-nowrap">
                            <thead class="table-light">
                              <tr>
                                <th scope="col">Source</th>
                                <th scope="col">Lead Generated</th>
                                {/* <th scope="col">Percentage</th> */}
                                {/* <th scope="col">Rate</th> */}
                              </tr>
                            </thead>
                            <tbody>
                              {leadSourceCount.map((item, index) => (
                                <tr>
                                  <td data-label="Email" key={index}>
                                    <span>{item.leadSource}</span>
                                  </td>
                                  <td data-label="Phone">
                                    <a
                                      class="text-current"
                                      href="mailto:robert.fox@example.com"
                                    >
                                      {item._count}
                                    </a>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      {/* <div
                        style={{
                          padding: "20px",
                          display: "flex",
                          justifyContent: "space-between",
                          paddingTop: "0",
                        }}
                      >
                        <div style={{ display: "flex" }}>
                          <Select
                            defaultValue="20"
                            // onChange={(value) => {
                            //   handlePageSize(value);
                            //   handleLoader();
                            // }}
                            style={{
                              marginTop: "10px",
                              height: "20px",
                              marginRight: "10px",
                            }}
                          >
                            <Option value="20">20</Option>
                            <Option value="50">50</Option>
                            <Option value="100">100</Option>
                            <Option value="200">200</Option>
                            <Option value="500">500</Option>
                          </Select>
                          <span
                            style={{
                              marginTop: "16px",
                              color: "#808080",
                            }}
                          >
                            Page Size
                          </span>
                        </div>
                        <Stack spacing={2} style={{ marginTop: "20px" }}>
                          <Pagination
                            count={5}
                            page={1}
                            variant="outlined"
                            // onChange={handlePageChange}
                            // onClick={() => handleLoader()}
                            color="primary"
                          />
                        </Stack>{" "}
                      </div> */}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="card custom-card">
                      <div className="card-body">
                        <h5 style={{ textDecoration: "underline" }}>
                          Lead Stage
                        </h5>
                        <div class="table-responsive">
                          <table class="table table-hover table-nowrap">
                            <thead class="table-light">
                              <tr>
                                <th scope="col">Lead Stage</th>
                                <th scope="col">Number of Leads</th>
                                {/* <th scope="col">Percentage of Total Leads</th> */}
                                {/* <th scope="col">Rate</th> */}
                              </tr>
                            </thead>
                            <tbody>
                              {leadStageCount.map((item, index) => (
                                <tr key={index}>
                                  <td data-label="Email">
                                    <span>{item.status}</span>
                                  </td>
                                  <td data-label="Phone">
                                    <a
                                      class="text-current"
                                      href="mailto:robert.fox@example.com"
                                    >
                                      {item._count}
                                    </a>
                                  </td>

                                  {/*  */}
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      {/* <div
                        style={{
                          padding: "20px",
                          display: "flex",
                          justifyContent: "space-between",
                          paddingTop: "0",
                        }}
                      >
                        <div style={{ display: "flex" }}>
                          <Select
                            defaultValue="20"
                            // onChange={(value) => {
                            //   handlePageSize(value);
                            //   handleLoader();
                            // }}
                            style={{
                              marginTop: "10px",
                              height: "20px",
                              marginRight: "10px",
                            }}
                          >
                            <Option value="20">20</Option>
                            <Option value="50">50</Option>
                            <Option value="100">100</Option>
                            <Option value="200">200</Option>
                            <Option value="500">500</Option>
                          </Select>
                          <span
                            style={{
                              marginTop: "16px",
                              color: "#808080",
                            }}
                          >
                            Page Size
                          </span>
                        </div>
                        <Stack spacing={2} style={{ marginTop: "20px" }}>
                          <Pagination
                            count={5}
                            page={1}
                            variant="outlined"
                            // onChange={handlePageChange}
                            // onClick={() => handleLoader()}
                            color="primary"
                          />
                        </Stack>{" "}
                      </div> */}
                    </div>
                    <div className="card custom-card">
                      <div className="card-body">
                        <h5 style={{ textDecoration: "underline" }}>
                          Lead to Opportunity
                        </h5>
                        <div class="table-responsive">
                          <table class="table table-hover table-nowrap">
                            <thead class="table-light">
                              <tr>
                                <th scope="col">Metric</th>
                                <th scope="col">Value</th>
                                {/* <th scope="col">Percentage of Total Leads</th> */}
                                {/* <th scope="col">Rate</th> */}
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td data-label="Email">
                                  <span>Total Leads</span>
                                </td>
                                <td data-label="Phone">
                                  <a
                                    class="text-current"
                                    href="mailto:robert.fox@example.com"
                                  >
                                    2040
                                  </a>
                                </td>
                                {/* <td data-label="Lead Score">
                                  <a
                                    class="text-current"
                                    href="tel:202-555-0152"
                                  >
                                    30%
                                  </a>
                                </td> */}
                                {/* <td data-label="Company">
                                  <span class="badge bg-opacity-30 bg-success text-success">
                                    7/10
                                  </span>
                                </td> */}
                              </tr>
                              <tr>
                                <td data-label="Email">
                                  <span>Opportunities Identified (SYTS)</span>
                                </td>

                                {/* <td data-label="Lead Score">
                                  <a
                                    class="text-current"
                                    href="tel:202-555-0152"
                                  >
                                    20%
                                  </a>
                                </td> */}
                                <td data-label="Company">30</td>
                              </tr>
                              <tr>
                                <td data-label="Email">
                                  <span>Conversion Rate</span>
                                </td>
                                <td data-label="Phone">
                                  <a
                                    class="text-current"
                                    href="mailto:robert.fox@example.com"
                                  >
                                    30%
                                  </a>
                                </td>
                                {/* <td data-label="Lead Score">
                                  <a
                                    class="text-current"
                                    href="tel:202-555-0152"
                                  >
                                    20%
                                  </a>
                                </td> */}
                                {/* <td data-label="Company">
                                  <span class="badge bg-opacity-30 bg-success text-success">
                                    4/76
                                  </span>
                                </td> */}
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      {/* <div
                        style={{
                          padding: "20px",
                          display: "flex",
                          justifyContent: "space-between",
                          paddingTop: "0",
                        }}
                      >
                        <div style={{ display: "flex" }}>
                          <Select
                            defaultValue="20"
                            // onChange={(value) => {
                            //   handlePageSize(value);
                            //   handleLoader();
                            // }}
                            style={{
                              marginTop: "10px",
                              height: "20px",
                              marginRight: "10px",
                            }}
                          >
                            <Option value="20">20</Option>
                            <Option value="50">50</Option>
                            <Option value="100">100</Option>
                            <Option value="200">200</Option>
                            <Option value="500">500</Option>
                          </Select>
                          <span
                            style={{
                              marginTop: "16px",
                              color: "#808080",
                            }}
                          >
                            Page Size
                          </span>
                        </div>
                        <Stack spacing={2} style={{ marginTop: "20px" }}>
                          <Pagination
                            count={5}
                            page={1}
                            variant="outlined"
                            // onChange={handlePageChange}
                            // onClick={() => handleLoader()}
                            color="primary"
                          />
                        </Stack>{" "}
                      </div> */}
                    </div>
                    <div className="card custom-card">
                      <div className="card-body">
                        <h5 style={{ textDecoration: "underline" }}>
                          Opportunity to Sales
                        </h5>
                        <div class="table-responsive">
                          <table class="table table-hover table-nowrap">
                            <thead class="table-light">
                              <tr>
                                <th scope="col">Metric</th>
                                <th scope="col">Value</th>
                                {/* <th scope="col">Percentage of Total Leads</th> */}
                                {/* <th scope="col">Rate</th> */}
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td data-label="Email">
                                  <span>Opportunities Identified</span>
                                </td>
                                <td data-label="Phone">
                                  <a
                                    class="text-current"
                                    href="mailto:robert.fox@example.com"
                                  >
                                    243
                                  </a>
                                </td>
                                {/* <td data-label="Lead Score">
                                  <a
                                    class="text-current"
                                    href="tel:202-555-0152"
                                  >
                                    30%
                                  </a>
                                </td> */}
                                {/* <td data-label="Company">
                                  <span class="badge bg-opacity-30 bg-success text-success">
                                    7/10
                                  </span>
                                </td> */}
                              </tr>
                              <tr>
                                <td data-label="Email">
                                  <span>Deals Won</span>
                                </td>
                                <td data-label="Phone">
                                  <a
                                    class="text-current"
                                    href="mailto:robert.fox@example.com"
                                  >
                                    20
                                  </a>
                                </td>
                                {/* <td data-label="Lead Score">
                                  <a
                                    class="text-current"
                                    href="tel:202-555-0152"
                                  >
                                    20%
                                  </a>
                                </td> */}
                                {/* <td data-label="Company">
                                  <span class="badge bg-opacity-30 bg-success text-success">
                                    30%
                                  </span>
                                </td> */}
                              </tr>
                              <tr>
                                <td data-label="Email">
                                  <span>Conversion Rate</span>
                                </td>
                                <td data-label="Phone">
                                  <a
                                    class="text-current"
                                    href="mailto:robert.fox@example.com"
                                  >
                                    22%
                                  </a>
                                </td>
                                {/* <td data-label="Lead Score">
                                  <a
                                    class="text-current"
                                    href="tel:202-555-0152"
                                  >
                                    20%
                                  </a>
                                </td> */}
                                {/* <td data-label="Company">
                                  <span class="badge bg-opacity-30 bg-success text-success">
                                    7/45
                                  </span>
                                </td> */}
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      {/* <div
                        style={{
                          padding: "20px",
                          display: "flex",
                          justifyContent: "space-between",
                          paddingTop: "0",
                        }}
                      >
                        <div style={{ display: "flex" }}>
                          <Select
                            defaultValue="20"
                            // onChange={(value) => {
                            //   handlePageSize(value);
                            //   handleLoader();
                            // }}
                            style={{
                              marginTop: "10px",
                              height: "20px",
                              marginRight: "10px",
                            }}
                          >
                            <Option value="20">20</Option>
                            <Option value="50">50</Option>
                            <Option value="100">100</Option>
                            <Option value="200">200</Option>
                            <Option value="500">500</Option>
                          </Select>
                          <span
                            style={{
                              marginTop: "16px",
                              color: "#808080",
                            }}
                          >
                            Page Size
                          </span>
                        </div>
                        <Stack spacing={2} style={{ marginTop: "20px" }}>
                          <Pagination
                            count={5}
                            page={1}
                            variant="outlined"
                            // onChange={handlePageChange}
                            // onClick={() => handleLoader()}
                            color="primary"
                          />
                        </Stack>{" "}
                      </div> */}
                    </div>
                  </div>
                </div>
                {/* Row */}
              </div>

              {}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LeadStatusReport;
