import React from "react";
import { useState, useEffect } from "react";
import TopHeader from "../../../Components/TopHeader";
import Prince from "../../../Components/Prince";
import { DateRangePicker } from "react-date-range";
import { format } from "date-fns";
import spiner from "../../../assets/img/spiner.gif";
import { MdDateRange } from "react-icons/md";
import FilterComponent from "../ReportComponent/FilterComponent";
import CardComponent from "../ReportComponent/CardComponent";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Select from "@mui/joy/Select";
import Option from "@mui/joy/Option";
import SelectComponent from "../../Sales CRM/SelectComponent";
import PipelineFilterComponent from "../ReportComponent/PipelineFilterComponent";
//import { isArray } from "jodit/types/core/helpers";

const apiUrl = process.env.REACT_APP_URL;
const Token = localStorage.getItem("Token");

function PipelineReport() {
  const [dateFilterModalOPen, setDateFilterModalOpen] = useState(false);
  const [modelOpen, setModalOpen] = useState(false);
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [headerCount, setHeaderCount] = useState([]);
  const [shipmentPotentialCount, setShipmentPotentialCount] = useState([]);
  const [wonLostCount, setWonLostCount] = useState([]);
  const [stageCount, setStageCount] = useState([]);
  const [forecastNumberCount, setforecastNumberCount] = useState([]);
  const [leadSourceOptions, setLeadSourceOptions] = useState([]);
  const [employeeId, setEmployeeId] = useState([]);
  const [state, setStates] = useState([]);
  const [leadStatus, setLeadStatus] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [shipmentPotentialOptions, setShipmentPotentialOptions] = useState([]);

  const onFilterChange = (data) => {
    setFilterData(data);
    //console.log(filterData);
  };

  const [date, setDate] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });
  const handleDateFilterClick = () => {
    setDateFilterModalOpen(true);
  };
  const handleCloseDateFilterModal = () => {
    setDateFilterModalOpen(false);
  };
  // const handleCloseModal = () => {
  //   setModalOpen(false);
  //   // setIsModalOpen(false);
  // };
  // const handleOpenModal = () => {
  //   setModalOpen(true);
  // };
  console.log(filterData);
  const handleDateChange1 = (ranges) => {
    const adjustedStartDate = new Date(ranges.selection.startDate);
    adjustedStartDate.setHours(23, 59, 59, 999);

    const adjustedEndDate = new Date(ranges.selection.endDate);
    adjustedEndDate.setHours(23, 59, 59, 999);

    // setFilterByObj({
    //   ...filterByObj,
    //   to: adjustedEndDate.toISOString(),
    //   from: adjustedStartDate.toISOString(),
    // });
    setFrom(adjustedStartDate.toISOString());
    setTo(adjustedEndDate.toISOString());
    setDate(ranges.selection);
    // setDate({
    //   startDate: adjustedStartDate,
    //   endDate: adjustedEndDate,
    //   key: "selection",
    // });
  };
  console.log(filterData);
  // useEffect(() => {
  const fetchData = async () => {
    try {
      const [response1, response2, response3, response4, response5] =
        await Promise.all([
          fetch(`${apiUrl}/pipeLineReport/headerCount`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authrizarion: `Bearer ${Token}`,
            },
          }),
          fetch(
            `${apiUrl}/pipeLineReport/shipmentPotentialCount?from=${from}&to=${to}&shipmentPotential=${
              filterData.filterByBusinessCategory || ""
            }&employeeId=${filterData.filterByLeadPipeline || ""}&status=${
              filterData.filterByPartner || ""
            }`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authrizarion: `Bearer ${Token}`,
              },
            }
          ),
          fetch(
            `${apiUrl}/pipeLineReport/wonLostCounts?from=${from}&to=${to}&shipmentPotential=${
              filterData.filterByBusinessCategory || ""
            }&employeeId=${filterData.filterByLeadPipeline || ""}&status=${
              filterData.filterByPartner || ""
            }`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authrizarion: `Bearer ${Token}`,
              },
            }
          ),
          fetch(
            `${apiUrl}/pipeLineReport/stageCount?from=${from}&to=${to}&shipmentPotential=${
              filterData.filterByBusinessCategory || ""
            }&employeeId=${filterData.filterByLeadPipeline || ""}&status=${
              filterData.filterByPartner || ""
            }`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authrizarion: `Bearer ${Token}`,
              },
            }
          ),
          fetch(
            `${apiUrl}/pipeLineReport/forecastnumber?from=${from}&to=${to}&shipmentPotential=${
              filterData.filterByBusinessCategory || ""
            }&employeeId=${filterData.filterByLeadPipeline || ""}&status=${
              filterData.filterByPartner || ""
            }`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authrizarion: `Bearer ${Token}`,
              },
            }
          ),
        ]);

      const [data1, data2, data3, data4, data5] = await Promise.all([
        response1.json(),
        response2.json(),
        response3.json(),
        response4.json(),
        response5.json(),
      ]);

      setHeaderCount(data1.data);
      setShipmentPotentialCount(data2.data);
      setWonLostCount(data3.data);
      setStageCount(data4.data);
      setforecastNumberCount(data5.data);
    } catch (error) {
      console.log(`Error in Fetching Data... ${error}`);
    }
  };

  //   fetchData();
  // }, []);
  useEffect(() => {
    fetchData();
  }, []);

  const handleApplyFilter = () => {
    fetchData();
  };

  useEffect(() => {
    // Make an API request to fetch gender options
    fetch(`${apiUrl}/master/getAllMasterData/1`, {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data.data)) {
          setLeadSourceOptions(data.data);
        }
      });

    fetch(`${apiUrl}/employee/allStates/101`, {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data.data)) {
          setStates(data.data);
        }
      })
      .catch((error) => {
        // console.error("Error fetching gender options:", error);
      });
  }, []);

  useEffect(() => {
    fetch(`${apiUrl}/master/getAllMasterData/4`, {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data.data)) {
          setShipmentPotentialOptions(data.data);
        }
      })
      .catch((error) => {});
  }, []);

  useEffect(() => {
    // Make an API request to fetch gender options
    fetch(`${apiUrl}/master/getAllMasterData/8`, {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data.data)) {
          setLeadStatus(data.data);
        }
      });
  }, []);

  useEffect(() => {
    fetch(`${apiUrl}/employee/empDropdownSub`, {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data.data)) {
          setEmployeeId(data.data);
        }
      })
      .catch((error) => {});
  }, []);

  console.log(headerCount);
  return (
    <>
      <div className="page">
        <TopHeader />
        <Prince />
        <div className="main-content side-content pt-0 m-0">
          <div className="main-container container-fluid">
            <div className="inner-body">
              <div className="page-header my-2">
                <div>
                  <h2 className="main-content-title tx-24 mb-0 mt-1 ">
                    Pipeline Report
                  </h2>
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a>Report</a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Pipeline Report
                    </li>
                  </ol>
                </div>
              </div>
              <div style={{ marginTop: "15px" }}>
                <div className="row">
                  <CardComponent
                    title={"Total Leads"}
                    link={"Total Leads"}
                    count={headerCount.totalLeadCount}
                  />
                  <CardComponent
                    title={"Total Open Leads"}
                    link={"Total Open Leads"}
                    count={headerCount.openLeadCount}
                  />

                  <CardComponent
                    title={"Total Won Lead"}
                    link={"Total Won Lead"}
                    count={headerCount.WonLeadCount}
                  />
                  <CardComponent
                    title={"Total Lost Leads"}
                    link={"Total Lost Leads"}
                    count={headerCount.lostLeadCount}
                  />
                  <CardComponent
                    title={"Shipment Potential"}
                    link={"Shipment Potential"}
                    count={headerCount.totalShipment}
                  />
                  <div className="card custom-card">
                    <div className="card-body py-0 pb-3">
                      <div className="d-flex">
                        <div className="col-sm-2 mt-3">
                          <label>Date Filter</label>
                          <button
                            className="calender btn btn-outline-danger"
                            onClick={handleDateFilterClick}
                            style={{
                              fontSize: "11px",
                              width: "215px",
                            }}
                          >
                            <MdDateRange
                              size={16}
                              style={{ display: "inline" }}
                            />{" "}
                            {`${format(
                              date.startDate,
                              "MMM dd yyyy"
                            )} -- ${format(date.endDate, "MMM dd yyyy")}`}
                          </button>
                          <div
                            className={`modal ${
                              dateFilterModalOPen ? "show" : ""
                            } bg-black-7`}
                            style={{
                              display: dateFilterModalOPen ? "block" : "none",
                            }}
                            tabIndex="-1"
                            role="dialog"
                          >
                            <div
                              className=" modal-dialog modal-dialog modal-lg"
                              role="document"
                              style={{ marginTop: "80px" }}
                            >
                              <div
                                className="modal-content"
                                style={{ borderRadius: "8px" }}
                              >
                                <div className="modal-header">
                                  <h5 className="modal-title">
                                    Select Date Range
                                  </h5>
                                  <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    onClick={handleCloseDateFilterModal}
                                  >
                                    <span aria-hidden="true">&times;</span>
                                  </button>
                                </div>
                                <div
                                  className="modal-body"
                                  style={{ width: "100%" }}
                                >
                                  <DateRangePicker
                                    ranges={[date]}
                                    onChange={handleDateChange1}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <PipelineFilterComponent
                          shipmentPotentialOptions={shipmentPotentialOptions}
                          leadStatus={leadStatus}
                          employeeId={employeeId}
                          onFilterChange={onFilterChange}
                        />

                        {/* <FilterComponent
                          leadSourceOptions={[{}, {}, {}]}
                          leadPipelineOptions={[{}, {}, {}]}
                          businessCategoryOptions={[{}, {}, {}]}
                          partnerOptions={[{}, {}, {}]}
                        /> */}

                        {/* <SelectComponent
                          label="Sales Representative"
                          options={leadPipelineOptions}
                          handleSelectChange={handleCheckboxChange1}
                          selectedOptions={filterByLeadPipeline}
                        /> */}
                        <div className="d-flex mt-3">
                          <div className="mr-2">
                            <button
                              style={{ marginTop: "30px" }}
                              className="btn btn-outline-success"
                              onClick={handleApplyFilter}
                            >
                              Apply Filter
                            </button>
                          </div>
                          <div className="mx-2">
                            <button
                              style={{ marginTop: "30px" }}
                              className="btn btn-outline-warning"
                              onClick={() => {
                                setDate({
                                  startDate: new Date(),
                                  endDate: new Date(),
                                  key: "selection",
                                });
                                window.location.reload();
                              }}
                            >
                              Clear Filter
                            </button>
                          </div>
                          {/* <div>
                            <button
                              style={{ marginTop: "30px" }}
                              className="btn btn-outline-info"
                              onClick={handleOpenModal}
                            >
                              Export
                            </button>
                            <div
                              className={`modal ${
                                modelOpen ? "show" : ""
                              } bg-black-7`}
                              style={{
                                display: modelOpen ? "block" : "none",
                              }}
                              tabIndex="-1"
                              role="dialog"
                            >
                              <div
                                className="modal-dialog modal-dialog-centered modal-xl"
                                role="document"
                              >
                                <div className="modal-content rounded-3">
                                  <div className="modal-header">
                                    <h5 className="modal-title">
                                      Download Excel
                                    </h5>
                                    <button
                                      type="button"
                                      className="close"
                                      data-dismiss="modal"
                                      aria-label="Close"
                                      onClick={handleCloseModal}
                                    >
                                      <span aria-hidden="true">&times;</span>
                                    </button>
                                  </div>

                                  <div
                                    className="modal-body "
                                    style={{ width: "100%" }}
                                  >
                                    {from !== "" ? (
                                      <p>Click here to download</p>
                                    ) : (
                                      <p>
                                        Please wait , Download button will
                                        appear soon...{" "}
                                      </p>
                                    )}
                                    {from !== "" ? (
                                      <button
                                        className="btn ripple btn-primary"
                                        type="button"
                                        onClick={handleConfirm}
                                      >
                                        Download
                                      </button>
                                    ) : (
                                      <img src={spiner} height={"50px"}></img>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div> */}
                        </div>
                      </div>{" "}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="card custom-card">
                      <div className="card-body">
                        <h5 style={{ textDecoration: "underline" }}>
                          Lead Won vs Lost
                        </h5>

                        <div class="table-responsive">
                          <table class="table table-hover table-nowrap">
                            <thead class="table-light">
                              <tr>
                                <th scope="col">Metric</th>
                                <th scope="col">Count</th>
                                <th scope="col">Percentage</th>
                                {/* <th scope="col">Rate</th> */}
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td data-label="Email">
                                  <span>Deals Won</span>
                                </td>
                                <td data-label="Phone">
                                  <a
                                    class="text-current"
                                    href="mailto:robert.fox@example.com"
                                  >
                                    {Array.isArray(wonLostCount) &&
                                      wonLostCount.length &&
                                      wonLostCount[0]?.dealWon}
                                  </a>
                                </td>
                                <td data-label="Lead Score">
                                  <a
                                    class="text-current"
                                    href="tel:202-555-0152"
                                  >
                                    {Array.isArray(wonLostCount) &&
                                      wonLostCount.length &&
                                      wonLostCount[0]?.percentage}
                                  </a>
                                </td>
                              </tr>
                              <tr>
                                <td data-label="Email">
                                  <span>Deals Lost</span>
                                </td>
                                <td data-label="Phone">
                                  <a
                                    class="text-current"
                                    href="mailto:robert.fox@example.com"
                                  >
                                    {Array.isArray(wonLostCount) &&
                                      wonLostCount.length &&
                                      wonLostCount[1]?.dealLost}
                                  </a>
                                </td>
                                <td data-label="Lead Score">
                                  <a
                                    class="text-current"
                                    href="tel:202-555-0152"
                                  >
                                    {Array.isArray(wonLostCount) &&
                                      wonLostCount.length &&
                                      wonLostCount[1]?.percentage}
                                  </a>
                                </td>
                              </tr>
                              <tr>
                                <td data-label="Email">
                                  <span>Total Deals</span>
                                </td>
                                <td data-label="Phone">
                                  <a
                                    class="text-current"
                                    href="mailto:robert.fox@example.com"
                                  >
                                    {Array.isArray(wonLostCount) &&
                                      wonLostCount.length &&
                                      wonLostCount[2]?.totalDeal}
                                  </a>
                                </td>
                                <td data-label="Lead Score">
                                  <a
                                    class="text-current"
                                    href="tel:202-555-0152"
                                  >
                                    {Array.isArray(wonLostCount) &&
                                      wonLostCount.length &&
                                      wonLostCount[2]?.percentage}
                                  </a>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      {/* <div
                        style={{
                          padding: "20px",
                          display: "flex",
                          justifyContent: "space-between",
                          paddingTop: "0",
                        }}
                      >
                        <div style={{ display: "flex" }}>
                          <Select
                            defaultValue="20"
                            // onChange={(value) => {
                            //   handlePageSize(value);
                            //   handleLoader();
                            // }}
                            style={{
                              marginTop: "10px",
                              height: "20px",
                              marginRight: "10px",
                            }}
                          >
                            <Option value="20">20</Option>
                            <Option value="50">50</Option>
                            <Option value="100">100</Option>
                            <Option value="200">200</Option>
                            <Option value="500">500</Option>
                          </Select>
                          <span
                            style={{
                              marginTop: "16px",
                              color: "#808080",
                            }}
                          >
                            Page Size
                          </span>
                        </div>
                        <Stack spacing={2} style={{ marginTop: "20px" }}>
                          <Pagination
                            count={5}
                            page={1}
                            variant="outlined"
                            // onChange={handlePageChange}
                            // onClick={() => handleLoader()}
                            color="primary"
                          />
                        </Stack>{" "}
                      </div> */}
                    </div>
                    <div className="card custom-card">
                      <div className="card-body">
                        <h5 style={{ textDecoration: "underline" }}>
                          Shipment Potential (Sum of Total Potential)
                        </h5>

                        <div class="table-responsive">
                          <table class="table table-hover table-nowrap">
                            <thead class="table-light">
                              <tr>
                                <th scope="col">Region / Product Line</th>
                                <th scope="col">Potential (₹)</th>
                                {/* <th scope="col">Percentage</th> */}
                                {/* <th scope="col">Rate</th> */}
                              </tr>
                            </thead>
                            <tbody>
                              {shipmentPotentialCount?.map((item, index) => (
                                <tr key={index}>
                                  <td data-label="Email">
                                    <span>{item.state}</span>
                                  </td>
                                  <td data-label="Phone">
                                    <a
                                      class="text-current"
                                      href="mailto:robert.fox@example.com"
                                    >
                                      {item._count}
                                    </a>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      {/* <div
                        style={{
                          padding: "20px",
                          display: "flex",
                          justifyContent: "space-between",
                          paddingTop: "0",
                        }}
                      >
                        <div style={{ display: "flex" }}>
                          <Select
                            defaultValue="20"
                            // onChange={(value) => {
                            //   handlePageSize(value);
                            //   handleLoader();
                            // }}
                            style={{
                              marginTop: "10px",
                              height: "20px",
                              marginRight: "10px",
                            }}
                          >
                            <Option value="20">20</Option>
                            <Option value="50">50</Option>
                            <Option value="100">100</Option>
                            <Option value="200">200</Option>
                            <Option value="500">500</Option>
                          </Select>
                          <span
                            style={{
                              marginTop: "16px",
                              color: "#808080",
                            }}
                          >
                            Page Size
                          </span>
                        </div>
                        <Stack spacing={2} style={{ marginTop: "20px" }}>
                          <Pagination
                            count={5}
                            page={1}
                            variant="outlined"
                            // onChange={handlePageChange}
                            // onClick={() => handleLoader()}
                            color="primary"
                          />
                        </Stack>{" "}
                      </div> */}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="card custom-card">
                      <div className="card-body">
                        <h5 style={{ textDecoration: "underline" }}>
                          Lead By Lead Stage
                        </h5>
                        <div class="table-responsive">
                          <table class="table table-hover table-nowrap">
                            <thead class="table-light">
                              <tr>
                                <th scope="col">Lead Stage</th>
                                <th scope="col">Number of Leads</th>
                                {/* <th scope="col">Percentage of Total Leads</th> */}
                                {/* <th scope="col">Rate</th> */}
                              </tr>
                            </thead>
                            <tbody>
                              {stageCount?.map((item, index) => (
                                <tr key={index}>
                                  <td data-label="Email">
                                    <span>{item.status}</span>
                                  </td>
                                  <td data-label="Phone">
                                    <a
                                      class="text-current"
                                      href="mailto:robert.fox@example.com"
                                    >
                                      {item._count}
                                    </a>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      {/* <div
                        style={{
                          padding: "20px",
                          display: "flex",
                          justifyContent: "space-between",
                          paddingTop: "0",
                        }}
                      >
                        <div style={{ display: "flex" }}>
                          <Select
                            defaultValue="20"
                            // onChange={(value) => {
                            //   handlePageSize(value);
                            //   handleLoader();
                            // }}
                            style={{
                              marginTop: "10px",
                              height: "20px",
                              marginRight: "10px",
                            }}
                          >
                            <Option value="20">20</Option>
                            <Option value="50">50</Option>
                            <Option value="100">100</Option>
                            <Option value="200">200</Option>
                            <Option value="500">500</Option>
                          </Select>
                          <span
                            style={{
                              marginTop: "16px",
                              color: "#808080",
                            }}
                          >
                            Page Size
                          </span>
                        </div>
                        <Stack spacing={2} style={{ marginTop: "20px" }}>
                          <Pagination
                            count={5}
                            page={1}
                            variant="outlined"
                            // onChange={handlePageChange}
                            // onClick={() => handleLoader()}
                            color="primary"
                          />
                        </Stack>{" "}
                      </div> */}
                    </div>
                    <div className="card custom-card">
                      <div className="card-body">
                        <h5 style={{ textDecoration: "underline" }}>
                          Forecasted Numbers (30% of Declared Potential)
                        </h5>
                        <div class="table-responsive">
                          <table class="table table-hover table-nowrap">
                            <thead class="table-light">
                              <tr>
                                <th scope="col">Metric</th>
                                <th scope="col">Amount</th>
                                {/* <th scope="col">Percentage of Total Leads</th> */}
                                {/* <th scope="col">Rate</th> */}
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td data-label="Email">
                                  <span>Total Declared Potential</span>
                                </td>
                                <td data-label="Phone">
                                  <a
                                    class="text-current"
                                    href="mailto:robert.fox@example.com"
                                  >
                                    {forecastNumberCount?.totalShipmentCount}
                                  </a>
                                </td>
                              </tr>
                              <tr>
                                <td data-label="Email">
                                  <span>Forecasted Sales (30%)</span>
                                </td>
                                <td data-label="Phone">
                                  <a
                                    class="text-current"
                                    href="mailto:robert.fox@example.com"
                                  >
                                    {forecastNumberCount?.forecastedSale}
                                  </a>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      {/* <div
                        style={{
                          padding: "20px",
                          display: "flex",
                          justifyContent: "space-between",
                          paddingTop: "0",
                        }}
                      >
                        <div style={{ display: "flex" }}>
                          <Select
                            defaultValue="20"
                            // onChange={(value) => {
                            //   handlePageSize(value);
                            //   handleLoader();
                            // }}
                            style={{
                              marginTop: "10px",
                              height: "20px",
                              marginRight: "10px",
                            }}
                          >
                            <Option value="20">20</Option>
                            <Option value="50">50</Option>
                            <Option value="100">100</Option>
                            <Option value="200">200</Option>
                            <Option value="500">500</Option>
                          </Select>
                          <span
                            style={{
                              marginTop: "16px",
                              color: "#808080",
                            }}
                          >
                            Page Size
                          </span>
                        </div>
                        <Stack spacing={2} style={{ marginTop: "20px" }}>
                          <Pagination
                            count={5}
                            page={1}
                            variant="outlined"
                            // onChange={handlePageChange}
                            // onClick={() => handleLoader()}
                            color="primary"
                          />
                        </Stack>{" "}
                      </div> */}
                    </div>
                  </div>
                </div>
                {/* Row */}
              </div>

              {}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PipelineReport;
