import React, { useState } from "react";
import SelectComponent from "../../Sales CRM/SelectComponent"; // Adjust the import path as needed

function PipelineFilterComponent({
  leadStatus,
  shipmentPotentialOptions,
  employeeId,
  onFilterChange,
}) {
  //console.log("AMAN", num++);
  const [filterByLeadPipeline, setFilterByLeadPipeline] = useState([]);
  const [filterByBusinessCategory, setFilterByBusinessCategory] = useState([]);
  const [filterByPartner, setFilterByPartner] = useState([]);

  const handleCheckboxChange1 = (selectedOptions) => {
    const values = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    //setFilterByLeadPipeline(values);
    const id = selectedOptions
      ? selectedOptions.map((option) => option.id)
      : [];
    setFilterByLeadPipeline(values);
    onFilterChange && onFilterChange({ filterByLeadPipeline: id });
    // onFilterChange && onFilterChange({ filterByLeadPipeline: values });
  };

  const handleCheckboxChange2 = (selectedOptions) => {
    const values = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    setFilterByBusinessCategory(values);
    onFilterChange && onFilterChange({ filterByBusinessCategory: values });
  };

  const handleCheckboxChange3 = (selectedOptions) => {
    const values = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    setFilterByPartner(values);
    onFilterChange && onFilterChange({ filterByPartner: values });
  };

  // console.log(headerCount);
  return (
    <>
      <SelectComponent
        label="Sales Representative"
        options={employeeId}
        handleSelectChange={handleCheckboxChange1}
        selectedOptions={filterByLeadPipeline}
      />

      <SelectComponent
        label="Shipment Potential"
        options={shipmentPotentialOptions}
        handleSelectChange={handleCheckboxChange2}
        selectedOptions={filterByBusinessCategory}
      />

      <SelectComponent
        label="Opportunity Stage"
        options={leadStatus}
        handleSelectChange={handleCheckboxChange3}
        selectedOptions={filterByPartner}
      />
      {/* <SelectComponent
        label="Region/Territory"
        options={partnerOptions}
        handleSelectChange={handleCheckboxChange3}
        selectedOptions={filterByPartner}
      /> */}
    </>
  );
}

export default PipelineFilterComponent;
