// import React from "react";
// import { useState, useEffect, useRef } from "react";
// import { toast } from "react-toastify";
// import Menu from "@mui/material/Menu";
// import MenuItem from "@mui/material/MenuItem";
// import "./Support.css";
// import { FaEye } from "react-icons/fa6";
// import Editor from "./Editor";
// import styles from "./Support.module.css";
// import EmailPopUp from "./EmailPopUp";
// import CryptoJS from "crypto-js";
// import { useLocation } from "react-router-dom";

// const useQuery = () => {
//   return new URLSearchParams(useLocation().search);
// };

// function SupportByUrlCaseId(apiData) {
//   const [subject, setSubject] = useState("");
//   const [openEmail, setOpenEmail] = React.useState(false);
//   const [itemDataPopUp, setItemDataPopUp] = useState("");
//   const [to, setTo] = useState("");
//   const [cc, setCc] = useState("");

//   const handleCloseMailPopUp = () => {
//     setOpenEmail(false);
//   };
//   const handleOpenMailPopUp = () => {
//     setOpenEmail(true);
//   };
//   const [anchorEl, setAnchorEl] = React.useState(null);
//   const open = Boolean(anchorEl);

//   const handleClose = () => {
//     setAnchorEl(null);
//   };

//   const query = useQuery();
//   const [caseId, setCaseId] = useState({ caseId: "" });
//   const [checkCompanyId, setCheckCompanyId] = useState("");
//   const [encryptedValue, setEncryptedValue] = useState("");
//   const [emailFile, setEmailFile] = useState([]);

//   useEffect(() => {
//     const pathArray = window.location.pathname.split("/");
//     const companyIdFromUrl = pathArray[pathArray.length - 1];

//     const encryptedValueFromUrl = query.get("key");

//     setCheckCompanyId(companyIdFromUrl);
//     setEncryptedValue(encryptedValueFromUrl);
//   }, [query]);

//   function decryptValue(encryptedValue) {
//     const key = "Fship"; // Replace with your actual key
//     const bytes = CryptoJS.AES.decrypt(encryptedValue, key);
//     const decryptedValue = bytes.toString(CryptoJS.enc.Utf8);

//     return decryptedValue;
//   }

//   function validateCompanyId(checkCompanyId, encryptedValue) {
//     if (!encryptedValue) return false; // Add a check to handle undefined or null encryptedValue
//     const decryptedValue = decryptValue(encryptedValue);
//     const [decryptedCompanyId, constantString] = decryptedValue.split("_");
//     return (
//       decryptedCompanyId === checkCompanyId && constantString === "FshipData"
//     );
//   }

//   useEffect(() => {
//     if (encryptedValue) {
//       if (validateCompanyId(checkCompanyId, encryptedValue)) {
//         setCaseId({ caseId: checkCompanyId });
//       } else {
//         toast.error("Invalid Company ID");
//       }
//     }
//   }, [checkCompanyId, encryptedValue]);

//   const handleEmailFile = async (event) => {
//     const file = event.target.files[0];

//     setEmailFile(file);
//   };

//   const [content, setContent] = useState("");
//   const handleEditorChange = (newContent) => {
//     setContent(newContent);
//   };

//   const sendEmail = async () => {
//     if (!subject || !content) {
//       toast.error("Please fill out all required fields.");
//       return;
//     }

//     const formData = new FormData();
//     // formData.append("receiverEmail", to);
//     // formData.append("ccEmail", cc);
//     formData.append("subject", subject);
//     formData.append("message", content);

//     if (emailFile) {
//       formData.append("uploadedFile", emailFile); // Append the file to FormData
//     }

//     try {
//       const response = await fetch(
//         `${apiUrl}/caseM/sendEmailToKam?caseId=${caseId.caseId}`,
//         {
//           method: "POST",

//           body: formData,
//         }
//       );

//       const data = await response.json();

//       if (response.ok) {
//         setSubject("");
//         // setCc(""); // Assuming you also want to clear 'cc'
//         setContent("");
//         toast.success("Email sent successfully!");
//         setTimeout(() => {
//           window.location.reload();
//         }, 3000);
//       } else {
//         toast.error(data.message || "Failed to send email.");
//       }
//     } catch (error) {
//       toast.error("An error occurred while sending the email.");
//       console.error("Error:", error);
//     }
//   };

//   // const sendEmail = async () => {
//   //   if (true) {
//   //     const payload = {
//   //       receiverEmail: to,
//   //       ccEmail: cc,
//   //       subject: subject,
//   //       message: content,
//   //     };

//   //     try {
//   //       const response = await fetch(
//   //         `${apiUrl}/caseM/sendEmailToKam?caseId=${caseId.caseId}`,
//   //         {
//   //           method: "POST",
//   //           headers: {
//   //             "Content-Type": "application/json",
//   //           },
//   //           body: JSON.stringify(payload),
//   //         }
//   //       );
//   //       const data = await response.json();
//   //       if (response.ok) {
//   //         setSubject("");

//   //         setContent("");
//   //         toast.success("Email sent successfully!");
//   //         setTimeout(() => {
//   //           window.location.reload();
//   //         }, 3000);
//   //       } else {
//   //         toast.error(data.message);
//   //       }
//   //     } catch (error) {
//   //       console.error("Error:", error);
//   //     }
//   //   }
//   // };

//   const [emailData, setEmailData] = useState([]);
//   const [datestamp, setDatestamp] = useState("");
//   const [isNewUser, setIsNewUser] = useState(true);

//   const [employeeList, setEmployeeList] = useState({});

//   const apiUrl = process.env.REACT_APP_URL;

//   useEffect(() => {
//     fetch(`${apiUrl}/caseM/getEmailList?caseId=${caseId.caseId}`, {})
//       .then((response) => response.json())
//       .then((data) => {
//         if (data.status === "success") {
//           if (Array.isArray(data.data)) {
//             setEmailData(data.data);
//           } else {
//             console.error("API response does not contain users array:", data);
//           }
//         } else {
//           console.error("API request was not successful:", data);
//         }
//       })
//       .catch((error) => {
//         console.error("Error fetching data:", error);
//       });
//   }, [caseId]);

//   const getCurrentDate = () => {
//     const today = new Date();
//     const options = {
//       weekday: "long",
//       year: "numeric",
//       month: "long",
//       day: "numeric",
//     };
//     return today.toLocaleDateString(undefined, options);
//   };

//   useEffect(() => {
//     const currentDate = getCurrentDate();
//     setDatestamp(currentDate);
//   }, []);

//   const capitalizeFirstLetterOfEachWord = (str) => {
//     return str.replace(/\b\w/g, (char) => char.toUpperCase());
//   };

//   const formatDate = (dateString) => {
//     const date = new Date(dateString);
//     const today = new Date();
//     const yesterday = new Date();
//     yesterday.setDate(today.getDate() - 1);

//     if (date.toDateString() === today.toDateString()) {
//       return "Today";
//     } else if (date.toDateString() === yesterday.toDateString()) {
//       return "Yesterday";
//     } else {
//       const options = {
//         weekday: "short",
//         month: "short",
//         day: "numeric",
//         year: "numeric",
//       };
//       return date.toLocaleDateString("en-US", options);
//     }
//   };

//   useEffect(() => {
//     setIsNewUser(true);
//   }, []);

//   async function getEmp() {
//     const url = `${apiUrl}/caseM/getCase?caseId=${caseId.caseId}`;

//     const Token = localStorage.getItem("Token");
//     // console.log("Token " + Token);

//     let response = await fetch(url, {
//       method: "GET",
//       headers: {
//         Authorization: `Bearer ${Token}`,
//       },
//     });
//     response = await response.json();

//     setTo(response.data?.customer.emailId);
//   }
//   useEffect(() => {
//     if (!caseId.caseId === "") {
//       getEmp();
//     }
//   }, [caseId]);

//   return (
//     <>
//       <div>
//         <div className="page">
//           <div className="main-content  pt-0 ">
//             <div className="main-container container-fluid">
//               <div className="inner-body">
//                 {/* Page Header */}
//                 <div className="page-header">
//                   <div>
//                     <h2 className="main-content-title tx-24 mg-b-5">
//                       Support/NDR ID:<span>{caseId.caseId}</span>
//                     </h2>
//                     <ol className="breadcrumb">
//                       <li
//                         className="breadcrumb-item active"
//                         aria-current="page"
//                       >
//                         Support / All Tickets /<span>{caseId.caseId}</span>
//                       </li>
//                     </ol>
//                   </div>
//                 </div>

//                 <div className="row row-sm">
//                   <div
//                     className="col-sm-12 col-md-12 col-lg-12 col-xl-3"
//                     style={{ display: "none" }}
//                   >
//                     <div className="card custom-card chat-account">
//                       <div className="d-block pt-0">
//                         <div className="card-body text-center">
//                           <div className="user-lock text-center">
//                             <a href="#">
//                               <img
//                                 alt="avatar"
//                                 className="rounded-10"
//                                 src="/assets/img/users/user-img1.jpg"
//                               />
//                             </a>
//                           </div>
//                           <a href="profile.html">
//                             <h5 className=" mb-1 mt-3 main-content-label">
//                               Rajiv Kumar
//                             </h5>
//                           </a>
//                           <p className="mb-0 mt-1 text-muted">
//                             KAM/Support Agent
//                           </p>
//                           <p className="mb-0 mt-1 text-muted">Fship Company</p>
//                         </div>
//                         <div className="card-body">
//                           <h6 className="mb-3 tx-semibold">Contact Details</h6>
//                           <div className="d-flex">
//                             <div>
//                               <p className="contact-icon text-primary m-0">
//                                 <i className="fe fe-phone" />
//                               </p>
//                             </div>
//                             <div className="ms-3">
//                               <p className="tx-13 mb-0 tx-semibold">Phone</p>
//                               <p className="tx-12 text-muted">+1 135792468</p>
//                             </div>
//                           </div>
//                           <div className="d-flex">
//                             <div>
//                               <p className="contact-icon text-primary m-0">
//                                 <i className="fe fe-mail" />
//                               </p>
//                             </div>
//                             <div className="ms-3">
//                               <p className="tx-13 mb-0 tx-semibold">Email</p>
//                               <p className="tx-12 text-muted">
//                                 harvey@gmail.com.
//                               </p>
//                             </div>
//                           </div>
//                           <div className="d-flex">
//                             <div>
//                               <p className="contact-icon text-primary m-0">
//                                 <i className="fe fe-map-pin" />
//                               </p>
//                             </div>
//                             <div className="ms-3">
//                               <p className="tx-13 mb-0 tx-semibold">Address</p>
//                               <p className="tx-12 text-muted mb-0">
//                                 California.
//                               </p>
//                             </div>
//                           </div>
//                         </div>
//                         <div className="card-body">
//                           <h6 className="mb-3 tx-semibold">Shared Files</h6>
//                           <div className="media mb-3">
//                             <p className="contact-icon text-primary m-0">
//                               <i className="mdi mdi-file-image" />
//                             </p>
//                             <div className="media-body ms-3 d-flex">
//                               <div>
//                                 <p className="tx-13 text-dark mb-0 tx-semibold">
//                                   Image1.jpg
//                                 </p>
//                                 <span className="tx-12 text-muted">200 KB</span>
//                               </div>
//                               <div className="ms-auto my-auto">
//                                 <a href=";" className="btn px-0">
//                                   <i className="fe fe-download my-auto text-muted tx-18" />
//                                 </a>
//                               </div>
//                             </div>
//                           </div>
//                           <div className="media mb-3">
//                             <p className="contact-icon text-danger m-0">
//                               <i className="mdi mdi-file-pdf" />
//                             </p>
//                             <div className="media-body ms-3 d-flex">
//                               <div>
//                                 <p className="tx-13 text-dark mb-0 tx-semibold">
//                                   Doc1.pdf
//                                 </p>
//                                 <span className="tx-12 text-muted">48 KB</span>
//                               </div>
//                               <div className="ms-auto my-auto">
//                                 <a href=";" className="btn px-0">
//                                   <i className="fe fe-download my-auto text-muted tx-18" />
//                                 </a>
//                               </div>
//                             </div>
//                           </div>
//                           <div className="media mb-3">
//                             <p className="contact-icon text-info m-0">
//                               <i className="mdi mdi-file-word" />
//                             </p>
//                             <div className="media-body ms-3 d-flex">
//                               <div>
//                                 <p className="tx-13 text-dark mb-0 tx-semibold">
//                                   Word1.doc
//                                 </p>
//                                 <span className="tx-12 text-muted">35 KB</span>
//                               </div>
//                               <div className="ms-auto my-auto">
//                                 <a href=";" className="btn px-0">
//                                   <i className="fe fe-download my-auto text-muted tx-18" />
//                                 </a>
//                               </div>
//                             </div>
//                           </div>
//                           <div className="media">
//                             <p className="contact-icon text-warning m-0">
//                               <i className="mdi mdi-file-powerpoint" />
//                             </p>
//                             <div className="media-body ms-3 d-flex">
//                               <div>
//                                 <p className="tx-13 text-dark mb-0 tx-semibold">
//                                   Example1.ppt
//                                 </p>
//                                 <span className="tx-12 text-muted">54 KB</span>
//                               </div>
//                               <div className="ms-auto my-auto">
//                                 <a href=";" className="btn px-0">
//                                   <i className="fe fe-download my-auto text-muted tx-18" />
//                                 </a>
//                               </div>
//                             </div>
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                   <div className="col-sm-12 col-md-12 col-lg-7 col-xl-7">
//                     <div className="card custom-card">
//                       <div className=" pt-0">
//                         <div className={styles.App}>
//                           <input
//                             type="text"
//                             placeholder="Subject"
//                             value={subject ?? ""}
//                             onChange={(e) => setSubject(e.target.value)}
//                             className={styles.input}
//                             style={{ color: "#808080" }}
//                           />

//                           <Editor
//                             value={content}
//                             onChange={handleEditorChange}
//                           ></Editor>
//                           {/* <button
//                             onClick={sendEmail}
//                             className="btn btn-primary mt-2"
//                           >
//                             Click to Send
//                           </button> */}
//                           {/* <div
//                             style={{
//                               display: "flex",
//                               justifyContent: "space-between",
//                             }}
//                           >
//                             <button
//                               onClick={sendEmail}
//                               className="btn btn-primary mt-2"
//                             >
//                               Click to Send
//                             </button>
//                             <div className="mt-3">
//                               <input
//                                 type="file"
//                                 onChange={(event) => handleEmailFile(event)}
//                               />
//                             </div>
//                           </div> */}
//                           <div class="input-group mt-3">
//                             <input
//                               type="file"
//                               class="form-control"
//                               id="inputGroupFile02"
//                               onChange={(event) => handleEmailFile(event)}
//                             />
//                             {/* <label
//                                   class="input-group-text"
//                                   for="inputGroupFile02"
//                                 >
//                                   Upload
//                                 </label> */}
//                           </div>
//                           <button
//                             onClick={sendEmail}
//                             className="btn btn-primary ml-3 mt-3"
//                           >
//                             Send Email
//                           </button>

//                           <div className="list-group-container"></div>
//                         </div>

//                         <EmailPopUp
//                           item={itemDataPopUp}
//                           handleClose={handleCloseMailPopUp}
//                           open={openEmail}
//                         ></EmailPopUp>
//                         <div className="main-content-body main-content-body-chat"></div>
//                       </div>
//                     </div>
//                   </div>
//                   <div className="col-sm-12 col-md-12 col-lg-7 col-xl-5">
//                     <div className="card custom-card">
//                       <div className=" pt-0">
//                         <div className="main-content-body main-content-body-chat">
//                           <div
//                             className="main-chat-header pt-3"
//                             style={{
//                               background: "#f4f6ff",
//                               borderTopLeftRadius: 10,
//                               borderTopRightRadius: 10,
//                               display: "flex",
//                               justifyContent: "space-between",
//                             }}
//                           >
//                             <div className="main-chat-msg-name">
//                               <h6>Email &amp; List</h6>
//                             </div>

//                             <div>
//                               <Menu
//                                 id="demo-positioned-menu"
//                                 aria-labelledby="demo-positioned-button"
//                                 anchorEl={anchorEl}
//                                 open={open}
//                                 onClose={handleClose}
//                                 anchorOrigin={{
//                                   vertical: "top",
//                                   horizontal: "left",
//                                 }}
//                                 transformOrigin={{
//                                   vertical: "top",
//                                   horizontal: "left",
//                                 }}
//                               >
//                                 {employeeList.EmployeeCasesM?.map(
//                                   (item, index) => (
//                                     <MenuItem key={index}>
//                                       {capitalizeFirstLetterOfEachWord(
//                                         item.employee?.firstName
//                                       )}{" "}
//                                       {capitalizeFirstLetterOfEachWord(
//                                         item.employee?.lastName
//                                       )}
//                                     </MenuItem>
//                                   )
//                                 )}
//                               </Menu>
//                             </div>
//                           </div>

//                           <div
//                             className="main-chat-body"
//                             id="ChatBody"
//                             style={{
//                               overflowY: "scroll",
//                               height: "400px",
//                               padding: "20px",
//                             }}
//                           >
//                             <div className="list-group mt-2 ">
//                               {emailData?.map((item, index) => (
//                                 <a
//                                   className="list-group-item list-group-item-action flex-column align-items-start"
//                                   key={index}
//                                   style={{
//                                     border: "1px solid #B2BEB5",
//                                     marginBottom: "10px",
//                                   }}
//                                 >
//                                   {" "}
//                                   <span
//                                     style={{
//                                       position: "absolute",
//                                       left: "-10px",
//                                       bottom: "40px",
//                                       backgroundColor: "#219C90",
//                                       color: "#f4f4f4",
//                                       fontSize: "9px",
//                                       borderRadius: "5px",
//                                       padding: "3px",
//                                       zIndex: 30,
//                                     }}
//                                   >
//                                     {item.isCustomer === true
//                                       ? "Customer"
//                                       : "KAM"}
//                                   </span>
//                                   <div
//                                     className="d-flex w-100 justify-content-between"
//                                     style={{
//                                       height: "20px",
//                                     }}
//                                   >
//                                     <span
//                                       className="d-flex "
//                                       style={{ width: "80%" }}
//                                     >
//                                       <FaEye
//                                         className="mr-2"
//                                         onClick={() => {
//                                           handleOpenMailPopUp();
//                                           setItemDataPopUp(item);
//                                         }}
//                                         style={{
//                                           cursor: "pointer",
//                                           marginTop: "3px",
//                                         }}
//                                       />

//                                       <span
//                                         className="text-truncate mb-0 font-weight-bold"
//                                         style={{ width: "90%" }}
//                                       >
//                                         {`${item.subject}`}
//                                       </span>
//                                     </span>
//                                     <small>{formatDate(item.createdAt)}</small>
//                                   </div>
//                                 </a>
//                               ))}
//                             </div>
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>

//         <a href="#top" id="back-to-top">
//           <i className="fe fe-arrow-up" />
//         </a>
//       </div>
//     </>
//   );
// }

// export default SupportByUrlCaseId;

import React from "react";
import { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import "./Support.css";
import { FaEye } from "react-icons/fa6";
import Editor from "./Editor";
import styles from "./Support.module.css";
import EmailPopUp from "./EmailPopUp";
import CryptoJS from "crypto-js";
import { useLocation, useSearchParams } from "react-router-dom";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

function SupportByUrlCaseId(apiData) {
  const [subject, setSubject] = useState("");
  const [openEmail, setOpenEmail] = React.useState(false);
  const [itemDataPopUp, setItemDataPopUp] = useState("");
  const [to, setTo] = useState("");
  const [cc, setCc] = useState("");

  const [searchParams] = useSearchParams();

  const handleCloseMailPopUp = () => {
    setOpenEmail(false);
  };
  const handleOpenMailPopUp = () => {
    setOpenEmail(true);
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const query = useQuery();
  console.log(query.size);
  const [caseId, setCaseId] = useState({ caseId: "" });
  const [checkCompanyId, setCheckCompanyId] = useState("");
  const [encryptedValue, setEncryptedValue] = useState("");
  const [emailFile, setEmailFile] = useState([]);
  const [awbNumber, setAwbNumber] = useState("");
  const [tabId, setTabId] = useState("");
  // const [caseId, setCaseId] = useState("");

  useEffect(() => {
    const pathArray = window.location.pathname.split("/");
    const companyIdFromUrl = pathArray[pathArray.length - 1];

    const encryptedValueFromUrl = query.get("key");

    setCheckCompanyId(companyIdFromUrl);
    setEncryptedValue(encryptedValueFromUrl);
    setAwbNumber(searchParams.get("awbNumber") || "");
    setTabId(searchParams.get("tabId") || "");
    setCaseId({ caseId: searchParams.get("caseId") || "" });
  }, [query.size]);

  function decryptValue(encryptedValue) {
    const key = "Fship"; // Replace with your actual key
    const bytes = CryptoJS.AES.decrypt(encryptedValue, key);
    const decryptedValue = bytes.toString(CryptoJS.enc.Utf8);

    return decryptedValue;
  }

  function validateCompanyId(checkCompanyId, encryptedValue) {
    if (!encryptedValue) return false; // Add a check to handle undefined or null encryptedValue
    const decryptedValue = decryptValue(encryptedValue);
    const [decryptedCompanyId, constantString] = decryptedValue.split("_");
    return (
      decryptedCompanyId === checkCompanyId && constantString === "FshipData"
    );
  }

  useEffect(() => {
    // if (encryptedValue) {
    //   if (validateCompanyId(checkCompanyId, encryptedValue)) {
    //     setCaseId({ caseId: checkCompanyId });
    //   } else {
    //     toast.error("Invalid Company ID");
    //   }
    // }
    setCaseId({ caseId: checkCompanyId });
  }, [checkCompanyId, encryptedValue]);

  const handleEmailFile = async (event) => {
    const file = event.target.files[0];

    setEmailFile(file);
  };

  const [content, setContent] = useState("");
  const handleEditorChange = (newContent) => {
    setContent(newContent);
  };

  const sendEmail = async () => {
    if (!subject || !content) {
      toast.error("Please fill out all required fields.");
      return;
    }

    const formData = new FormData();
    // formData.append("receiverEmail", to);
    // formData.append("ccEmail", cc);
    formData.append("subject", subject);
    formData.append("message", content);

    if (emailFile) {
      formData.append("uploadedFile", emailFile); // Append the file to FormData
    }

    try {
      const response = await fetch(
        `${apiUrl}/caseM/sendEmailToKam?caseId=${caseId.caseId}`,
        {
          method: "POST",

          body: formData,
        }
      );

      const data = await response.json();

      if (response.ok) {
        setSubject("");
        // setCc(""); // Assuming you also want to clear 'cc'
        setContent("");
        toast.success("Email sent successfully!");
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else {
        toast.error(data.message || "Failed to send email.");
      }
    } catch (error) {
      toast.error("An error occurred while sending the email.");
      console.error("Error:", error);
    }
  };

  // const sendEmail = async () => {
  //   if (true) {
  //     const payload = {
  //       receiverEmail: to,
  //       ccEmail: cc,
  //       subject: subject,
  //       message: content,
  //     };

  //     try {
  //       const response = await fetch(
  //         `${apiUrl}/caseM/sendEmailToKam?caseId=${caseId.caseId}`,
  //         {
  //           method: "POST",
  //           headers: {
  //             "Content-Type": "application/json",
  //           },
  //           body: JSON.stringify(payload),
  //         }
  //       );
  //       const data = await response.json();
  //       if (response.ok) {
  //         setSubject("");

  //         setContent("");
  //         toast.success("Email sent successfully!");
  //         setTimeout(() => {
  //           window.location.reload();
  //         }, 3000);
  //       } else {
  //         toast.error(data.message);
  //       }
  //     } catch (error) {
  //       console.error("Error:", error);
  //     }
  //   }
  // };

  const [emailData, setEmailData] = useState([]);
  const [datestamp, setDatestamp] = useState("");
  const [isNewUser, setIsNewUser] = useState(true);

  const [employeeList, setEmployeeList] = useState({});

  const apiUrl = process.env.REACT_APP_URL;

  // useEffect(() => {
  //   fetch(`${apiUrl}/caseM/getEmailList?caseId=${caseId.caseId}`, {})
  //     .then((response) => response.json())
  //     .then((data) => {
  //       if (data.status === "success") {
  //         if (Array.isArray(data.data)) {
  //           setEmailData(data.data);
  //         } else {
  //           console.error("API response does not contain users array:", data);
  //         }
  //       } else {
  //         console.error("API request was not successful:", data);
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching data:", error);
  //     });
  // }, []);

  useEffect(() => {
    if (!caseId?.caseId) return; // Prevent API call if caseId is undefined/null

    fetch(`${apiUrl}/caseM/getEmailList?caseId=${caseId.caseId}`)
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          if (Array.isArray(data.data)) {
            setEmailData(data.data);
          } else {
            console.error("API response does not contain users array:", data);
          }
        } else {
          console.error("API request was not successful:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [caseId?.caseId]); // Depend only on the `caseId` value

  const getCurrentDate = () => {
    const today = new Date();
    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    return today.toLocaleDateString(undefined, options);
  };

  useEffect(() => {
    const currentDate = getCurrentDate();
    setDatestamp(currentDate);
  }, []);

  const capitalizeFirstLetterOfEachWord = (str) => {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const today = new Date();
    const yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);

    if (date.toDateString() === today.toDateString()) {
      return "Today";
    } else if (date.toDateString() === yesterday.toDateString()) {
      return "Yesterday";
    } else {
      const options = {
        weekday: "short",
        month: "short",
        day: "numeric",
        year: "numeric",
      };
      return date.toLocaleDateString("en-US", options);
    }
  };

  useEffect(() => {
    setIsNewUser(true);
  }, []);

  async function getEmp() {
    const url = `${apiUrl}/caseM/getCase?caseId=${caseId.caseId}`;

    const Token = localStorage.getItem("Token");
    // console.log("Token " + Token);

    let response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    });
    response = await response.json();

    setTo(response.data?.customer.emailId);
  }
  useEffect(() => {
    if (!caseId.caseId === "") {
      getEmp();
    }
  }, [caseId]);

  return (
    <>
      <div>
        <div className="page">
          <div className="main-content  pt-0 ">
            <div className="main-container container-fluid">
              <div className="inner-body">
                {/* Page Header */}
                <div className="page-header">
                  <div>
                    <h2 className="main-content-title tx-24 mg-b-5">
                      Support/NDR ID:<span>{caseId.caseId}</span>
                    </h2>
                    <ol className="breadcrumb">
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        Support / All Tickets /<span>{caseId.caseId}</span>
                      </li>
                    </ol>
                  </div>
                </div>

                <div className="row row-sm">
                  <div
                    className="col-sm-12 col-md-12 col-lg-12 col-xl-3"
                    style={{ display: "none" }}
                  >
                    <div className="card custom-card chat-account">
                      <div className="d-block pt-0">
                        <div className="card-body text-center">
                          <div className="user-lock text-center">
                            <a href="#">
                              <img
                                alt="avatar"
                                className="rounded-10"
                                src="/assets/img/users/user-img1.jpg"
                              />
                            </a>
                          </div>
                          <a href="profile.html">
                            <h5 className=" mb-1 mt-3 main-content-label">
                              Rajiv Kumar
                            </h5>
                          </a>
                          <p className="mb-0 mt-1 text-muted">
                            KAM/Support Agent
                          </p>
                          <p className="mb-0 mt-1 text-muted">Fship Company</p>
                        </div>
                        <div className="card-body">
                          <h6 className="mb-3 tx-semibold">Contact Details</h6>
                          <div className="d-flex">
                            <div>
                              <p className="contact-icon text-primary m-0">
                                <i className="fe fe-phone" />
                              </p>
                            </div>
                            <div className="ms-3">
                              <p className="tx-13 mb-0 tx-semibold">Phone</p>
                              <p className="tx-12 text-muted">+1 135792468</p>
                            </div>
                          </div>
                          <div className="d-flex">
                            <div>
                              <p className="contact-icon text-primary m-0">
                                <i className="fe fe-mail" />
                              </p>
                            </div>
                            <div className="ms-3">
                              <p className="tx-13 mb-0 tx-semibold">Email</p>
                              <p className="tx-12 text-muted">
                                harvey@gmail.com.
                              </p>
                            </div>
                          </div>
                          <div className="d-flex">
                            <div>
                              <p className="contact-icon text-primary m-0">
                                <i className="fe fe-map-pin" />
                              </p>
                            </div>
                            <div className="ms-3">
                              <p className="tx-13 mb-0 tx-semibold">Address</p>
                              <p className="tx-12 text-muted mb-0">
                                California.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="card-body">
                          <h6 className="mb-3 tx-semibold">Shared Files</h6>
                          <div className="media mb-3">
                            <p className="contact-icon text-primary m-0">
                              <i className="mdi mdi-file-image" />
                            </p>
                            <div className="media-body ms-3 d-flex">
                              <div>
                                <p className="tx-13 text-dark mb-0 tx-semibold">
                                  Image1.jpg
                                </p>
                                <span className="tx-12 text-muted">200 KB</span>
                              </div>
                              <div className="ms-auto my-auto">
                                <a href=";" className="btn px-0">
                                  <i className="fe fe-download my-auto text-muted tx-18" />
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="media mb-3">
                            <p className="contact-icon text-danger m-0">
                              <i className="mdi mdi-file-pdf" />
                            </p>
                            <div className="media-body ms-3 d-flex">
                              <div>
                                <p className="tx-13 text-dark mb-0 tx-semibold">
                                  Doc1.pdf
                                </p>
                                <span className="tx-12 text-muted">48 KB</span>
                              </div>
                              <div className="ms-auto my-auto">
                                <a href=";" className="btn px-0">
                                  <i className="fe fe-download my-auto text-muted tx-18" />
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="media mb-3">
                            <p className="contact-icon text-info m-0">
                              <i className="mdi mdi-file-word" />
                            </p>
                            <div className="media-body ms-3 d-flex">
                              <div>
                                <p className="tx-13 text-dark mb-0 tx-semibold">
                                  Word1.doc
                                </p>
                                <span className="tx-12 text-muted">35 KB</span>
                              </div>
                              <div className="ms-auto my-auto">
                                <a href=";" className="btn px-0">
                                  <i className="fe fe-download my-auto text-muted tx-18" />
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="media">
                            <p className="contact-icon text-warning m-0">
                              <i className="mdi mdi-file-powerpoint" />
                            </p>
                            <div className="media-body ms-3 d-flex">
                              <div>
                                <p className="tx-13 text-dark mb-0 tx-semibold">
                                  Example1.ppt
                                </p>
                                <span className="tx-12 text-muted">54 KB</span>
                              </div>
                              <div className="ms-auto my-auto">
                                <a href=";" className="btn px-0">
                                  <i className="fe fe-download my-auto text-muted tx-18" />
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-7 col-xl-7">
                    <div className="card custom-card">
                      <div className=" pt-0">
                        <div className={styles.App}>
                          <input
                            type="text"
                            placeholder="Subject"
                            value={subject ?? ""}
                            onChange={(e) => setSubject(e.target.value)}
                            className={styles.input}
                            style={{ color: "#808080" }}
                          />

                          <Editor
                            value={content}
                            onChange={handleEditorChange}
                          ></Editor>
                          {/* <button
                            onClick={sendEmail}
                            className="btn btn-primary mt-2"
                          >
                            Click to Send
                          </button> */}
                          {/* <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <button
                              onClick={sendEmail}
                              className="btn btn-primary mt-2"
                            >
                              Click to Send
                            </button>
                            <div className="mt-3">
                              <input
                                type="file"
                                onChange={(event) => handleEmailFile(event)}
                              />
                            </div>
                          </div> */}
                          <div class="input-group mt-3">
                            <input
                              type="file"
                              class="form-control"
                              id="inputGroupFile02"
                              onChange={(event) => handleEmailFile(event)}
                            />
                            {/* <label
                                  class="input-group-text"
                                  for="inputGroupFile02"
                                >
                                  Upload
                                </label> */}
                          </div>
                          <button
                            onClick={sendEmail}
                            className="btn btn-primary ml-3 mt-3"
                          >
                            Send Email
                          </button>

                          <div className="list-group-container"></div>
                        </div>

                        <EmailPopUp
                          item={itemDataPopUp}
                          handleClose={handleCloseMailPopUp}
                          open={openEmail}
                        ></EmailPopUp>
                        <div className="main-content-body main-content-body-chat"></div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-7 col-xl-5">
                    <div className="card custom-card">
                      <div className=" pt-0">
                        <div className="main-content-body main-content-body-chat">
                          <div
                            className="main-chat-header pt-3"
                            style={{
                              background: "#f4f6ff",
                              borderTopLeftRadius: 10,
                              borderTopRightRadius: 10,
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div className="main-chat-msg-name">
                              <h6>Email &amp; List</h6>
                            </div>

                            <div>
                              <Menu
                                id="demo-positioned-menu"
                                aria-labelledby="demo-positioned-button"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                anchorOrigin={{
                                  vertical: "top",
                                  horizontal: "left",
                                }}
                                transformOrigin={{
                                  vertical: "top",
                                  horizontal: "left",
                                }}
                              >
                                {employeeList.EmployeeCasesM?.map(
                                  (item, index) => (
                                    <MenuItem key={index}>
                                      {capitalizeFirstLetterOfEachWord(
                                        item.employee?.firstName
                                      )}{" "}
                                      {capitalizeFirstLetterOfEachWord(
                                        item.employee?.lastName
                                      )}
                                    </MenuItem>
                                  )
                                )}
                              </Menu>
                            </div>
                          </div>

                          <div
                            className="main-chat-body"
                            id="ChatBody"
                            style={{
                              overflowY: "scroll",
                              height: "400px",
                              padding: "20px",
                            }}
                          >
                            <div className="list-group mt-2 ">
                              {emailData?.map((item, index) => (
                                <a
                                  className="list-group-item list-group-item-action flex-column align-items-start"
                                  key={index}
                                  style={{
                                    border: "1px solid #B2BEB5",
                                    marginBottom: "10px",
                                  }}
                                >
                                  {" "}
                                  <span
                                    style={{
                                      position: "absolute",
                                      left: "-10px",
                                      bottom: "40px",
                                      backgroundColor: "#219C90",
                                      color: "#f4f4f4",
                                      fontSize: "9px",
                                      borderRadius: "5px",
                                      padding: "3px",
                                      zIndex: 30,
                                    }}
                                  >
                                    {item.isCustomer === true
                                      ? "Customer"
                                      : "KAM"}
                                  </span>
                                  <div
                                    className="d-flex w-100 justify-content-between"
                                    style={{
                                      height: "20px",
                                    }}
                                  >
                                    <span
                                      className="d-flex "
                                      style={{ width: "80%" }}
                                    >
                                      <FaEye
                                        className="mr-2"
                                        onClick={() => {
                                          handleOpenMailPopUp();
                                          setItemDataPopUp(item);
                                        }}
                                        style={{
                                          cursor: "pointer",
                                          marginTop: "3px",
                                        }}
                                      />

                                      <span
                                        className="text-truncate mb-0 font-weight-bold"
                                        style={{ width: "90%" }}
                                      >
                                        {`${item.subject}`}
                                      </span>
                                    </span>
                                    <small>{formatDate(item.createdAt)}</small>
                                  </div>
                                </a>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <a href="#top" id="back-to-top">
          <i className="fe fe-arrow-up" />
        </a>
      </div>
    </>
  );
}

export default SupportByUrlCaseId;
