import React, { useEffect } from "react";
import { useState } from "react";
import TopHeader from "../../../Components/TopHeader";
import Prince from "../../../Components/Prince";
import { DateRangePicker } from "react-date-range";
import { format } from "date-fns";
import spiner from "../../../assets/img/spiner.gif";
import { MdDateRange } from "react-icons/md";
import FilterComponent from "../ReportComponent/FilterComponent";
import CardComponent from "../ReportComponent/CardComponent";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Select from "@mui/joy/Select";
import Option from "@mui/joy/Option";
import SupportTicketFilterComp from "../ReportComponent/SupportTicketFilterComp";
import ClientFilterComponent from "../ReportComponent/ClientFilterComponent";

const apiUrl = process.env.REACT_APP_URL;
const Token = localStorage.getItem("Token");

function ClientResponseTimeReport() {
  const [dateFilterModalOPen, setDateFilterModalOpen] = useState(false);
  const [modelOpen, setModalOpen] = useState(false);
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [averageResponseTime, setAverageResponseTime] = useState("");
  const [delayResponse, setDelayResponse] = useState([]);
  const [date, setDate] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });
  const handleDateFilterClick = () => {
    setDateFilterModalOpen(true);
  };
  const handleCloseDateFilterModal = () => {
    setDateFilterModalOpen(false);
  };
  // const handleCloseModal = () => {
  //   setModalOpen(false);
  //   // setIsModalOpen(false);
  // };
  // const handleOpenModal = () => {
  //   setModalOpen(true);
  // };
  const handleDateChange1 = (ranges) => {
    const adjustedStartDate = new Date(ranges.selection.startDate);
    adjustedStartDate.setHours(23, 59, 59, 999);

    const adjustedEndDate = new Date(ranges.selection.endDate);
    adjustedEndDate.setHours(23, 59, 59, 999);

    // setFilterByObj({
    //   ...filterByObj,
    //   to: adjustedEndDate.toISOString(),
    //   from: adjustedStartDate.toISOString(),
    // });
    setFrom(adjustedStartDate.toISOString());
    setTo(adjustedEndDate.toISOString());
    setDate(ranges.selection);
    // setDate({
    //   startDate: adjustedStartDate,
    //   endDate: adjustedEndDate,
    //   key: "selection",
    // });
  };

  // useEffect(() => {
  const fetchApi2 = async () => {
    try {
      const response = await fetch(
        `${apiUrl}/customerResponseReport/avgResponseTime?from=${from}&to=${to}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Autharization: `Bearer ${Token}`,
          },
        }
      );

      const data = await response.json();
      setAverageResponseTime(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  //   fetchApi();
  // }, []);
  // useEffect(() => {
  const fetchApi1 = async () => {
    try {
      const response = await fetch(
        `${apiUrl}/customerResponseReport/ticketsWithResponseDelays?from=${from}&to=${to}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Autharization: `Bearer ${Token}`,
          },
        }
      );

      const data = await response.json();
      setDelayResponse(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  // fetchApi();
  // }, []);
  useEffect(() => {
    fetchApi1();
    fetchApi2();
  }, []);

  const handleApplyFilter = () => {
    fetchApi1();
    fetchApi2();
  };
  return (
    <>
      <div className="page">
        <TopHeader />
        <Prince />
        <div className="main-content side-content pt-0 m-0">
          <div className="main-container container-fluid">
            <div className="inner-body">
              <div className="page-header my-2">
                <div>
                  <h2 className="main-content-title tx-24 mb-0 mt-1 ">
                    Client Response Time Report
                  </h2>
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a>Report</a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Client Response Time Report
                    </li>
                  </ol>
                </div>
              </div>
              <div style={{ marginTop: "15px" }}>
                <div className="row">
                  {/* <CardComponent
                    title={"Total Tickets"}
                    link={"Total Tickets"}
                    count={"344"}
                  />
                  <CardComponent
                    title={"Open Tickets"}
                    link={"Open Tickets"}
                    count={"33"}
                  />
                  <CardComponent
                    title={"Closed Tickets"}
                    link={"Closed Tickets"}
                    count={"34"}
                  />
                  <CardComponent
                    title={"Average Resolution Time"}
                    link={"Average Resolution Time"}
                    count={"4 hours/days"}
                  />
                  <CardComponent
                    title={"SLA Adherence"}
                    link={"SLA Adherence"}
                    count={"53%"}
                  /> */}
                  <div className="card custom-card">
                    <div className="card-body py-0 pb-3">
                      <div className="row">
                        <div className="col-sm-2 mt-3">
                          <label>Date Filter</label>
                          <button
                            className="calender btn btn-outline-danger"
                            onClick={handleDateFilterClick}
                            style={{
                              fontSize: "11px",
                              width: "215px",
                            }}
                          >
                            <MdDateRange
                              size={16}
                              style={{ display: "inline" }}
                            />{" "}
                            {`${format(
                              date.startDate,
                              "MMM dd yyyy"
                            )} -- ${format(date.endDate, "MMM dd yyyy")}`}
                          </button>
                          <div
                            className={`modal ${
                              dateFilterModalOPen ? "show" : ""
                            } bg-black-7`}
                            style={{
                              display: dateFilterModalOPen ? "block" : "none",
                            }}
                            tabIndex="-1"
                            role="dialog"
                          >
                            <div
                              className=" modal-dialog modal-dialog modal-lg"
                              role="document"
                              style={{ marginTop: "80px" }}
                            >
                              <div
                                className="modal-content"
                                style={{ borderRadius: "8px" }}
                              >
                                <div className="modal-header">
                                  <h5 className="modal-title">
                                    Select Date Range
                                  </h5>
                                  <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    onClick={handleCloseDateFilterModal}
                                  >
                                    <span aria-hidden="true">&times;</span>
                                  </button>
                                </div>
                                <div
                                  className="modal-body"
                                  style={{ width: "100%" }}
                                >
                                  <DateRangePicker
                                    ranges={[date]}
                                    onChange={handleDateChange1}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <ClientFilterComponent
                          leadSourceOptions={[{}, {}, {}]}
                          leadPipelineOptions={[{}, {}, {}]}
                          businessCategoryOptions={[{}, {}, {}]}
                          partnerOptions={[{}, {}, {}]}
                        /> */}
                        <div className="d-flex">
                          <div className="mr-2">
                            <button
                              style={{ marginTop: "30px" }}
                              className="btn btn-outline-success"
                              onClick={handleApplyFilter}
                            >
                              Apply Filter
                            </button>
                          </div>
                          <div className="mx-2">
                            <button
                              style={{ marginTop: "30px" }}
                              className="btn btn-outline-warning"
                              onClick={() => {
                                setDate({
                                  startDate: new Date(),
                                  endDate: new Date(),
                                  key: "selection",
                                });
                                // window.location.reload();
                              }}
                            >
                              Clear Filter
                            </button>
                          </div>
                          {/* <div>
                            <button
                              style={{ marginTop: "30px" }}
                              className="btn btn-outline-info"
                              onClick={handleOpenModal}
                            >
                              Export
                            </button>
                            <div
                              className={`modal ${
                                modelOpen ? "show" : ""
                              } bg-black-7`}
                              style={{
                                display: modelOpen ? "block" : "none",
                              }}
                              tabIndex="-1"
                              role="dialog"
                            >
                              <div
                                className="modal-dialog modal-dialog-centered modal-xl"
                                role="document"
                              >
                                <div className="modal-content rounded-3">
                                  <div className="modal-header">
                                    <h5 className="modal-title">
                                      Download Excel
                                    </h5>
                                    <button
                                      type="button"
                                      className="close"
                                      data-dismiss="modal"
                                      aria-label="Close"
                                      onClick={handleCloseModal}
                                    >
                                      <span aria-hidden="true">&times;</span>
                                    </button>
                                  </div>

                                  <div
                                    className="modal-body "
                                    style={{ width: "100%" }}
                                  >
                                    {from !== "" ? (
                                      <p>Click here to download</p>
                                    ) : (
                                      <p>
                                        Please wait , Download button will
                                        appear soon...{" "}
                                      </p>
                                    )}
                                    {from !== "" ? (
                                      <button
                                        className="btn ripple btn-primary"
                                        type="button"
                                        onClick={handleConfirm}
                                      >
                                        Download
                                      </button>
                                    ) : (
                                      <img src={spiner} height={"50px"}></img>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div> */}
                        </div>
                      </div>{" "}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="card custom-card">
                      <div className="card-body">
                        <h5 style={{ textDecoration: "underline" }}>
                          Summary Overview
                        </h5>

                        <div class="table-responsive">
                          <table class="table table-hover table-nowrap">
                            <thead class="table-light">
                              <tr>
                                <th scope="col">Metric</th>
                                <th scope="col">Value</th>
                                {/* <th scope="col">Percentage</th> */}
                                {/* <th scope="col">Rate</th> */}
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td data-label="Email">
                                  <span>Average Response Time</span>
                                </td>
                                {/* <td data-label="Phone">
                                  <a
                                    class="text-current"
                                    href="mailto:robert.fox@example.com"
                                  >
                                    60
                                  </a>
                                </td> */}
                                <td data-label="Lead Score">
                                  <a
                                    class="text-current"
                                    href="tel:202-555-0152"
                                  >
                                    {averageResponseTime.averageResolutionTime}
                                  </a>
                                </td>
                                {/* <td data-label="Company">
                                  <span class="badge bg-opacity-30 bg-success text-success">
                                    7/10
                                  </span>
                                </td> */}
                              </tr>
                              <tr>
                                <td data-label="Email">
                                  <span>Total Cases</span>
                                </td>
                                {/* <td data-label="Phone">
                                  <a
                                    class="text-current"
                                    href="mailto:robert.fox@example.com"
                                  >
                                    40
                                  </a>
                                </td> */}
                                <td data-label="Lead Score">
                                  <a
                                    class="text-current"
                                    href="tel:202-555-0152"
                                  >
                                    {averageResponseTime.totalCases}
                                  </a>
                                </td>
                                {/* <td data-label="Company">
                                  <span class="badge bg-opacity-30 bg-success text-success">
                                    7/10
                                  </span>
                                </td> */}
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      {/* <div
                        style={{
                          padding: "20px",
                          display: "flex",
                          justifyContent: "space-between",
                          paddingTop: "0",
                        }}
                      >
                        <div style={{ display: "flex" }}>
                          <Select
                            defaultValue="20"
                            // onChange={(value) => {
                            //   handlePageSize(value);
                            //   handleLoader();
                            // }}
                            style={{
                              marginTop: "10px",
                              height: "20px",
                              marginRight: "10px",
                            }}
                          >
                            <Option value="20">20</Option>
                            <Option value="50">50</Option>
                            <Option value="100">100</Option>
                            <Option value="200">200</Option>
                            <Option value="500">500</Option>
                          </Select>
                          <span
                            style={{
                              marginTop: "16px",
                              color: "#808080",
                            }}
                          >
                            Page Size
                          </span>
                        </div>
                        <Stack spacing={2} style={{ marginTop: "20px" }}>
                          <Pagination
                            count={5}
                            page={1}
                            variant="outlined"
                            // onChange={handlePageChange}
                            // onClick={() => handleLoader()}
                            color="primary"
                          />
                        </Stack>{" "}
                      </div> */}
                    </div>
                    {/* <div className="card custom-card">
                      <div className="card-body">
                        <h5 style={{ textDecoration: "underline" }}>
                          NPS Calculation Summary
                        </h5>
                        <div class="table-responsive">
                          <table class="table table-hover table-nowrap">
                            <thead class="table-light">
                              <tr>
                                <th scope="col">Category</th>
                                <th scope="col">Number of Tickets</th>
                               
                                <th scope="col">Percentage</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td data-label="Email">
                                  <span>Promoters</span>
                                </td>
                                <td data-label="Phone">
                                  <a
                                    class="text-current"
                                    href="mailto:robert.fox@example.com"
                                  >
                                    20
                                  </a>
                                </td>
                                <td data-label="Phone">
                                  <a
                                    class="text-current"
                                    href="mailto:robert.fox@example.com"
                                  >
                                    20%
                                  </a>
                                </td>
                              
                              </tr>
                              <tr>
                                <td data-label="Email">
                                  <span>Passives</span>
                                </td>
                                <td data-label="Phone">
                                  <a
                                    class="text-current"
                                    href="mailto:robert.fox@example.com"
                                  >
                                    40
                                  </a>
                                </td>
                                
                                <td data-label="Phone">
                                  <a
                                    class="text-current"
                                    href="mailto:robert.fox@example.com"
                                  >
                                    67%
                                  </a>
                                </td>
                               
                              </tr>
                              <tr>
                                <td data-label="Email">
                                  <span>Detractors</span>
                                </td>
                                <td data-label="Phone">
                                  <a
                                    class="text-current"
                                    href="mailto:robert.fox@example.com"
                                  >
                                    30
                                  </a>
                                </td>
                                
                                <td data-label="Phone">
                                  <a
                                    class="text-current"
                                    href="mailto:robert.fox@example.com"
                                  >
                                    89%
                                  </a>
                                </td>
                               
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      
                    </div>
                    <div className="card custom-card">
                      <div className="card-body">
                        <h5 style={{ textDecoration: "underline" }}>
                          Average First Response Time (By Status)
                        </h5>
                        <div class="table-responsive">
                          <table class="table table-hover table-nowrap">
                            <thead class="table-light">
                              <tr>
                                <th scope="col">Status</th>
                                <th scope="col">Average First Response Time</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td data-label="Email">
                                  <span>Open</span>
                                </td>
                                <td data-label="Phone">
                                  <a
                                    class="text-current"
                                    href="mailto:robert.fox@example.com"
                                  >
                                    20
                                  </a>
                                </td>
                               
                              </tr>
                              <tr>
                                <td data-label="Email">
                                  <span>Closed</span>
                                </td>
                                <td data-label="Phone">
                                  <a
                                    class="text-current"
                                    href="mailto:robert.fox@example.com"
                                  >
                                    90
                                  </a>
                                </td>
                                
                             
                              </tr>
                              <tr>
                                <td data-label="Email">
                                  <span>Escalated</span>
                                </td>
                                <td data-label="Phone">
                                  <a
                                    class="text-current"
                                    href="mailto:robert.fox@example.com"
                                  >
                                    30
                                  </a>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      
                    </div> */}
                  </div>
                  <div className="col-md-6">
                    <div className="card custom-card">
                      <div className="card-body">
                        <h5 style={{ textDecoration: "underline" }}>
                          Tickets with Response Delays
                        </h5>
                        <div class="table-responsive">
                          <table class="table table-hover table-nowrap">
                            <thead class="table-light">
                              <tr>
                                <th scope="col">Title</th>
                                <th scope="col">Count</th>
                                {/* <th scope="col">SLA Breach (Yes/No)</th>
                                <th scope="col">Response Delay (in Hours)</th> */}
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td data-label="Email">
                                  <span>Delayed Ticket Count</span>
                                </td>
                                <td data-label="Phone">
                                  <a
                                    class="text-current"
                                    href="mailto:robert.fox@example.com"
                                  >
                                    {delayResponse.delayedTicketsCount}
                                  </a>
                                </td>
                                {/* <td data-label="Lead Score">
                                  <a
                                    class="text-current"
                                    href="tel:202-555-0152"
                                  >
                                    Yes
                                  </a>
                                </td>
                                <td data-label="Company">
                                  <span class="">3</span>
                                </td> */}
                              </tr>
                              {/* <tr>
                                <td data-label="Email">
                                  <span>#23424</span>
                                </td>
                                <td data-label="Phone">
                                  <a
                                    class="text-current"
                                    href="mailto:robert.fox@example.com"
                                  >
                                    6 hours
                                  </a>
                                </td>
                                <td data-label="Lead Score">
                                  <a
                                    class="text-current"
                                    href="tel:202-555-0152"
                                  >
                                    Yes
                                  </a>
                                </td>
                                <td data-label="Company">
                                  <span class="">3</span>
                                </td>
                              </tr>
                              <tr>
                                <td data-label="Email">
                                  <span>#23424</span>
                                </td>
                                <td data-label="Phone">
                                  <a
                                    class="text-current"
                                    href="mailto:robert.fox@example.com"
                                  >
                                    6 hours
                                  </a>
                                </td>
                                <td data-label="Lead Score">
                                  <a
                                    class="text-current"
                                    href="tel:202-555-0152"
                                  >
                                    Yes
                                  </a>
                                </td>
                                <td data-label="Company">
                                  <span class="">3</span>
                                </td>
                              </tr>
                              <tr>
                                <td data-label="Email">
                                  <span>#23424</span>
                                </td>
                                <td data-label="Phone">
                                  <a
                                    class="text-current"
                                    href="mailto:robert.fox@example.com"
                                  >
                                    6 hours
                                  </a>
                                </td>
                                <td data-label="Lead Score">
                                  <a
                                    class="text-current"
                                    href="tel:202-555-0152"
                                  >
                                    Yes
                                  </a>
                                </td>
                                <td data-label="Company">
                                  <span class="">3</span>
                                </td>
                              </tr> */}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      {/* <div
                        style={{
                          padding: "20px",
                          display: "flex",
                          justifyContent: "space-between",
                          paddingTop: "0",
                        }}
                      >
                        <div style={{ display: "flex" }}>
                          <Select
                            defaultValue="20"
                            // onChange={(value) => {
                            //   handlePageSize(value);
                            //   handleLoader();
                            // }}
                            style={{
                              marginTop: "10px",
                              height: "20px",
                              marginRight: "10px",
                            }}
                          >
                            <Option value="20">20</Option>
                            <Option value="50">50</Option>
                            <Option value="100">100</Option>
                            <Option value="200">200</Option>
                            <Option value="500">500</Option>
                          </Select>
                          <span
                            style={{
                              marginTop: "16px",
                              color: "#808080",
                            }}
                          >
                            Page Size
                          </span>
                        </div>
                        <Stack spacing={2} style={{ marginTop: "20px" }}>
                          <Pagination
                            count={5}
                            page={1}
                            variant="outlined"
                            // onChange={handlePageChange}
                            // onClick={() => handleLoader()}
                            color="primary"
                          />
                        </Stack>{" "}
                      </div> */}
                    </div>

                    <div className="card custom-card">
                      {/* <div className="card-body">
                        <h5 style={{ textDecoration: "underline" }}>
                          Ticket Feedback Ratings Breakdown
                        </h5>
                        <div class="table-responsive">
                          <table class="table table-hover table-nowrap">
                            <thead class="table-light">
                              <tr>
                                <th scope="col">Ticket ID</th>
                                <th scope="col">Rating (0-10)</th>
                                <th scope="col">Feedback</th>
                                <th scope="col">Category</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td data-label="Email">
                                  <span>#12345</span>
                                </td>
                                <td data-label="Phone">
                                  <a
                                    class="text-current"
                                    href="mailto:robert.fox@example.com"
                                  >
                                    10
                                  </a>
                                </td>
                                <td data-label="Phone">
                                  <a
                                    class="text-current"
                                    href="mailto:robert.fox@example.com"
                                  >
                                    Excellent support!
                                  </a>
                                </td>
                                <td data-label="Phone">
                                  <a
                                    class="text-current"
                                    href="mailto:robert.fox@example.com"
                                  >
                                    Promoter
                                  </a>
                                </td>
                                
                              </tr>
                              <tr>
                                <td data-label="Email">
                                  <span>#12345</span>
                                </td>
                                <td data-label="Phone">
                                  <a
                                    class="text-current"
                                    href="mailto:robert.fox@example.com"
                                  >
                                    10
                                  </a>
                                </td>
                                <td data-label="Phone">
                                  <a
                                    class="text-current"
                                    href="mailto:robert.fox@example.com"
                                  >
                                    Excellent support!
                                  </a>
                                </td>
                                <td data-label="Phone">
                                  <a
                                    class="text-current"
                                    href="mailto:robert.fox@example.com"
                                  >
                                    Promoter
                                  </a>
                                </td>
                                
                              </tr>
                              <tr>
                                <td data-label="Email">
                                  <span>#12345</span>
                                </td>
                                <td data-label="Phone">
                                  <a
                                    class="text-current"
                                    href="mailto:robert.fox@example.com"
                                  >
                                    10
                                  </a>
                                </td>
                                <td data-label="Phone">
                                  <a
                                    class="text-current"
                                    href="mailto:robert.fox@example.com"
                                  >
                                    Excellent support!
                                  </a>
                                </td>
                                <td data-label="Phone">
                                  <a
                                    class="text-current"
                                    href="mailto:robert.fox@example.com"
                                  >
                                    Promoter
                                  </a>
                                </td>
                                
                              </tr>
                              <tr>
                                <td data-label="Email">
                                  <span>#12345</span>
                                </td>
                                <td data-label="Phone">
                                  <a
                                    class="text-current"
                                    href="mailto:robert.fox@example.com"
                                  >
                                    10
                                  </a>
                                </td>
                                <td data-label="Phone">
                                  <a
                                    class="text-current"
                                    href="mailto:robert.fox@example.com"
                                  >
                                    Excellent support!
                                  </a>
                                </td>
                                <td data-label="Phone">
                                  <a
                                    class="text-current"
                                    href="mailto:robert.fox@example.com"
                                  >
                                    Promoter
                                  </a>
                                </td>
                               
                              </tr>
                              <tr>
                                <td data-label="Email">
                                  <span>#12345</span>
                                </td>
                                <td data-label="Phone">
                                  <a
                                    class="text-current"
                                    href="mailto:robert.fox@example.com"
                                  >
                                    10
                                  </a>
                                </td>
                                <td data-label="Phone">
                                  <a
                                    class="text-current"
                                    href="mailto:robert.fox@example.com"
                                  >
                                    Excellent support!
                                  </a>
                                </td>
                                <td data-label="Phone">
                                  <a
                                    class="text-current"
                                    href="mailto:robert.fox@example.com"
                                  >
                                    Promoter
                                  </a>
                                </td>
                               
                              </tr>
                              <tr>
                                <td data-label="Email">
                                  <span>#12345</span>
                                </td>
                                <td data-label="Phone">
                                  <a
                                    class="text-current"
                                    href="mailto:robert.fox@example.com"
                                  >
                                    10
                                  </a>
                                </td>
                                <td data-label="Phone">
                                  <a
                                    class="text-current"
                                    href="mailto:robert.fox@example.com"
                                  >
                                    Excellent support!
                                  </a>
                                </td>
                                <td data-label="Phone">
                                  <a
                                    class="text-current"
                                    href="mailto:robert.fox@example.com"
                                  >
                                    Promoter
                                  </a>
                                </td>
                              </tr>
                              <tr>
                                <td data-label="Email">
                                  <span>#12345</span>
                                </td>
                                <td data-label="Phone">
                                  <a
                                    class="text-current"
                                    href="mailto:robert.fox@example.com"
                                  >
                                    10
                                  </a>
                                </td>
                                <td data-label="Phone">
                                  <a
                                    class="text-current"
                                    href="mailto:robert.fox@example.com"
                                  >
                                    Excellent support!
                                  </a>
                                </td>
                                <td data-label="Phone">
                                  <a
                                    class="text-current"
                                    href="mailto:robert.fox@example.com"
                                  >
                                    Promoter
                                  </a>
                                </td>
                                
                              </tr>
                              <tr>
                                <td data-label="Email">
                                  <span>#12345</span>
                                </td>
                                <td data-label="Phone">
                                  <a
                                    class="text-current"
                                    href="mailto:robert.fox@example.com"
                                  >
                                    10
                                  </a>
                                </td>
                                <td data-label="Phone">
                                  <a
                                    class="text-current"
                                    href="mailto:robert.fox@example.com"
                                  >
                                    Excellent support!
                                  </a>
                                </td>
                                <td data-label="Phone">
                                  <a
                                    class="text-current"
                                    href="mailto:robert.fox@example.com"
                                  >
                                    Promoter
                                  </a>
                                </td>
                               
                              </tr>
                              <tr>
                                <td data-label="Email">
                                  <span>#12345</span>
                                </td>
                                <td data-label="Phone">
                                  <a
                                    class="text-current"
                                    href="mailto:robert.fox@example.com"
                                  >
                                    10
                                  </a>
                                </td>
                                <td data-label="Phone">
                                  <a
                                    class="text-current"
                                    href="mailto:robert.fox@example.com"
                                  >
                                    Excellent support!
                                  </a>
                                </td>
                                <td data-label="Phone">
                                  <a
                                    class="text-current"
                                    href="mailto:robert.fox@example.com"
                                  >
                                    Promoter
                                  </a>
                                </td>
                               
                              </tr>
                              <tr>
                                <td data-label="Email">
                                  <span>#12345</span>
                                </td>
                                <td data-label="Phone">
                                  <a
                                    class="text-current"
                                    href="mailto:robert.fox@example.com"
                                  >
                                    10
                                  </a>
                                </td>
                                <td data-label="Phone">
                                  <a
                                    class="text-current"
                                    href="mailto:robert.fox@example.com"
                                  >
                                    Excellent support!
                                  </a>
                                </td>
                                <td data-label="Phone">
                                  <a
                                    class="text-current"
                                    href="mailto:robert.fox@example.com"
                                  >
                                    Promoter
                                  </a>
                                </td>
                               
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div> */}
                      {/* <div
                        style={{
                          padding: "20px",
                          display: "flex",
                          justifyContent: "space-between",
                          paddingTop: "0",
                        }}
                      >
                        <div style={{ display: "flex" }}>
                          <Select
                            defaultValue="20"
                            // onChange={(value) => {
                            //   handlePageSize(value);
                            //   handleLoader();
                            // }}
                            style={{
                              marginTop: "10px",
                              height: "20px",
                              marginRight: "10px",
                            }}
                          >
                            <Option value="20">20</Option>
                            <Option value="50">50</Option>
                            <Option value="100">100</Option>
                            <Option value="200">200</Option>
                            <Option value="500">500</Option>
                          </Select>
                          <span
                            style={{
                              marginTop: "16px",
                              color: "#808080",
                            }}
                          >
                            Page Size
                          </span>
                        </div>
                        <Stack spacing={2} style={{ marginTop: "20px" }}>
                          <Pagination
                            count={5}
                            page={1}
                            variant="outlined"
                            // onChange={handlePageChange}
                            // onClick={() => handleLoader()}
                            color="primary"
                          />
                        </Stack>{" "}
                      </div> */}
                    </div>
                  </div>
                </div>
                {/* Row */}
              </div>

              {}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ClientResponseTimeReport;
