import React, { useState } from "react";
import SelectComponent from "../../Sales CRM/SelectComponent"; // Adjust the import path as needed
let num = 0;
function ActivityFilterComponent({
  // leadSourceOptions,
  // leadPipelineOptions,
  // businessCategoryOptions,
  // partnerOptions,
  onFilterChange,
  employeeId,
}) {
  console.log("AMAN", num++);
  const [filterByLeadSource, setFilterByLeadSource] = useState([]);
  const [filterByLeadPipeline, setFilterByLeadPipeline] = useState([]);
  const [filterByBusinessCategory, setFilterByBusinessCategory] = useState([]);
  const [filterByPartner, setFilterByPartner] = useState([]);

  const handleCheckboxChange = (selectedOptions) => {
    const values = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    setFilterByLeadSource(values);
    onFilterChange && onFilterChange({ filterByLeadSource: values });
  };

  const handleCheckboxChange1 = (selectedOptions) => {
    const values = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    const id = selectedOptions
      ? selectedOptions.map((option) => option.id)
      : [];
    setFilterByLeadPipeline(values);
    onFilterChange && onFilterChange({ filterByLeadPipeline: id });
    // setFilterByLeadPipeline(values);
    // onFilterChange && onFilterChange({ filterByLeadPipeline: values });
  };

  const handleCheckboxChange2 = (selectedOptions) => {
    const values = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    setFilterByBusinessCategory(values);
    onFilterChange && onFilterChange({ filterByBusinessCategory: values });
  };

  const handleCheckboxChange3 = (selectedOptions) => {
    const values = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    setFilterByPartner(values);
    onFilterChange && onFilterChange({ filterByPartner: values });
  };

  return (
    <>
      {/* <SelectComponent
        label="Lead Source"
        options={leadSourceOptions}
        handleSelectChange={handleCheckboxChange}
        selectedOptions={filterByLeadSource}
      /> */}

      <SelectComponent
        label="Sales Representative"
        options={employeeId}
        handleSelectChange={handleCheckboxChange1}
        selectedOptions={filterByLeadPipeline}
      />

      {/* <SelectComponent
        label="Activity Type"
        options={businessCategoryOptions}
        handleSelectChange={handleCheckboxChange2}
        selectedOptions={filterByBusinessCategory}
      /> */}

      {/* <SelectComponent
        label="Activity Status"
        options={partnerOptions}
        handleSelectChange={handleCheckboxChange3}
        selectedOptions={filterByPartner}
      /> */}
      {/* <SelectComponent
        label="Region/Territory"
        options={partnerOptions}
        handleSelectChange={handleCheckboxChange3}
        selectedOptions={filterByPartner}
      /> */}
    </>
  );
}

export default ActivityFilterComponent;
