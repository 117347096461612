import React, { useState, useEffect } from "react";
import Box from "@mui/material";
import { Button, TextField, MenuItem, FormLabel } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CreateButton from "../../Components/createTicket/CreateButton";
import { toast } from "react-toastify";
import InputField from "../../Components/createTicket/InputField";

const apiUrl = process.env.REACT_APP_URL;

const WrongPackageDelivered = ({
  customerId,
  selectedValue4Id,
  caseId,
  prefilledAwb,
  tabId,
}) => {
  const [reason, setReason] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [files, setFiles] = useState({});
  const [awbNumber, setAwbNumber] = useState("");

  const handleAwbNumber = (event) => {
    setAwbNumber(event.target.value);
  };
  const handleFileChange = (event, label) => {
    const file = event.target.files[0];
    if (file) {
      setFiles((prevFiles) => ({ ...prevFiles, [label]: file }));
    }
  };
  useEffect(() => {
    if (prefilledAwb) setAwbNumber(prefilledAwb);
  }, [prefilledAwb]);

  const handleCreateCase = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("caseType", caseId);
    formData.append("caseSubType", selectedValue4Id);
    formData.append("alternateNumber", mobileNumber);
    formData.append("selectReason", reason);
    formData.append("isMultipleAWB", false);
    formData.append("awbNo", awbNumber);
    formData.append("tabId", tabId);
    Object.keys(files).forEach((key) => {
      formData.append(key, files[key]);
    });

    const url = customerId.companyId
      ? `${apiUrl}/caseM/addCase?companyId=${customerId.companyId}`
      : `${apiUrl}/caseM/addCase?customerId=${customerId}`;

    try {
      const response = await fetch(url, {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        toast.success("Case Created successfully!");
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else {
        toast.error("Failed to create case. Please try again.");
      }
    } catch (error) {
      toast.error("Failed to create case. Please try again.");
    }
  };

  return (
    <div>
      <div className="mt-3">
        <FormLabel>
          <p className="text-xs mb-0 text-black">AWB Number</p>
        </FormLabel>
        <TextField
          fullWidth
          type="text"
          value={awbNumber}
          onChange={handleAwbNumber}
          variant="outlined"
          size="small"
          className="mt-2"
          InputProps={{
            sx: {
              "& input": {
                color: "#373A40",
              },
            },
          }}
        />
      </div>
      <div className="row">
        <div className="col-md-6">
          <FormLabel>
            <p className="text-xs mb-0 text-black">
              Select a reason for raising a dispute *
            </p>
          </FormLabel>
          <TextField
            select
            fullWidth
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            variant="outlined"
            size="small"
            className="mt-2"
          >
            <MenuItem value="damaged">
              <p className="text-xs mb-0">Damaged Package</p>
            </MenuItem>
            <MenuItem value="wrong">
              {" "}
              <p className="text-xs mb-0">Wrong Item Received</p>
            </MenuItem>
            <MenuItem value="empty">
              {" "}
              <p className="text-xs mb-0">Empty Package</p>
            </MenuItem>
          </TextField>
        </div>
        <div className="col-md-6">
          <FormLabel>
            <p className="text-xs mb-0 text-black">Alternate Mobile Number</p>
          </FormLabel>
          <TextField
            fullWidth
            type="text"
            value={mobileNumber}
            onChange={(e) => setMobileNumber(e.target.value)}
            variant="outlined"
            size="small"
            className="mt-2"
            InputProps={{
              sx: {
                "& input": {
                  color: "#373A40",
                },
              },
            }}
          />
        </div>
      </div>

      <div className="mt-3 text-black">
        <FormLabel>
          <p className="text-xs mb-0 text-black">
            Upload Shipment Images/Videos:
          </p>
        </FormLabel>
        <div className="grid grid-cols-3 gap-4 mt-2">
          {[
            "postShippingPackage",
            "preShippingPackage",
            "innerContentReceived",
            "catelogProductImage",
          ].map((label, index) => (
            <div
              key={index}
              className="flex flex-col items-center border p-4 rounded-lg"
            >
              <CloudUploadIcon className="text-blue-500" />
              <Button component="label" variant="text" color="primary">
                Image Upload
                <input
                  type="file"
                  hidden
                  onChange={(e) => handleFileChange(e, label)}
                  // accept="image/*"
                />
              </Button>
              <p className="text-sm text-gray-500 text-center">{label}</p>
            </div>
          ))}
          {["unboxVideo", "preshippingVideo"].map((label, index) => (
            <div
              key={index}
              className="flex flex-col items-center border p-4 rounded-lg"
            >
              <CloudUploadIcon className="text-blue-500" />
              <Button component="label" variant="text" color="primary">
                Video Upload
                <input
                  type="file"
                  hidden
                  onChange={(e) => handleFileChange(e, label)}
                  // accept="video/*"
                />
              </Button>
              <p className="text-sm text-gray-500 text-center">{label}</p>
            </div>
          ))}
        </div>
        <p className="text-xs text-gray-500 mt-2">
          Note: Maximum image size 2MB and maximum video size 10MB.
        </p>
      </div>

      {selectedValue4Id === 42 && (
        <CreateButton handleCreateCase={handleCreateCase} />
      )}
    </div>
  );
};

export default WrongPackageDelivered;
