// import React from "react";
// import { useState, useEffect } from "react";

// import Backdrop from "@mui/material/Backdrop";
// import Box from "@mui/material/Box";
// import Modal from "@mui/material/Modal";
// import Fade from "@mui/material/Fade";
// import { toast } from "react-toastify";
// import NotesComp from "../../Components/createTicket/NotesComp";
// import RadioButton from "../../Components/createTicket/RadioButton";
// import HeadingTop from "../../Components/createTicket/HeadingTop";
// import SelectDropdown from "../../Components/createTicket/SelectDropdown";
// import InputField from "../../Components/createTicket/InputField";
// import CreateButton from "../../Components/createTicket/CreateButton";
// import BrowesFileBox from "../../Components/createTicket/BrowesFileBox";
// import CreateTicketRemittence from "./CreateTicketRemittence";
// import CreateTicketTransferMyAmount from "./CreateTicketTransferMyAmount";
// import CreateTicketRechargingWallet from "./CreateTicketRechargingWallet";
// import CreateTicketKYC from "./CreateTicketKYC";
// import CreateTicketChannel from "./CreateTicketChannel";
// import CreateTicketAccountLedger from "./CreateTicketAccountLedger";
// import CreateTicketTDSForm from "./CreateTicketTDSForm";
// import CreateTicketIssueWithInvoice from "./CreateTicketIssueWithInvoice";
// import CreateTicketWithGST from "./CreateTicketWithGST";
// import CreateTicketRequestClaim from "./CreateTicketRequestClaim";
// import CreateTicketIssueWithEngageService from "./CreateTicketIssueWithEngageService";
// import CryptoJS from "crypto-js";
// import { useLocation } from "react-router-dom";

// const useQuery = () => {
//   return new URLSearchParams(useLocation().search);
// };

// const apiUrl = process.env.REACT_APP_URL;

// const classes = {
//   hr: {
//     marginTop: "12px",
//     marginBottom: "10px",
//     border: "none",
//     borderBottom: "1px solid #000",
//   },
// };

// const style = {
//   top: 0,
//   right: 0,
//   margin: "auto",
//   width: "60%",
//   height: "95%",
//   bgcolor: "background.paper",
//   boxShadow: 24,
//   p: 4,
//   transition: "transform 0.5s ease-in-out",
//   borderRadius: "10px",

//   marginBottom: "20px",
//   marginTop: "20px",
// };

// const openStyle = {
//   transform: "translateX(0)",
// };

// function CreateTicketByUrlCompanyId({ customerId }) {
//   const [issueOption1, setIssueOption1] = useState([]);
//   const [issueOption2, setIssueOption2] = useState([]);
//   const [issueOption3, setIssueOption3] = useState([]);
//   const [issueOption4, setIssueOption4] = useState([]);
//   const [issueOption5, setIssueOption5] = useState([]);
//   const [issueOption6, setIssueOption6] = useState([]);
//   const [issueOption7, setIssueOption7] = useState([]);
//   const [issueOption8, setIssueOption8] = useState([]);
//   const [issueOption9, setIssueOption9] = useState([]);
//   const [trigger, setTrigger] = useState(false);
//   const [open, setOpen] = useState(true);
//   const [awbNumber, setAwbNumber] = useState("");
//   const [caseList, setCaseList] = useState([]);
//   const [caseSubType, setCaseSubType] = useState([]);
//   const [caseId, setCaseId] = useState(0);
//   const [selectedFile, setSelectedFile] = useState(null);
//   const [selectedValue4, setSelectedValue4] = useState("");
//   const [selectedValue4Id, setSelectedValue4Id] = useState("");
//   const [selectedValue3, setSelectedValue3] = useState("");
//   const [selectedValue, setSelectedValue] = useState("Single AWB");
//   const [description, setDescription] = useState("");

//   const handleOpen = () => {
//     setTrigger(true);
//   };

//   useEffect(() => {
//     handleOpen();
//   }, []);

//   const query = useQuery();
//   const [companyId, setCompanyId] = useState({ companyId: "" });
//   const [checkCompanyId, setCheckCompanyId] = useState("");
//   const [encryptedValue, setEncryptedValue] = useState("");

//   useEffect(() => {
//     const pathArray = window.location.pathname.split("/");
//     const companyIdFromUrl = pathArray[pathArray.length - 1];
//     console.log(companyIdFromUrl);
//     const encryptedValueFromUrl =
//       window.location.href.split("key=")[1]?.trim() || "";

//     setCheckCompanyId(companyIdFromUrl);
//     setEncryptedValue(encryptedValueFromUrl);
//   }, [query]);

//   function decryptValue(encryptedValue) {
//     const key = "Fship";
//     const bytes = CryptoJS.AES.decrypt(encryptedValue, key);
//     const decryptedValue = bytes.toString(CryptoJS.enc.Utf8);
//     return decryptedValue;
//   }

//   function validateCompanyId(checkCompanyId, encryptedValue) {
//     if (!encryptedValue) return false;
//     const decryptedValue = decryptValue(encryptedValue);

//     const [decryptedCompanyId] = decryptedValue?.split("_");
//     console.log(decryptedCompanyId);
//     return decryptedCompanyId === checkCompanyId;
//   }

//   useEffect(() => {
//     if (encryptedValue) {
//       if (validateCompanyId(checkCompanyId, encryptedValue)) {
//         setCompanyId({ companyId: checkCompanyId });
//       } else {
//         toast.error("Invalid Company ID");
//       }
//     }
//   }, [checkCompanyId, encryptedValue]);

//   const handleClose = () => setOpen(false);
//   const handleFileChange = (e) => {
//     const file = e.target.files[0];
//     if (file) {
//       setSelectedFile(file);
//     }
//   };

//   const handleInputValueDescription = (event) => {
//     setDescription(event.target.value);
//   };
//   const handleDeleteFile = () => {
//     setSelectedFile(null);
//   };

//   const handleChange3 = (event) => {
//     setCaseId(event.id);
//     setSelectedValue3(event.name);
//   };
//   const handleChange4 = (event) => {
//     setSelectedValue4Id(event.id);

//     setSelectedValue4(event.name);
//   };

//   const handleChange = (event) => {
//     setSelectedValue(event.target.value);
//   };

//   const handleInputValue = (event) => {
//     setAwbNumber(event.target.value);
//   };
//   const isSingleAWB = selectedValue === "Single AWB";
//   const isMultipleAWBs = selectedValue === "Multiple AWBs";

//   const isValidSelectedValue = (value) => {
//     const invalidValues = [
//       10, 11, 12, 13, 14, 18, 19, 20, 21, 23, 24, 25, 26, 30, 33, 34, 32, 35,
//       36, 39, 40,
//     ];
//     return !invalidValues.includes(value);
//   };

//   const handleCreateCase = async (event) => {
//     event.preventDefault();
//     const formData = new FormData();
//     formData.append("caseType", caseId);
//     formData.append("caseSubType", selectedValue4Id);
//     formData.append("isMultipleAWB", !isSingleAWB);
//     formData.append("awbNo", awbNumber);
//     formData.append("isExcel", selectedFile ? true : isSingleAWB);
//     formData.append("awbExcel", selectedFile);

//     formData.append("discriptionIssue", description);
//     try {
//       const response = await fetch(
//         `${apiUrl}/caseM/addCase?companyId=${companyId.companyId}`,
//         {
//           method: "POST",

//           body: formData,
//         }
//       );

//       if (response.ok) {
//         toast.success("Case Created successfully!");

//         setTimeout(() => {
//           window.location.reload();
//         }, 3000);
//       } else {
//         toast.error("Failed to create case. Please try again.");
//       }
//     } catch (error) {
//       toast.error("Failed to create case. Please try again.");
//     }
//   };

//   useEffect(() => {
//     fetch(`${apiUrl}/master/getAllMasterData/26`, {
//       headers: {
//         "Content-Type": "application/json",
//       },
//     })
//       .then((response) => response.json())
//       .then((data) => {
//         if (data && Array.isArray(data.data)) {
//           setIssueOption1(data.data);
//         } else {
//           console.error("API response does not contain an array:", data);
//         }
//       })
//       .catch((error) => {
//         console.error("Error fetching gender options:", error);
//       });
//   }, []);
//   useEffect(() => {
//     fetch(`${apiUrl}/master/getAllMasterData/29`, {
//       headers: {
//         "Content-Type": "application/json",
//       },
//     })
//       .then((response) => response.json())
//       .then((data) => {
//         if (data && Array.isArray(data.data)) {
//           setIssueOption2(data.data);
//         } else {
//           console.error("API response does not contain an array:", data);
//         }
//       })
//       .catch((error) => {
//         console.error("Error fetching gender options:", error);
//       });
//   }, []);
//   useEffect(() => {
//     fetch(`${apiUrl}/master/getAllMasterData/30`, {
//       headers: {
//         "Content-Type": "application/json",
//       },
//     })
//       .then((response) => response.json())
//       .then((data) => {
//         if (data && Array.isArray(data.data)) {
//           setIssueOption3(data.data);
//         } else {
//           console.error("API response does not contain an array:", data);
//         }
//       })
//       .catch((error) => {
//         console.error("Error fetching gender options:", error);
//       });
//   }, []);
//   useEffect(() => {
//     fetch(`${apiUrl}/master/getAllMasterData/31`, {})
//       .then((response) => response.json())
//       .then((data) => {
//         if (data && Array.isArray(data.data)) {
//           setIssueOption4(data.data);
//         } else {
//           console.error("API response does not contain an array:", data);
//         }
//       })
//       .catch((error) => {
//         console.error("Error fetching gender options:", error);
//       });
//   }, []);
//   useEffect(() => {
//     fetch(`${apiUrl}/master/getAllMasterData/32`, {
//       headers: {
//         "Content-Type": "application/json",
//       },
//     })
//       .then((response) => response.json())
//       .then((data) => {
//         if (data && Array.isArray(data.data)) {
//           setIssueOption5(data.data);
//         } else {
//           console.error("API response does not contain an array:", data);
//         }
//       })
//       .catch((error) => {
//         console.error("Error fetching gender options:", error);
//       });
//   }, []);

//   useEffect(() => {
//     fetch(`${apiUrl}/master/getAllMasterData/33`, {
//       headers: {
//         "Content-Type": "application/json",
//       },
//     })
//       .then((response) => response.json())
//       .then((data) => {
//         if (data && Array.isArray(data.data)) {
//           setIssueOption6(data.data);
//         } else {
//           console.error("API response does not contain an array:", data);
//         }
//       })
//       .catch((error) => {
//         console.error("Error fetching gender options:", error);
//       });
//   }, []);
//   useEffect(() => {
//     // Make an API request to fetch gender options
//     fetch(`${apiUrl}/master/getAllMasterData/37`, {
//       headers: {
//         "Content-Type": "application/json",
//       },
//     })
//       .then((response) => response.json())
//       .then((data) => {
//         if (data && Array.isArray(data.data)) {
//           setIssueOption7(data.data);
//         } else {
//           console.error("API response does not contain an array:", data);
//         }
//       })
//       .catch((error) => {
//         console.error("Error fetching gender options:", error);
//       });
//   }, []);
//   useEffect(() => {
//     // Make an API request to fetch gender options
//     fetch(`${apiUrl}/master/getAllMasterData/38`, {
//       headers: {
//         "Content-Type": "application/json",
//       },
//     })
//       .then((response) => response.json())
//       .then((data) => {
//         if (data && Array.isArray(data.data)) {
//           setIssueOption8(data.data);
//         } else {
//           console.error("API response does not contain an array:", data);
//         }
//       })
//       .catch((error) => {
//         console.error("Error fetching gender options:", error);
//       });
//   }, []);
//   useEffect(() => {
//     // Make an API request to fetch gender options
//     fetch(`${apiUrl}/master/getAllMasterData/36`, {
//       headers: {
//         "Content-Type": "application/json",
//       },
//     })
//       .then((response) => response.json())
//       .then((data) => {
//         if (data && Array.isArray(data.data)) {
//           setIssueOption9(data.data);
//         } else {
//           console.error("API response does not contain an array:", data);
//         }
//       })
//       .catch((error) => {
//         console.error("Error fetching gender options:", error);
//       });
//   }, []);
//   useEffect(() => {
//     fetch(`${apiUrl}/caseMaster/getCaseType`, {})
//       .then((response) => response.json())
//       .then((data) => {
//         if (data.status === "success") {
//           if (Array.isArray(data.data)) {
//             setCaseList(data.data);
//           } else {
//             console.error("API response does not contain users array:", data);
//           }
//         } else {
//           console.error("API request was not successful:", data);
//         }
//       })
//       .catch((error) => {
//         console.error("Error fetching data:", error);
//       });
//   }, [trigger]);

//   useEffect(() => {
//     fetch(`${apiUrl}/caseMaster/getAllCaseSubType/${caseId}`)
//       .then((response) => response.json())
//       .then((data) => {
//         if (data.status === "success") {
//           if (Array.isArray(data.data)) {
//             setCaseSubType(data.data);
//           } else {
//             console.error("API response does not contain users array:", data);
//           }
//         } else {
//           console.error("API request was not successful:", data);
//         }
//       })
//       .catch((error) => {
//         console.error("Error fetching data:", error);
//       });
//   }, [caseId]);

//   return (
//     <div>
//       <Modal
//         aria-labelledby="transition-modal-title"
//         aria-describedby="transition-modal-description"
//         open={open}
//         closeAfterTransition
//         slots={{ backdrop: Backdrop }}
//         slotProps={{
//           backdrop: {
//             timeout: 500,
//           },
//         }}
//       >
//         <Fade in={open}>
//           <Box
//             sx={{ ...style, ...(open && openStyle) }}
//             className="overflow-auto"
//           >
//             <HeadingTop
//               handleClose={handleClose}
//               companyId={companyId}
//             ></HeadingTop>
//             <hr style={classes.hr} />
//             <Box sx={{ display: "flex", gap: 2, mt: 3 }}>
//               <SelectDropdown
//                 dropDownData={caseList}
//                 handleChange={handleChange3}
//                 selectedData={selectedValue3}
//                 Text={"Select Category"}
//               ></SelectDropdown>
//               <SelectDropdown
//                 dropDownData={caseSubType}
//                 handleChange={handleChange4}
//                 selectedData={selectedValue4}
//                 Text={"Select Subcategory"}
//               ></SelectDropdown>
//             </Box>

//             <>
//               {isValidSelectedValue(selectedValue4Id) && (
//                 <Box>
//                   <RadioButton
//                     handleChange={handleChange}
//                     selectedValue={selectedValue}
//                   />
//                 </Box>
//               )}
//               {isSingleAWB && isValidSelectedValue(selectedValue4Id) && (
//                 <Box className="mt-3">
//                   <InputField
//                     text="AWB(s) e.g: 10912581234"
//                     handleInputValue={handleInputValue}
//                     value={awbNumber}
//                   />
//                 </Box>
//               )}
//             </>
//             {isMultipleAWBs && isValidSelectedValue(selectedValue4Id) && (
//               <Box className="mt-3 ">
//                 <InputField
//                   text="Enter comma-separated values or press enter,"
//                   handleInputValue={handleInputValue}
//                   value={awbNumber}
//                 ></InputField>
//                 <NotesComp
//                   text={
//                     " You can add up to 50 AWBs here. To add more AWBs, please upload sheet. "
//                   }
//                 ></NotesComp>
//                 <BrowesFileBox
//                   handleDeleteFile={handleDeleteFile}
//                   handleFileChange={handleFileChange}
//                   selectedFile={selectedFile}
//                   text={"Upload your AWB(s)"}
//                 ></BrowesFileBox>
//               </Box>
//             )}
//             {selectedValue4Id == 10 && (
//               <CreateTicketRemittence
//                 caseId={caseId}
//                 selectedValue4Id={selectedValue4Id}
//                 // customerId={customerId}
//                 customerId={companyId}
//                 companyId={companyId}
//               ></CreateTicketRemittence>
//             )}
//             {selectedValue4Id == 11 && (
//               <CreateTicketTransferMyAmount
//                 caseId={caseId}
//                 selectedValue4Id={selectedValue4Id}
//                 setOpen={setOpen}
//                 customerId={companyId}
//                 companyId={companyId}
//               ></CreateTicketTransferMyAmount>
//             )}
//             {selectedValue4Id == 12 && (
//               <CreateTicketRechargingWallet
//                 customerId={companyId}
//                 companyId={companyId}
//                 caseId={caseId}
//                 selectedValue4Id={selectedValue4Id}
//                 setOpen={setOpen}
//                 handleShow={true}
//               ></CreateTicketRechargingWallet>
//             )}
//             {selectedValue4Id == 13 && (
//               <CreateTicketRechargingWallet
//                 customerId={companyId}
//                 companyId={companyId}
//                 caseId={caseId}
//                 selectedValue4Id={selectedValue4Id}
//                 setOpen={setOpen}
//                 handleShow={false}
//               ></CreateTicketRechargingWallet>
//             )}
//             {selectedValue4Id == 14 && (
//               <CreateTicketRechargingWallet
//                 customerId={companyId}
//                 companyId={companyId}
//                 caseId={caseId}
//                 selectedValue4Id={selectedValue4Id}
//                 setOpen={setOpen}
//                 handleShow={"Hide"}
//               ></CreateTicketRechargingWallet>
//             )}
//             {/* {selectedValue4Id == 18 && (
//               <CreateTicketKYC
//                 customerId={companyId}
//                 companyId={companyId}
//                 caseId={caseId}
//                 selectedValue4Id={selectedValue4Id}
//                 setOpen={setOpen}
//               ></CreateTicketKYC>
//             )} */}
//             {(selectedValue4Id == 18 ||
//               selectedValue4Id == 39 ||
//               selectedValue4Id == 40) && (
//               <CreateTicketKYC
//                 customerId={companyId}
//                 caseId={caseId}
//                 // companyId={companyId}
//                 selectedValue4Id={selectedValue4Id}
//                 setOpen={setOpen}
//               ></CreateTicketKYC>
//             )}
//             {/* {selectedValue4Id == 19 && (
//               <p className="mt-3 fw-bold fs-12">
//                 Congratulations! Your bank details have been verified
//                 successfully on 13 October 2022.
//               </p>
//             )} */}

//             {selectedValue4Id == 20 && (
//               <CreateTicketChannel
//                 customerId={companyId}
//                 companyId={companyId}
//                 caseId={caseId}
//                 setOpen={setOpen}
//                 selectedValue4Id={selectedValue4Id}
//                 selectedData={selectedValue4Id}
//                 optionData1={issueOption1}
//                 optionData2={issueOption2}
//                 text={"Enter Order ID(s) here (optional)"}
//               ></CreateTicketChannel>
//             )}
//             {selectedValue4Id == 21 && (
//               <CreateTicketChannel
//                 customerId={companyId}
//                 companyId={companyId}
//                 caseId={caseId}
//                 setOpen={setOpen}
//                 selectedValue4Id={selectedValue4Id}
//                 selectedData={selectedValue4Id}
//                 optionData1={issueOption3}
//                 optionData2={issueOption4}
//                 text={"Add your order ID and press Enter"}
//               ></CreateTicketChannel>
//             )}
//             {selectedValue4Id == 23 && (
//               <CreateTicketAccountLedger
//                 customerId={companyId}
//                 companyId={companyId}
//                 caseId={caseId}
//                 setOpen={setOpen}
//                 selectedValue4Id={selectedValue4Id}
//               ></CreateTicketAccountLedger>
//             )}
//             {selectedValue4Id == 24 && (
//               <CreateTicketTDSForm
//                 customerId={companyId}
//                 companyId={companyId}
//                 caseId={caseId}
//                 selectedValue4Id={selectedValue4Id}
//                 setOpen={setOpen}
//               ></CreateTicketTDSForm>
//             )}
//             {selectedValue4Id == 25 && (
//               <CreateTicketIssueWithInvoice
//                 customerId={companyId}
//                 companyId={companyId}
//                 caseId={caseId}
//                 selectedValue4Id={selectedValue4Id}
//                 setOpen={setOpen}
//               ></CreateTicketIssueWithInvoice>
//             )}
//             {selectedValue4Id == 26 && (
//               <CreateTicketWithGST
//                 caseId={caseId}
//                 selectedValue4Id={selectedValue4Id}
//                 customerId={companyId}
//                 companyId={companyId}
//               ></CreateTicketWithGST>
//             )}
//             {selectedValue4Id == 29 && (
//               <CreateTicketRequestClaim
//                 text={"Describes your issue:"}
//                 customerId={companyId}
//                 companyId={companyId}
//                 caseId={caseId}
//                 selectedValue4Id={selectedValue4Id}
//                 setOpen={setOpen}
//                 description={description}
//                 handleInputValueDescription={handleInputValueDescription}
//               ></CreateTicketRequestClaim>
//             )}
//             {selectedValue4Id == 30 && (
//               <CreateTicketRequestClaim
//                 text={"Select Issue"}
//                 customerId={companyId}
//                 companyId={companyId}
//                 caseId={caseId}
//                 selectedValue4Id={selectedValue4Id}
//                 setOpen={setOpen}
//               ></CreateTicketRequestClaim>
//             )}
//             {/* {(selectedValue4Id == 33 || selectedValue4Id == 34) && (
//               <NotesComp
//                 text={
//                   "You are not currently using our Fship Fulfillment services. Please raise your issue under Others>>My Issue Not Listed subcategory."
//                 }
//               ></NotesComp>
//             )} */}

//             {/* {(selectedValue4Id == 32 || selectedValue4Id == 35) && (
//               <CreateTicketIssueWithEngageService
//                 selectedValue4Id={selectedValue4Id}
//                 optionData1={issueOption5}
//                 optionData2={issueOption6}
//                 customerId={companyId}
//                 companyId={companyId}
//                 caseId={caseId}
//                 setOpen={setOpen}
//               ></CreateTicketIssueWithEngageService>
//             )} */}
//             {(selectedValue4Id == 32 ||
//               selectedValue4Id == 35 ||
//               selectedValue4Id == 33 ||
//               selectedValue4Id == 36 ||
//               selectedValue4Id == 34) && (
//               <CreateTicketIssueWithEngageService
//                 selectedValue4Id={selectedValue4Id}
//                 optionData1={issueOption5}
//                 optionData2={issueOption6}
//                 optionData3={issueOption7}
//                 optionData4={issueOption8}
//                 optionData5={issueOption9}
//                 customerId={companyId}
//                 caseId={caseId}
//                 setOpen={setOpen}
//                 companyId={companyId}
//               ></CreateTicketIssueWithEngageService>
//             )}
//             {selectedValue4Id !== 10 &&
//               selectedValue4Id !== 11 &&
//               selectedValue4Id !== 19 &&
//               selectedValue4Id !== 12 &&
//               selectedValue4Id !== 13 &&
//               selectedValue4Id !== 14 &&
//               selectedValue4Id !== 18 &&
//               selectedValue4Id !== 20 &&
//               selectedValue4Id !== 21 &&
//               selectedValue4Id !== 23 &&
//               selectedValue4Id !== 24 &&
//               selectedValue4Id !== 25 &&
//               selectedValue4Id !== 26 &&
//               selectedValue4Id !== 30 &&
//               selectedValue4Id !== 32 &&
//               selectedValue4Id !== 35 &&
//               selectedValue4Id !== 33 &&
//               selectedValue4Id !== 34 &&
//               selectedValue4Id !== 36 &&
//               selectedValue4Id !== 39 &&
//               selectedValue4Id !== 40 && (
//                 <Box>
//                   <CreateButton
//                     handleCreateCase={handleCreateCase}
//                   ></CreateButton>
//                 </Box>
//               )}
//           </Box>
//         </Fade>
//       </Modal>
//     </div>
//   );
// }
// export default CreateTicketByUrlCompanyId;

import React from "react";
import { useState, useEffect } from "react";

import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { toast } from "react-toastify";
import NotesComp from "../../Components/createTicket/NotesComp";
import RadioButton from "../../Components/createTicket/RadioButton";
import HeadingTop from "../../Components/createTicket/HeadingTop";
import SelectDropdown from "../../Components/createTicket/SelectDropdown";
import InputField from "../../Components/createTicket/InputField";
import CreateButton from "../../Components/createTicket/CreateButton";
import BrowesFileBox from "../../Components/createTicket/BrowesFileBox";
import CreateTicketRemittence from "./CreateTicketRemittence";
import CreateTicketTransferMyAmount from "./CreateTicketTransferMyAmount";
import CreateTicketRechargingWallet from "./CreateTicketRechargingWallet";
import CreateTicketKYC from "./CreateTicketKYC";
import CreateTicketChannel from "./CreateTicketChannel";
import CreateTicketAccountLedger from "./CreateTicketAccountLedger";
import CreateTicketTDSForm from "./CreateTicketTDSForm";
import CreateTicketIssueWithInvoice from "./CreateTicketIssueWithInvoice";
import CreateTicketWithGST from "./CreateTicketWithGST";
import CreateTicketRequestClaim from "./CreateTicketRequestClaim";
import CreateTicketIssueWithEngageService from "./CreateTicketIssueWithEngageService";
import CryptoJS from "crypto-js";
import { useLocation } from "react-router-dom";
import DeliveryNotRecived from "./DeliveryNotRecived";
import WrongPackageDelivered from "./WrongPackageDelivered";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const apiUrl = process.env.REACT_APP_URL;

const classes = {
  hr: {
    marginTop: "12px",
    marginBottom: "10px",
    border: "none",
    borderBottom: "1px solid #000",
  },
};

const style = {
  top: 0,
  right: 0,
  margin: "auto",
  width: "60%",
  height: "95%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  transition: "transform 0.5s ease-in-out",
  borderRadius: "10px",

  marginBottom: "20px",
  marginTop: "20px",
};

const openStyle = {
  transform: "translateX(0)",
};

function CreateTicketByUrlCompanyId({ customerId }) {
  const [issueOption1, setIssueOption1] = useState([]);
  const [issueOption2, setIssueOption2] = useState([]);
  const [issueOption3, setIssueOption3] = useState([]);
  const [issueOption4, setIssueOption4] = useState([]);
  const [issueOption5, setIssueOption5] = useState([]);
  const [issueOption6, setIssueOption6] = useState([]);
  const [issueOption7, setIssueOption7] = useState([]);
  const [issueOption8, setIssueOption8] = useState([]);
  const [issueOption9, setIssueOption9] = useState([]);
  const [trigger, setTrigger] = useState(false);
  const [open, setOpen] = useState(true);
  const [awbNumber, setAwbNumber] = useState("");
  const [tabId, setTabId] = useState("");
  const [caseList, setCaseList] = useState([]);
  const [caseSubType, setCaseSubType] = useState([]);
  const [caseId, setCaseId] = useState(0);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedValue4, setSelectedValue4] = useState("");
  const [selectedValue4Id, setSelectedValue4Id] = useState("");
  const [selectedValue3, setSelectedValue3] = useState("");
  const [selectedValue, setSelectedValue] = useState("Single AWB");
  const [description, setDescription] = useState("");
  const [prefilledAwb, setPrefilledAwb] = useState("");

  const handleOpen = () => {
    setTrigger(true);
  };

  useEffect(() => {
    handleOpen();
  }, []);

  const query = useQuery();
  const [companyId, setCompanyId] = useState({ companyId: "" });
  const [checkCompanyId, setCheckCompanyId] = useState("");
  const [encryptedValue, setEncryptedValue] = useState("");

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    const pathArray = window.location.pathname.split("/");
    const companyIdFromUrl = pathArray[pathArray.length - 1];

    const awbNumberFromUrl = urlParams.get("awbNumber") || "";
    const tabIdFromUrl = urlParams.get("tabid") || "";
    //console.log(companyIdFromUrl);
    const encryptedValueFromUrl =
      window.location.href.split("key=")[1]?.trim() || "";
    //console.log(encryptedValueFromUrl);
    setCheckCompanyId(companyIdFromUrl);
    setEncryptedValue(encryptedValueFromUrl);
    // setAwbNumber(awbNumberFromUrl);
    setPrefilledAwb(awbNumberFromUrl);
    setTabId(tabIdFromUrl);
  }, [query.size]);

  function decryptValue(encryptedValue) {
    const key = "Fship";
    const bytes = CryptoJS.AES.decrypt(encryptedValue, key);
    const decryptedValue = bytes.toString(CryptoJS.enc.Utf8);
    return decryptedValue;
  }

  useEffect(() => {
    setAwbNumber(prefilledAwb);
  }, [prefilledAwb]);
  console.log(awbNumber);
  function validateCompanyId(checkCompanyId, encryptedValue) {
    if (!encryptedValue) return false;
    const decryptedValue = decryptValue(encryptedValue);

    const [decryptedCompanyId] = decryptedValue?.split("_");

    return decryptedCompanyId === checkCompanyId;
  }

  useEffect(() => {
    // if (encryptedValue) {
    //   if (validateCompanyId(checkCompanyId, encryptedValue)) {
    //     setCompanyId({ companyId: checkCompanyId });
    //   } else {
    //     toast.error("Invalid Company ID");
    //   }
    // }
    setCompanyId({ companyId: checkCompanyId });
  }, [checkCompanyId, encryptedValue]);

  const handleClose = () => setOpen(false);
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);
    }
  };

  const handleInputValueDescription = (event) => {
    setDescription(event.target.value);
  };
  const handleDeleteFile = () => {
    setSelectedFile(null);
  };

  const handleChange3 = (event) => {
    setCaseId(event.id);
    setSelectedValue3(event.name);
  };
  const handleChange4 = (event) => {
    setSelectedValue4Id(event.id);

    setSelectedValue4(event.name);
  };

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleInputValue = (event) => {
    setAwbNumber(event.target.value);
  };
  const isSingleAWB = selectedValue === "Single AWB";
  const isMultipleAWBs = selectedValue === "Multiple AWBs";

  const isValidSelectedValue = (value) => {
    const invalidValues = [
      10, 11, 12, 13, 14, 18, 19, 20, 21, 23, 24, 25, 26, 30, 33, 34, 32, 35,
      36, 39, 40, 41, 42,
    ];
    return !invalidValues.includes(value);
  };

  const handleCreateCase = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("caseType", caseId);
    formData.append("caseSubType", selectedValue4Id);
    formData.append("isMultipleAWB", !isSingleAWB);
    formData.append("awbNo", awbNumber);
    formData.append("isExcel", selectedFile ? true : isSingleAWB);
    formData.append("awbExcel", selectedFile);
    formData.append("tabId", tabId);
    formData.append("discriptionIssue", description);
    try {
      const response = await fetch(
        `${apiUrl}/caseM/addCase?companyId=${companyId.companyId}`,
        {
          method: "POST",

          body: formData,
        }
      );

      if (response.ok) {
        toast.success("Case Created successfully!");

        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else {
        toast.error("Failed to create case. Please try again.");
      }
    } catch (error) {
      toast.error("Failed to create case. Please try again.");
    }
  };

  useEffect(() => {
    fetch(`${apiUrl}/master/getAllMasterData/26`, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data.data)) {
          setIssueOption1(data.data);
        } else {
          console.error("API response does not contain an array:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching gender options:", error);
      });
  }, []);
  useEffect(() => {
    fetch(`${apiUrl}/master/getAllMasterData/29`, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data.data)) {
          setIssueOption2(data.data);
        } else {
          console.error("API response does not contain an array:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching gender options:", error);
      });
  }, []);
  useEffect(() => {
    fetch(`${apiUrl}/master/getAllMasterData/30`, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data.data)) {
          setIssueOption3(data.data);
        } else {
          console.error("API response does not contain an array:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching gender options:", error);
      });
  }, []);
  useEffect(() => {
    fetch(`${apiUrl}/master/getAllMasterData/31`, {})
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data.data)) {
          setIssueOption4(data.data);
        } else {
          console.error("API response does not contain an array:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching gender options:", error);
      });
  }, []);
  useEffect(() => {
    fetch(`${apiUrl}/master/getAllMasterData/32`, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data.data)) {
          setIssueOption5(data.data);
        } else {
          console.error("API response does not contain an array:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching gender options:", error);
      });
  }, []);

  useEffect(() => {
    fetch(`${apiUrl}/master/getAllMasterData/33`, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data.data)) {
          setIssueOption6(data.data);
        } else {
          console.error("API response does not contain an array:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching gender options:", error);
      });
  }, []);
  useEffect(() => {
    // Make an API request to fetch gender options
    fetch(`${apiUrl}/master/getAllMasterData/37`, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data.data)) {
          setIssueOption7(data.data);
        } else {
          console.error("API response does not contain an array:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching gender options:", error);
      });
  }, []);
  useEffect(() => {
    // Make an API request to fetch gender options
    fetch(`${apiUrl}/master/getAllMasterData/38`, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data.data)) {
          setIssueOption8(data.data);
        } else {
          console.error("API response does not contain an array:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching gender options:", error);
      });
  }, []);
  useEffect(() => {
    // Make an API request to fetch gender options
    fetch(`${apiUrl}/master/getAllMasterData/36`, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data.data)) {
          setIssueOption9(data.data);
        } else {
          console.error("API response does not contain an array:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching gender options:", error);
      });
  }, []);
  useEffect(() => {
    fetch(`${apiUrl}/caseMaster/getCaseType`, {})
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          if (Array.isArray(data.data)) {
            setCaseList(data.data);
          } else {
            console.error("API response does not contain users array:", data);
          }
        } else {
          console.error("API request was not successful:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [trigger]);

  useEffect(() => {
    fetch(`${apiUrl}/caseMaster/getAllCaseSubType/${caseId}`)
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          if (Array.isArray(data.data)) {
            setCaseSubType(data.data);
          } else {
            console.error("API response does not contain users array:", data);
          }
        } else {
          console.error("API request was not successful:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [caseId]);
  console.log(awbNumber);
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box
            sx={{ ...style, ...(open && openStyle) }}
            className="overflow-auto"
          >
            <HeadingTop
              handleClose={handleClose}
              companyId={companyId}
            ></HeadingTop>
            <hr style={classes.hr} />
            <Box sx={{ display: "flex", gap: 2, mt: 3 }}>
              <SelectDropdown
                dropDownData={caseList}
                handleChange={handleChange3}
                selectedData={selectedValue3}
                Text={"Select Category"}
              ></SelectDropdown>
              <SelectDropdown
                dropDownData={caseSubType}
                handleChange={handleChange4}
                selectedData={selectedValue4}
                Text={"Select Subcategory"}
              ></SelectDropdown>
            </Box>

            <>
              {isValidSelectedValue(selectedValue4Id) && (
                <Box>
                  <RadioButton
                    handleChange={handleChange}
                    selectedValue={selectedValue}
                  />
                </Box>
              )}
              {isSingleAWB && isValidSelectedValue(selectedValue4Id) && (
                <Box className="mt-3">
                  <InputField
                    text="AWB(s) e.g: 10912581234"
                    handleInputValue={handleInputValue}
                    value={awbNumber}
                  />
                </Box>
              )}
            </>
            {isMultipleAWBs && isValidSelectedValue(selectedValue4Id) && (
              <Box className="mt-3 ">
                <InputField
                  text="Enter comma-separated values or press enter,"
                  handleInputValue={handleInputValue}
                  value={awbNumber}
                ></InputField>
                <NotesComp
                  text={
                    " You can add up to 50 AWBs here. To add more AWBs, please upload sheet. "
                  }
                ></NotesComp>
                <BrowesFileBox
                  handleDeleteFile={handleDeleteFile}
                  handleFileChange={handleFileChange}
                  selectedFile={selectedFile}
                  text={"Upload your AWB(s)"}
                ></BrowesFileBox>
              </Box>
            )}
            {selectedValue4Id == 10 && (
              <CreateTicketRemittence
                caseId={caseId}
                selectedValue4Id={selectedValue4Id}
                // customerId={customerId}
                customerId={companyId}
                companyId={companyId}
              ></CreateTicketRemittence>
            )}
            {selectedValue4Id == 11 && (
              <CreateTicketTransferMyAmount
                caseId={caseId}
                selectedValue4Id={selectedValue4Id}
                setOpen={setOpen}
                customerId={companyId}
                companyId={companyId}
              ></CreateTicketTransferMyAmount>
            )}
            {selectedValue4Id == 12 && (
              <CreateTicketRechargingWallet
                customerId={companyId}
                companyId={companyId}
                caseId={caseId}
                selectedValue4Id={selectedValue4Id}
                setOpen={setOpen}
                handleShow={true}
              ></CreateTicketRechargingWallet>
            )}
            {selectedValue4Id == 13 && (
              <CreateTicketRechargingWallet
                customerId={companyId}
                companyId={companyId}
                caseId={caseId}
                selectedValue4Id={selectedValue4Id}
                setOpen={setOpen}
                handleShow={false}
              ></CreateTicketRechargingWallet>
            )}
            {selectedValue4Id == 14 && (
              <CreateTicketRechargingWallet
                customerId={companyId}
                companyId={companyId}
                caseId={caseId}
                selectedValue4Id={selectedValue4Id}
                setOpen={setOpen}
                handleShow={"Hide"}
              ></CreateTicketRechargingWallet>
            )}
            {/* {selectedValue4Id == 18 && (
              <CreateTicketKYC
                customerId={companyId}
                companyId={companyId}
                caseId={caseId}
                selectedValue4Id={selectedValue4Id}
                setOpen={setOpen}
              ></CreateTicketKYC>
            )} */}
            {(selectedValue4Id == 18 ||
              selectedValue4Id == 39 ||
              selectedValue4Id == 40) && (
              <CreateTicketKYC
                customerId={companyId}
                caseId={caseId}
                // companyId={companyId}
                selectedValue4Id={selectedValue4Id}
                setOpen={setOpen}
              ></CreateTicketKYC>
            )}
            {selectedValue4Id == 42 && (
              <WrongPackageDelivered
                customerId={companyId}
                companyId={companyId}
                caseId={caseId}
                selectedValue4Id={selectedValue4Id}
                setOpen={setOpen}
                prefilledAwb={prefilledAwb}
                tabId={tabId}
              ></WrongPackageDelivered>
            )}
            {selectedValue4Id == 41 && (
              <DeliveryNotRecived
                customerId={companyId}
                companyId={companyId}
                caseId={caseId}
                selectedValue4Id={selectedValue4Id}
                setOpen={setOpen}
                prefilledAwb={prefilledAwb}
                tabId={tabId}
              ></DeliveryNotRecived>
            )}
            {/* {selectedValue4Id == 19 && (
              <p className="mt-3 fw-bold fs-12">
                Congratulations! Your bank details have been verified
                successfully on 13 October 2022.
              </p>
            )} */}

            {selectedValue4Id == 20 && (
              <CreateTicketChannel
                customerId={companyId}
                companyId={companyId}
                caseId={caseId}
                setOpen={setOpen}
                selectedValue4Id={selectedValue4Id}
                selectedData={selectedValue4Id}
                optionData1={issueOption1}
                optionData2={issueOption2}
                text={"Enter Order ID(s) here (optional)"}
              ></CreateTicketChannel>
            )}
            {selectedValue4Id == 21 && (
              <CreateTicketChannel
                customerId={companyId}
                companyId={companyId}
                caseId={caseId}
                setOpen={setOpen}
                selectedValue4Id={selectedValue4Id}
                selectedData={selectedValue4Id}
                optionData1={issueOption3}
                optionData2={issueOption4}
                text={"Add your order ID and press Enter"}
              ></CreateTicketChannel>
            )}
            {selectedValue4Id == 23 && (
              <CreateTicketAccountLedger
                customerId={companyId}
                companyId={companyId}
                caseId={caseId}
                setOpen={setOpen}
                selectedValue4Id={selectedValue4Id}
              ></CreateTicketAccountLedger>
            )}
            {selectedValue4Id == 24 && (
              <CreateTicketTDSForm
                customerId={companyId}
                companyId={companyId}
                caseId={caseId}
                selectedValue4Id={selectedValue4Id}
                setOpen={setOpen}
              ></CreateTicketTDSForm>
            )}
            {selectedValue4Id == 25 && (
              <CreateTicketIssueWithInvoice
                customerId={companyId}
                companyId={companyId}
                caseId={caseId}
                selectedValue4Id={selectedValue4Id}
                setOpen={setOpen}
              ></CreateTicketIssueWithInvoice>
            )}
            {selectedValue4Id == 26 && (
              <CreateTicketWithGST
                caseId={caseId}
                selectedValue4Id={selectedValue4Id}
                customerId={companyId}
                companyId={companyId}
              ></CreateTicketWithGST>
            )}
            {selectedValue4Id == 29 && (
              <CreateTicketRequestClaim
                text={"Describes your issue:"}
                customerId={companyId}
                companyId={companyId}
                caseId={caseId}
                selectedValue4Id={selectedValue4Id}
                setOpen={setOpen}
                description={description}
                handleInputValueDescription={handleInputValueDescription}
              ></CreateTicketRequestClaim>
            )}
            {selectedValue4Id == 30 && (
              <CreateTicketRequestClaim
                text={"Select Issue"}
                customerId={companyId}
                companyId={companyId}
                caseId={caseId}
                selectedValue4Id={selectedValue4Id}
                setOpen={setOpen}
              ></CreateTicketRequestClaim>
            )}
            {/* {(selectedValue4Id == 33 || selectedValue4Id == 34) && (
              <NotesComp
                text={
                  "You are not currently using our Fship Fulfillment services. Please raise your issue under Others>>My Issue Not Listed subcategory."
                }
              ></NotesComp>
            )} */}

            {/* {(selectedValue4Id == 32 || selectedValue4Id == 35) && (
              <CreateTicketIssueWithEngageService
                selectedValue4Id={selectedValue4Id}
                optionData1={issueOption5}
                optionData2={issueOption6}
                customerId={companyId}
                companyId={companyId}
                caseId={caseId}
                setOpen={setOpen}
              ></CreateTicketIssueWithEngageService>
            )} */}
            {(selectedValue4Id == 32 ||
              selectedValue4Id == 35 ||
              selectedValue4Id == 33 ||
              selectedValue4Id == 36 ||
              selectedValue4Id == 34) && (
              <CreateTicketIssueWithEngageService
                selectedValue4Id={selectedValue4Id}
                optionData1={issueOption5}
                optionData2={issueOption6}
                optionData3={issueOption7}
                optionData4={issueOption8}
                optionData5={issueOption9}
                customerId={companyId}
                caseId={caseId}
                setOpen={setOpen}
                companyId={companyId}
              ></CreateTicketIssueWithEngageService>
            )}
            {selectedValue4Id !== 10 &&
              selectedValue4Id !== 11 &&
              selectedValue4Id !== 19 &&
              selectedValue4Id !== 12 &&
              selectedValue4Id !== 13 &&
              selectedValue4Id !== 14 &&
              selectedValue4Id !== 18 &&
              selectedValue4Id !== 20 &&
              selectedValue4Id !== 21 &&
              selectedValue4Id !== 23 &&
              selectedValue4Id !== 24 &&
              selectedValue4Id !== 25 &&
              selectedValue4Id !== 26 &&
              selectedValue4Id !== 30 &&
              selectedValue4Id !== 32 &&
              selectedValue4Id !== 35 &&
              selectedValue4Id !== 33 &&
              selectedValue4Id !== 34 &&
              selectedValue4Id !== 36 &&
              selectedValue4Id !== 39 &&
              selectedValue4Id !== 40 &&
              selectedValue4Id !== 41 &&
              selectedValue4Id !== 42 && (
                <Box>
                  <CreateButton
                    handleCreateCase={handleCreateCase}
                  ></CreateButton>
                </Box>
              )}
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
export default CreateTicketByUrlCompanyId;
