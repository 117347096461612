import React, { useState } from "react";
import SelectComponent from "../../Sales CRM/SelectComponent"; // Adjust the import path as needed
//let num = 0;
function FilterComponent({
  leadSourceOptions,
  partnerOptions,
  onFilterChange,
  employeeId,
  leadStatus,
}) {
  // console.log("AMAN", num++);
  const [filterByLeadSource, setFilterByLeadSource] = useState([]);
  const [filterByLeadPipeline, setFilterByLeadPipeline] = useState([]);
  const [filterByBusinessCategory, setFilterByBusinessCategory] = useState([]);
  const [filterByPartner, setFilterByPartner] = useState([]);

  const handleCheckboxChange = (selectedOptions) => {
    const values = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    setFilterByLeadSource(values);
    onFilterChange && onFilterChange({ filterByLeadSource: values });
  };

  const handleCheckboxChange1 = (selectedOptions) => {
    console.log(selectedOptions);
    const values = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];

    const id = selectedOptions
      ? selectedOptions.map((option) => option.id)
      : [];
    setFilterByLeadPipeline(values);
    onFilterChange && onFilterChange({ filterByLeadPipeline: id });
  };

  const handleCheckboxChange2 = (selectedOptions) => {
    const values = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    setFilterByBusinessCategory(values);
    onFilterChange && onFilterChange({ filterByBusinessCategory: values });
  };

  const handleCheckboxChange3 = (selectedOptions) => {
    const values = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    setFilterByPartner(values);
    onFilterChange && onFilterChange({ filterByPartner: values });
  };
  console.log(employeeId);
  return (
    <>
      <SelectComponent
        label="Lead Source"
        options={leadSourceOptions}
        handleSelectChange={handleCheckboxChange}
        selectedOptions={filterByLeadSource}
      />

      <SelectComponent
        label="Sales Representative"
        options={employeeId}
        handleSelectChange={handleCheckboxChange1}
        selectedOptions={filterByLeadPipeline}
      />

      {/* <SelectComponent
        label="UTM"
        options={businessCategoryOptions}
        handleSelectChange={handleCheckboxChange2}
        selectedOptions={filterByBusinessCategory}
      /> */}

      <SelectComponent
        label="Deal Status"
        options={leadStatus}
        handleSelectChange={handleCheckboxChange2}
        selectedOptions={filterByBusinessCategory}
      />
      <SelectComponent
        label="Region/Territory"
        options={partnerOptions}
        handleSelectChange={handleCheckboxChange3}
        selectedOptions={filterByPartner}
      />
    </>
  );
}

export default FilterComponent;
